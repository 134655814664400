import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "HeaderMainPage__breadcrumb-link-text" }
const _hoisted_3 = { class: "programPlanning__switch" }
const _hoisted_4 = { class: "HeaderMainPage__menu" }
const _hoisted_5 = { class: "dropDownMenu__item" }
const _hoisted_6 = { class: "dropDownMenu__item" }
const _hoisted_7 = { class: "dropDownMenu__item" }
const _hoisted_8 = {
  key: 0,
  class: "pageWithPopin__container inner-sm-min"
}
const _hoisted_9 = { class: "ProgramPlanning__MenuSlider" }
const _hoisted_10 = { class: "pageWithPopin pageWithPopin--ProgramPlanning" }
const _hoisted_11 = { class: "pageWithPopin__left" }
const _hoisted_12 = { class: "ProgramPlanning" }
const _hoisted_13 = { class: "ProgramPlanning__title" }
const _hoisted_14 = { class: "ProgramPlanning__hours" }
const _hoisted_15 = { class: "ProgramPlanning__hours-content" }
const _hoisted_16 = { class: "ProgramPlanning__hour" }
const _hoisted_17 = { class: "ProgramPlanning__hour" }
const _hoisted_18 = { class: "ProgramPlanning__hour" }
const _hoisted_19 = { class: "ProgramPlanning__hour" }
const _hoisted_20 = { class: "ProgramPlanning__hour" }
const _hoisted_21 = {
  key: 0,
  class: "ProgramPlanning__list"
}
const _hoisted_22 = { class: "ProgramPlanning__day" }
const _hoisted_23 = { class: "ProgramPlanning__day-abr" }
const _hoisted_24 = { class: "ProgramPlanning__day-full" }
const _hoisted_25 = { class: "pageWithPopin__right" }
const _hoisted_26 = {
  key: 0,
  class: "mobilePopin"
}
const _hoisted_27 = {
  key: 1,
  class: "mobilePopin"
}
const _hoisted_28 = {
  key: 2,
  class: "mobilePopin"
}
const _hoisted_29 = { class: "popinForm" }
const _hoisted_30 = { class: "popinForm__footer" }
const _hoisted_31 = ["innerHTML"]

import ERoutes from "@/router/routes";

import {IntlModule, ProgramModule} from '@/store';

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import MyVMenuSlider from "@/components/ui/molecules/MyVMenuSlider/MyVMenuSlider.vue";
import PlanningBar from "@/components/domains/Heating/programs/MyVPlanningBar/MyVPlanningBar.vue";
import MyVDropDownMenu from "@/components/ui/atoms/MyVDropDownMenu/MyVDropDownMenu.vue";
import PopinListCheck from '@/components/ui/organisms/MyVPopinListCheck/MyVPopinListCheck.vue';
import MyVRenameProgram from "@/components/domains/Heating/programs/MyVRenameProgram/MyVRenameProgram.vue";
import MyVDuplicateProgram
  from "@/components/domains/Heating/programs/create/MyVDuplicateProgram/MyVDuplicateProgram.vue";

import {TMenuSliderOption} from "@/components/ui/molecules/MyVMenuSlider/interfaces";
import {fillDailyProgSegments} from '@/helpers/domains/dailyprogs';

import logo from "@/assets/images/logo_beta.svg?url";
import {TPopinListCheckDatas, TPopinListCheckItem} from "@/components/ui/organisms/MyVPopinListCheck/interfaces";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {g_appTitle} from "@/helpers/constants/global";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {EApplianceMode} from "@/services/Appliances/interfaces";
import {TRenameProgramDatas} from "@/components/domains/Heating/programs/MyVRenameProgram/interfaces";
import {TDuplicateProgramDatas} from "@/components/domains/Heating/programs/create/MyVDuplicateProgram/interfaces";
import {isMobileDevice} from "@/helpers/domains/device";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import MyVTemperatureControlModal
  from "@/components/domains/Heating/programs/MyVTemperatureControlModal/MyVTemperatureControlModal.vue";
import {useDate} from "@/helpers/dates/date-utils";
import {EDropDownMenuDirection} from "@/components/ui/atoms/MyVDropDownMenu/interfaces";
import MixpanelService from "@/services/Mixpanel";
import {ETrackPageViewEvent, ETrackProgramEvent} from "@/services/Mixpanel/interfaces";
import {TPrograms} from "@/services/Program/interfaces";
import {ESwitchSize} from "@/components/ui/atoms/MyVSwitches/interfaces";
import MyVCustomSwitch from "@/components/ui/atoms/MyVSwitches/MyVCustomSwitch.vue";
import {useReminder} from "@/composables/useReminder";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramPlanningView',
  setup(__props) {

const currentSliderMenuAppliance = ref(0);
const idApplianceRef = ref(-1);
const duplicateError = ref<string | null>(null)
const isOpenPopinDuplicate = ref(false);
const isOpenPopinRename = ref(false);
const isOpenPopinDelete = ref(false);
const isOpenPopinApplyToAppliances = ref(false);
const isTemperatureControlModalOpen = ref(false);

const route = useRoute()
const router = useRouter()
const {t} = useI18n()
const {showReminder: hasTemperatureControlReminder} = useReminder("temperatureControlReminder");
const currentAppliance = computed(() => {
  return (programPlannings.value && programPlannings.value.length > 0 && currentSliderMenuAppliance.value !== -1) ? programPlannings.value[currentSliderMenuAppliance.value] : undefined;
})

const locale = computed(() => {
  return IntlModule.locale;
})

const programId = computed(() => {
  return parseInt(route.params.id as string);
})

const program = computed(() => {
  return ProgramModule.getProgramById(programId.value);
})

const programPlannings = computed(() => {
  return program.value?.plannings;
})

const sliderOptions = computed(() => {
  return programPlannings.value?.slice().map(({idAppliance, nameAppliance}) => ({
    label: nameAppliance ? t(nameAppliance) : null,
    value: idAppliance,
  }) as TMenuSliderOption);
})

const plannings = computed(() => {
  return currentAppliance.value ? fillDailyProgSegments(currentAppliance.value.dailyprogs, [], {
    mergeSlot: true,
    generateEmptySlot: true
  }).map(dailyProg => ({
    ...dailyProg,
    ranges: dailyProg.ranges.map(range => ({
      ...range,
      duration: useDate().diffTime(range.to, range.from)
    }))
  })) : [];
})

const backLink = computed(() => {
  return ERoutes.PROGRAM;
})

const duplicatedAppliances = computed(() => {
  const currentApplianceValue = currentAppliance.value;
  const programPlanningsValue = programPlannings.value;

  if (!currentApplianceValue || !programPlanningsValue) {
    return [];
  }

  return programPlanningsValue
      .filter(({idAppliance}) => idAppliance !== currentApplianceValue.idAppliance)
      .map(({idAppliance, nameAppliance, availableModes}) => {
        const enable = currentApplianceValue.availableModes.every(mode =>
            availableModes.includes(mode as EApplianceMode)
        );

        const disabledLabel = enable ? undefined : t('global.notAvailable');

        return {
          id: idAppliance,
          enable,
          disabledLabel,
          name: nameAppliance,
        } as TPopinListCheckItem;
      });
});


const editLink = (idAppliance: number, day: number) => {
  return `${ERoutes.PROGRAM}/${programId.value}/${ERoutes.ADDON_PLANNING}/${idAppliance}/${ERoutes.ADDON_EDIT}/${day}`;
}

const toggleProgram = () => {
  const programValue = program.value;

  ProgramModule.updateProgram({
    id: programValue.id,
    body: {
      enabled: !programValue.enabled
    }
  })
      .then((programs: TPrograms) => {
            if (!programValue.enabled) {
              const updatedProgram = programs.find(program => program.id === programValue.id);
              MixpanelService.trackProgramEvent(ETrackProgramEvent.ACTIVATED, {
                content: updatedProgram,
                enabled: updatedProgram?.enabled,
                id: updatedProgram?.id,
                name: updatedProgram?.name,
              });
            }
          }
      )
      .finally(() => {
        if (ProgramModule.hasActiveProgramTemperatureControl && hasTemperatureControlReminder())
          isTemperatureControlModalOpen.value = true;
      })
}

const setCurrent = (value: TMenuSliderOption['value']) => {
  currentSliderMenuAppliance.value = programPlannings.value!.findIndex(({idAppliance}) => idAppliance === value);
  idApplianceRef.value = value as number;
}

const menuLink = (value: TMenuSliderOption['value']) => {
  if (isMobileDevice) {
    return ERoutes.PROGRAM + "/" + programId.value + "/" + ERoutes.ADDON_PLANNING + "/" + value;
  }
  return `${ERoutes.PROGRAM}/${programId.value}/${ERoutes.ADDON_PLANNING}/${value}/${ERoutes.ADDON_EDIT}/${route.params.day}`;
}

const openPopinRename = () => {
  isOpenPopinRename.value = true;
  document.dispatchEvent(new Event("closeDropDownMenu"));
  pageScroll(false);
}

const closePopinRename = () => {
  isOpenPopinRename.value = false;
  pageScroll(true);
}

const submitRename = (formDatas: TRenameProgramDatas) => {
  MixpanelService.trackProgramEvent(ETrackProgramEvent.UPDATE, {
    name: program.value.name,
    enabled: program.value.enabled,
    id: programId.value,
    content: program.value,
  })

  ProgramModule.updateProgram({
    id: programId.value,
    body: {
      name: formDatas.programName
    }
  })
      .then(() => {
        closePopinRename();
      })
      .catch(console.error);
}

const openPopinDuplicate = () => {
  isOpenPopinDuplicate.value = true;
  document.dispatchEvent(new Event("closeDropDownMenu"));
  pageScroll(false);
}

const closePopinDuplicate = () => {
  isOpenPopinDuplicate.value = false;
  pageScroll(true);
}

const openPopinDelete = () => {
  isOpenPopinDelete.value = true;
  document.dispatchEvent(new Event("closeDropDownMenu"));
  pageScroll(false);
}

const closePopinDelete = () => {
  isOpenPopinDelete.value = false;
  pageScroll(true);
}

const deleteProgram = () => {
  ProgramModule.deleteProgram(programId.value)
      .then(() => {
        router.push(backLink.value);
      })
      .catch(console.error);
}


const openPopinApplyToAppliances = () => {
  isOpenPopinApplyToAppliances.value = true;
  pageScroll(false);
}

const closeApplyPlanningToAppliances = () => {
  isOpenPopinApplyToAppliances.value = false;
  pageScroll(true);
}

const submitDuplicate = (formDatas: TDuplicateProgramDatas) => {
  ProgramModule.createOrDuplicateProgram({
    id: programId.value,
    name: formDatas.programName
  })
      .then(() => {
        router.push(backLink.value);
      })
      .catch(() => {
        closePopinDuplicate()
        duplicateError.value = t('duplicate.error')
      });
}

const applyPlanningToAppliances = ({items}: TPopinListCheckDatas) => {
  if (items.length > 0) {
    ProgramModule.duplicateProgramPlanning({
      idProgram: programId.value,
      idSource: currentAppliance.value!.id,
      idAppliances: items
    })
        .then(() => {
          isOpenPopinApplyToAppliances.value = false;
          pageScroll(true);
        });
  } else {
    isOpenPopinApplyToAppliances.value = false;
    pageScroll(true);
  }
}

const getProgram = () => {
  ProgramModule.getProgram({
    id: programId.value,
    forceRequest: true
  }).then(() => {
    if (route.params.idAppliance) {
      idApplianceRef.value = parseInt(route.params.idAppliance as string);
    } else if (sliderOptions.value.length > 0) {
      idApplianceRef.value = parseInt(sliderOptions.value[0].value as string);
    }

    if (programPlannings.value.length > 0) {
      currentSliderMenuAppliance.value = programPlannings.value!.findIndex(({idAppliance}) => idAppliance === idApplianceRef.value);
    }
  });
}

onBeforeMount(() => {
  getProgram()
})

watch(idApplianceRef, (newValue, oldValue) => {
  if (oldValue !== -1 && oldValue !== newValue) {
    if (window.innerWidth >= 768) {
      router.replace(editLink(idApplianceRef.value, new Date().getDay()));
    } else {
      MixpanelService.track(ETrackPageViewEvent.UPDATED)
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyVPageMain, {
      homeLink: _unref(ERoutes).HOME,
      logo: _unref(logo),
      title: _unref(g_appTitle),
      hasFixedButtonBottom: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (program.value)
            ? (_openBlock(), _createBlock(MyVHeaderMainPage, {
                key: 0,
                title: _ctx.$t('programPlanning.title'),
                subtitle: _ctx.$t(program.value.name)
              }, {
                HeaderMainPage__breadcrumb: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: backLink.value,
                    class: "HeaderMainPage__breadcrumb-link"
                  }, {
                    default: _withCtx(() => [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "HeaderMainPage__breadcrumb-link-icon" }, [
                        _createElementVNode("svg", {
                          class: "icon icon-arrow-left",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
                        ])
                      ], -1)),
                      _createElementVNode("div", _hoisted_2, " < " + _toDisplayString(_ctx.$t("programPlanning.breadcrumb.backLink")), 1)
                    ]),
                    _: 1
                  }, 8, ["to"])
                ]),
                HeaderMainPage__right: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    (program.value)
                      ? (_openBlock(), _createBlock(MyVCustomSwitch, {
                          key: 0,
                          "onUpdate:modelValue": toggleProgram,
                          value: program.value.enabled,
                          size: _unref(ESwitchSize).Big
                        }, null, 8, ["value", "size"]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(MyVDropDownMenu, {
                      direction: _unref(EDropDownMenuDirection).BottomRight
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "dropDownMenu__link",
                            onClick: openPopinRename
                          }, _toDisplayString(_ctx.$t('global.rename')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "dropDownMenu__link",
                            onClick: openPopinDuplicate
                          }, _toDisplayString(_ctx.$t('global.duplicate')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("button", {
                            type: "button",
                            class: "dropDownMenu__link",
                            onClick: openPopinDelete
                          }, _toDisplayString(_ctx.$t('global.delete')), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["direction"])
                  ])
                ]),
                _: 1
              }, 8, ["title", "subtitle"]))
            : _createCommentVNode("", true)
        ]),
        (programPlannings.value && programPlannings.value.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (duplicateError.value)
                ? (_openBlock(), _createBlock(MyVErrorBanner, {
                    key: 0,
                    error: duplicateError.value,
                    onClose: _cache[0] || (_cache[0] = ($event: any) => (duplicateError.value=null)),
                    class: _normalizeClass(['error-banner'])
                  }, null, 8, ["error"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                (sliderOptions.value)
                  ? (_openBlock(), _createBlock(MyVMenuSlider, {
                      key: 0,
                      options: sliderOptions.value,
                      routeFunc: menuLink,
                      onCurrentValueChange: setCurrent,
                      defaultCurrent: idApplianceRef.value
                    }, null, 8, ["options", "defaultCurrent"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('global.planning')) + " - " + _toDisplayString(_ctx.$t(currentAppliance.value.nameAppliance)), 1),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('global.time.midnight_zero')), 1),
                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('global.time.middle_morning')), 1),
                        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t('global.time.noon')), 1),
                        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('global.time.middle_afternoon')), 1),
                        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('global.time.midnight')), 1)
                      ])
                    ]),
                    (currentAppliance.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plannings.value, (planning) => {
                            return (_openBlock(), _createBlock(_component_router_link, {
                              key: planning.dayNumber,
                              to: editLink(idApplianceRef.value, planning.dayNumber),
                              class: "ProgramPlanning__item",
                              "active-class": "active"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_22, [
                                  _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(useDate)().isoWeekday(planning.dayNumber).locale(locale.value).format('dd')), 1),
                                  _createElementVNode("span", _hoisted_24, _toDisplayString(_unref(useDate)().isoWeekday(planning.dayNumber).locale(locale.value).format('dddd')), 1)
                                ]),
                                _createVNode(PlanningBar, {
                                  className: "ProgramPlanning__planning",
                                  hideLegend: true,
                                  plannings: planning.ranges
                                }, null, 8, ["plannings"]),
                                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "ProgramPlanning__edit" }, [
                                  _createElementVNode("svg", {
                                    class: "icon icon-pen",
                                    "aria-hidden": "true"
                                  }, [
                                    _createElementVNode("use", { "xlink:href": "#icon-pen" })
                                  ])
                                ], -1))
                              ]),
                              _: 2
                            }, 1032, ["to"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(MyVButton, {
                      class: "ProgramPlanning-duplicate-button",
                      onClick: openPopinApplyToAppliances,
                      icon: "dupplicate"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('programPlanning.duplicate_to_heater')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: _ctx.$route.fullPath
                  }))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["homeLink", "logo", "title"]),
    (isOpenPopinRename.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createVNode(MyVPageWithReturn, {
            title: _ctx.$t('programPlanning.popinRename.title'),
            type: "popin",
            returnAction: closePopinRename
          }, {
            headerRight: _withCtx(() => _cache[5] || (_cache[5] = [])),
            footer: _withCtx(() => _cache[6] || (_cache[6] = [])),
            default: _withCtx(() => [
              _createVNode(MyVRenameProgram, {
                returnAction: closePopinRename,
                onSubmit: submitRename,
                programName: _ctx.$t(program.value.name)
              }, null, 8, ["programName"])
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    (isOpenPopinDuplicate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _createVNode(MyVPageWithReturn, {
            title: _ctx.$t('programPlanning.popinDuplicate.title'),
            type: "popin",
            returnAction: closePopinDuplicate
          }, {
            headerRight: _withCtx(() => _cache[7] || (_cache[7] = [])),
            footer: _withCtx(() => _cache[8] || (_cache[8] = [])),
            default: _withCtx(() => [
              _createVNode(MyVDuplicateProgram, {
                returnAction: closePopinDuplicate,
                onSubmit: submitDuplicate,
                programName: `${_ctx.$t(program.value.name)} - ${ _ctx.$t('global.copy')}`
              }, null, 8, ["programName"])
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    (isOpenPopinDelete.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createVNode(MyVPageWithReturn, {
            title: _ctx.$t('programPlanning.popinDelete.title'),
            type: "popin",
            returnAction: closePopinDelete
          }, {
            headerRight: _withCtx(() => _cache[9] || (_cache[9] = [])),
            footer: _withCtx(() => _cache[11] || (_cache[11] = [])),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_29, [
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "popinDelete" }, [
                  _createElementVNode("svg", {
                    class: "popinDelete__icon icon icon-big-colored-delete-forever",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(MyVButton, {
                    variant: "secondary",
                    onClick: closePopinDelete
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, { onClick: deleteProgram }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.submit_program_delete')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    (duplicatedAppliances.value && currentAppliance.value)
      ? (_openBlock(), _createBlock(PopinListCheck, {
          key: currentAppliance.value.idAppliance,
          open: isOpenPopinApplyToAppliances.value,
          "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((isOpenPopinApplyToAppliances).value = $event)),
          items: duplicatedAppliances.value,
          onValidate: applyPlanningToAppliances,
          onClose: closeApplyPlanningToAppliances,
          title: _ctx.$t('programPlanning.popinApplyToAppliances.title'),
          validateLabel: _ctx.$t('button.submit_program_planning_apply'),
          cancelLabel: _ctx.$t('global.cancel'),
          noItemsText: _ctx.$t('program_planning.duplication.no_compatible_appliances')
        }, {
          subHeader: _withCtx(() => [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('programPlanning.popinApplyToAppliances.subtitle', {appliance_name: currentAppliance.value.nameAppliance})
            }, null, 8, _hoisted_31)
          ]),
          _: 1
        }, 8, ["open", "items", "title", "validateLabel", "cancelLabel", "noItemsText"]))
      : _createCommentVNode("", true),
    (isTemperatureControlModalOpen.value)
      ? (_openBlock(), _createBlock(MyVTemperatureControlModal, {
          key: 4,
          onClose: _cache[2] || (_cache[2] = ($event: any) => (isTemperatureControlModalOpen.value= false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})