import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";

import {TLangSelect} from './interfaces';
import {computed, ref} from "vue";

interface Props {
  buttonClassName?: TLangSelect['buttonClassName'];
  itemClassName?: TLangSelect['itemClassName'];
  overlayClassName?: TLangSelect['overlayClassName'];
  textColor?: TLangSelect['textColor'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLangSelect',
  props: {
    buttonClassName: { default: undefined },
    itemClassName: { default: undefined },
    overlayClassName: { default: undefined },
    textColor: { default: "default" }
  },
  setup(__props: any) {


const props = __props

const isOpen = ref(false);

const locale = computed(() => IntlModule.locale)

const availableLanguages = computed(() => IntlModule.availableLanguages)
const filteredLocales = computed(() => IntlModule.filteredLocale)

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
}

const changeLocale = ({key}: { key: string }) => {
  const locale = key.toLowerCase();
  IntlModule.setLocale(locale as ELocale);
  isOpen.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, {
    overlayClassName: _ctx.$style.langDropDown + (_ctx.overlayClassName ? ` ${_ctx.overlayClassName}` : ''),
    open: isOpen.value
  }, {
    overlay: _withCtx(() => [
      _createVNode(_component_a_menu, {
        class: "menu",
        onClick: changeLocale
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableLanguages.value, (availableLanguage) => {
            return (_openBlock(), _createBlock(_component_a_menu_item, {
              key: availableLanguage,
              class: _normalizeClass(['langItem', {langItemSelected: availableLanguage === locale.value}, {itemClassName: _ctx.itemClassName !== undefined }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(availableLanguage), 1)
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(MyVButton, {
        icon: "triangle",
        variant: "text",
        "text-case": "uppercase",
        "text-color": props.textColor,
        "data-testid": "lang-selector-button",
        class: _normalizeClass([_ctx.$style.lang + (_ctx.buttonClassName ? ` ${_ctx.buttonClassName}` : ''), (isOpen.value ? _ctx.$style.toggle : undefined)]),
        primary: false,
        onClick: toggleOpen
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(filteredLocales.value), 1)
        ]),
        _: 1
      }, 8, ["text-color", "class"])
    ]),
    _: 1
  }, 8, ["overlayClassName", "open"]))
}
}

})