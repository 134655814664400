import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, renderList as _renderList, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ProfileStep" }
const _hoisted_2 = { class: "ProfileStep__form popinForm" }
const _hoisted_3 = { class: "popinForm__main" }
const _hoisted_4 = { class: "providerEditContractContainer" }
const _hoisted_5 = {
  key: 0,
  class: "providerContractBlock"
}
const _hoisted_6 = { class: "providerHeader" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "providerContractValue" }
const _hoisted_9 = { class: "contractHeader" }
const _hoisted_10 = { class: "providerContractValue" }
const _hoisted_11 = { class: "contractHeader" }
const _hoisted_12 = { class: "providerContractValue" }
const _hoisted_13 = { class: "contractHeader" }
const _hoisted_14 = { class: "providerContractValue" }
const _hoisted_15 = {
  key: 0,
  class: "ProfileStep__form__offpeak"
}
const _hoisted_16 = { class: "ProfileStep__form__offpeak__progress" }
const _hoisted_17 = { class: "ProfileStep__form__offpeak__hours__tag" }
const _hoisted_18 = { class: "ProfileStep__form__offpeak__hours__form" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "offPeakError" }
const _hoisted_21 = { class: "tarifContainer" }
const _hoisted_22 = {
  key: 0,
  class: "contractStartDate"
}
const _hoisted_23 = {
  key: 0,
  class: "error"
}
const _hoisted_24 = { class: "popinForm__footer" }
const _hoisted_25 = { class: "ProfileStep__form__save" }
const _hoisted_26 = { class: "BottomPopin__text" }
const _hoisted_27 = { class: "BottomPopin__title" }
const _hoisted_28 = { class: "BottomPopin__subtitle" }
const _hoisted_29 = { class: "BottomPopin__buttons" }

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import ERoutes from "@/router/routes";
import {AuthModule, ConsoModule, ContractModule, SiteModule} from "@/store";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {
  MaxOffPeakHoursRuleInMinutes,
  MaxOffPeakHoursRuleInMinutesUK,
  TContract,
  TContractEditable,
  TContractTimePeriods
} from "@/services/Contract/interfaces";
import {
  g_firstDayTime,
  g_lastDayTime,
  g_timeFormat,
  mergePeriods,
  splitTimePeriods
} from "@/helpers/domains/dailyprogs";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter} from "vue-router";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {notification} from 'ant-design-vue';
import {useDate} from "@/helpers/dates/date-utils";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import useCurrency from "@/composables/currency";
import {NUMBER_OF_DAYS_IN_MONTH} from "@/helpers/dates/date";
import {aggregatedDayFormat} from "@/services/Conso/interfaces";
import dayjs, {Dayjs} from "dayjs";
import MyVAntdTimepicker from "@/components/ui/molecules/MyVTimepicker/MyVTimepicker.vue";
import MyVDatepicker from "@/components/ui/molecules/MyVDatepicker/MyVDatepicker.vue";
import MyVInput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {useResponsive} from "@/composables/useResponsive";

interface Props {
  type?: string;
  contractCreation?: Partial<TContract> | null;
}

const minPriceKwh = 0.01;
const maxPriceKwh = 2;
const minPriceSubscription = 0.01;
const maxPriceSubscription = 500;


export default /*@__PURE__*/_defineComponent({
  __name: 'EditOrCreateContractView',
  props: {
    type: { default: 'page' },
    contractCreation: { default: undefined }
  },
  emits: ['resetCreation', 'noProvider'],
  setup(__props: any, { emit: __emit }) {


const props = __props

const emit = __emit

const form = reactive<TContractEditable>({
  kwhBasePrice: 0,
  companyName: '',
  subscribedPower: 0,
  creationDateTime: '',
  offpeakHours: [],
  companyId: null,
  subscriptionBasePrice: 0,
  apiContractId: null,
  name: '',
  siteId: getCurrentSiteIdFromCookie(AuthModule.user),
  id: null,
  kwhOffpeakHourPrice: null,
  subscriptionPeakAndOffPeakHourBasePrice: null,
  kwhPeakHourPrice: 0,
  startDate: useDate().format(aggregatedDayFormat),
  isPeakOffPeakContract: null,
  endDate: null,
  isDefault: false,
})

const formHasChanged = ref(false);
const showCancelContractPopin = ref(false);
const allowOffpeakHours = ref(false);
const error = ref<string | null>(null);
const offPeakError = ref<string | null>(null);
const offpeakHoursCalc = ref<TContractTimePeriods>([]);
const startDateError = ref<string | null>(null);

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const {currency} = useCurrency()
const {isMobile} = useResponsive()

const isSiteCountryUK = computed(() => SiteModule.isSiteCountryUK)

const contract = computed(() => {
  if (!route.query.contractId || !contractsList.value?.length) return null;
  return contractsList.value!.find(contract => contract.id === parseInt(route.query.contractId as string));
})

const currentContractIndex = computed(() => {
  if (route.query.mode === 'create') return null;
  return contractsList.value.findIndex(contract => contract.id === parseInt(route.query.contractId as string));
})

const contractKey = computed(() => {
  return ContractModule.contractKey;
})

const contractsList = computed((): TContract[] => {
  return ContractModule.contractsList;
})

const isNewestContrat = computed(() => {
  return currentContractIndex.value === 0 || !contractsList.value.length;
})

const isOldestContract = computed(() => {
  return currentContractIndex.value === contractsList.value.length - 1;
})

const disabledDates = (date: Dayjs): boolean => {
  const currentDate = useDate(date);
  const today = useDate();

  if (!contract.value) {

    const startDate = useDate(contractsList.value[0].startDate).add(2, 'day');
    return currentDate.isBefore(startDate) || currentDate.isAfter(today);
  } else {
    const nextContractEndDate = useDate(contractsList.value[currentContractIndex.value! + 1].endDate).add(1, 'day');
    const previousContractStartDate = contractsList.value[currentContractIndex.value! - 1]
        ? useDate(contractsList.value[currentContractIndex.value! - 1].startDate).substract(2, 'day')
        : useDate();

    return currentDate.isBefore(nextContractEndDate) ||
        currentDate.isAfter(previousContractStartDate) ||
        currentDate.isAfter(today);
  }
}

const goBackToContracts = () => {
  router.push(ERoutes.CONTRACT);
}

const offPeakWidth = (from: any, to: any) => {
  const fromSplit = from.split(':');
  const toSplit = to.split(':');
  from = parseFloat(from);
  to = parseFloat(to);

  if (fromSplit[1] == 30) {
    from += .5;
  } else if (fromSplit[1] > 30) {
    from += 1;
  }

  if (toSplit[1] == 30) {
    to += .5;
  } else if (toSplit[1] > 30) {
    to += 1;
  }

  return Math.abs(((100 / 24) * (from - to))).toString() + '%';
}

const offPeakStart = (from: any) => {
  const fromSplit = from.split(':');
  from = parseFloat(from);

  if (fromSplit[1] == 30) {
    from += .5;
  } else if (fromSplit[1] > 30) {
    from += 1;
  }

  return Math.abs((100 / 24) * from).toString() + '%';
}

const validateOffPeakRanges = (offpeakHours: TContractTimePeriods) => {
  let total = 0;

  offpeakHours!.forEach(el => {
    const from = useDate(el.from, 'HH:mm')
    const to = useDate(el.to, 'HH:mm')
    const duration = to.diff(from.toDate(), 'minutes');

    total += (duration)
  });

  const maxOffPeakHoursRuleInMinutes = isSiteCountryUK.value ? MaxOffPeakHoursRuleInMinutesUK : MaxOffPeakHoursRuleInMinutes
  if (total > maxOffPeakHoursRuleInMinutes) {
    offPeakError.value = t('profile.contact.off_peak_error', {hours: maxOffPeakHoursRuleInMinutes / 60})
    return false;
  }

  return !!total;
}

const cancelContract = () => {
  if (formHasChanged.value) {
    showCancelContractPopin.value = true;
    return;
  }

  confirmCancel();
}

const confirmCancel = () => {
  router.push(ERoutes.CONTRACT);
}

const save = async () => {
  if (!form.startDate && !isOldestContract.value && contractsList.value.length > 0) {
    startDateError.value = t('profile.contract.start_date_error');
    return false;
  }

  offPeakError.value = null;
  let payload: Partial<TContractEditable> = {
    id: form.id!,
    name: form.name!,
    subscribedPower: form.subscribedPower,
    companyName: form.companyName!,
    companyId: form.companyId,
    apiContractId: form.apiContractId,
    siteId: form.siteId!,
    subscriptionBasePrice: form.subscriptionBasePrice ? (isSiteCountryUK.value ? form.subscriptionBasePrice * NUMBER_OF_DAYS_IN_MONTH : form.subscriptionBasePrice) : 0,
    kwhBasePrice: typeof form.kwhBasePrice == "string" && !isNaN(parseFloat(form.kwhBasePrice)) ? parseFloat(form.kwhBasePrice) : form.kwhBasePrice ?? 0,
    startDate: isOldestContract.value || !contractsList.value.length ? null : useDate(form.startDate).format(aggregatedDayFormat),
    endDate: (!contractsList.value.length || isNewestContrat.value) ? null : (!contract.value ? null : useDate(form.endDate).format(aggregatedDayFormat)),
  }

  if (form.subscriptionPeakAndOffPeakHourBasePrice !== null) {
    const offpeakHours = splitTimePeriods(form.offpeakHours);

    if (!validateOffPeakRanges(offpeakHours)) {
      return false;
    }

    payload = {
      id: form.id!,
      name: form.name!,
      subscribedPower: form.subscribedPower,
      companyName: form.companyName!,
      siteId: form.siteId!,
      companyId: form.companyId,
      apiContractId: form.apiContractId,
      subscriptionPeakAndOffPeakHourBasePrice: isSiteCountryUK.value ? form.subscriptionPeakAndOffPeakHourBasePrice * NUMBER_OF_DAYS_IN_MONTH : form.subscriptionPeakAndOffPeakHourBasePrice,
      kwhPeakHourPrice: form.kwhPeakHourPrice!,
      kwhOffpeakHourPrice: form.kwhOffpeakHourPrice!,
      offpeakHours: offpeakHours,
      startDate: (isOldestContract.value || contractsList.value.length === 0) ? null :  useDate(form.startDate).format(aggregatedDayFormat),
      endDate: form.endDate,
    }
  }
  if (contract.value) {
    const contractsListValue = contractsList.value?.map((contract, index) => {
      if (index - 1 === currentContractIndex.value) {
        return {
          ...contract,
          endDate: useDate(payload.startDate).substract(1, 'day').format(aggregatedDayFormat),
        }
      }
      if (index === currentContractIndex.value) {
        return payload;

      }
      return contract
    }).sort((a, b) => {

      if (!a.startDate) return -1;
      if (!a.endDate) return 1;
      const _aDate = useDate(a.startDate!);
      const _bDate = useDate(b.startDate!);

      return _aDate.unix() - _bDate.unix();
    });

    await ContractModule.updateContract(contractsListValue).then(() => {
      notification.success({
        message: t('profile.contract.success_notification.title'),
        description: t('profile.contract.success_notification.desc'),
        duration: 3
      })
    });
  } else {
    const contractsListValue = contractsList.value.map(contract => {
      return {
        ...contract,
        endDate: (!contract.endDate && !isNewestContrat.value) ? useDate(payload.startDate).substract(1, 'day').format(aggregatedDayFormat) : contract.endDate
      }
    });

    const contracts = [
      payload,
      ...contractsListValue
    ].sort((a, b) => {
      if (!a.startDate) return -1;
      if (!a.endDate) return 1;
      const _aDate = useDate(a.startDate!);
      const _bDate = useDate(b.startDate!);

      return _aDate.unix() - _bDate.unix();
    });

    await ContractModule.createContract(contracts as TContractEditable[]).then(() => {
      notification.success({
        message: t('profile.contract.success_notification.title'),
        description: t('profile.contract.success_notification.desc')
      });
    })
  }

  ConsoModule.consoSavings.responses = {};
  ConsoModule.consumption.responses = {};
  ConsoModule.repartition.responses = {};
  ConsoModule.summaryConsumption.responses = {};
  emit('resetCreation');
  await ContractModule.getContractsList(true);
  goBackToContracts();
}

const emitNoProvider = () => {
  emit('resetCreation');
  emit('noProvider');
}

const addOffPeak = () => {
  form.offpeakHours.push(form.offpeakHours.length > 0 ? {
    from: dayjs().format(g_timeFormat),
    to: dayjs().format(g_timeFormat)
  } : {
    from: "23:00",
    to: "07:00"
  });
  offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
}

const removeOffPeak = (index: number) => {
  form.offpeakHours.splice(index, 1);
  offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
}

const checkAndMergePeriods = () => {
  let startMidnight = false;
  let endMidnight = false;
  form.offpeakHours.forEach((hour) => {
    if (hour.from === g_firstDayTime) {
      startMidnight = true;
    } else if (hour.to === g_lastDayTime) {
      endMidnight = true;
    }
  })

  if (startMidnight && endMidnight) {
    form.offpeakHours = mergePeriods(form.offpeakHours);
  }
}

const fetchContract = (afterDelete = false) => {
  ContractModule.getContractsList().then(() => {
    if (route.query.mode === 'edit' && route.query.contractId && contractsList.value.length) {
      Object.assign(form, contractsList.value.find(contract => contract.id === parseInt(route.query.contractId as string)) as TContractEditable);
      if (isSiteCountryUK.value) {
        if (form.subscriptionBasePrice) form.subscriptionBasePrice = form.subscriptionBasePrice / NUMBER_OF_DAYS_IN_MONTH
        if (form.subscriptionPeakAndOffPeakHourBasePrice) form.subscriptionPeakAndOffPeakHourBasePrice = form.subscriptionPeakAndOffPeakHourBasePrice / NUMBER_OF_DAYS_IN_MONTH
      }
      offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
      checkAndMergePeriods();
      if (form.offpeakHours?.length) {
        allowOffpeakHours.value = true;
      }
    }
  }).catch(e => {
    if (afterDelete) {
      Object.assign(form, {
        kwhBasePrice: 0,
        companyName: '',
        subscribedPower: 0,
        creationDateTime: '',
        offpeakHours: [],
        companyId: null,
        subscriptionBasePrice: 0,
        apiContractId: null,
        name: '',
        siteId: getCurrentSiteIdFromCookie(AuthModule.user),
        id: null,
        kwhOffpeakHourPrice: null,
        subscriptionPeakAndOffPeakHourBasePrice: null,
        kwhPeakHourPrice: 0,
        startDate: null,
        endDate: null
      })
    }
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  })
}

const changePeriod = (open: boolean) => {
  if (open) return;
  offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
}

onBeforeMount(() => {
  fetchContract();
  SiteModule.getSites()
  if (route.query.mode == 'create') {
    emit('noProvider');
  }
})

watch(() => props.contractCreation, (newValue, oldValue) => {
  if (newValue !== oldValue && oldValue === null) {
    Object.assign(form, {
      ...form,
      ...newValue
    })
    checkAndMergePeriods();
    offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
  }
})

watch(contractKey, () => {
  checkAndMergePeriods();
  offpeakHoursCalc.value = splitTimePeriods(form.offpeakHours);
})

watch(allowOffpeakHours, (newValue) => {
  if (newValue && (!form.offpeakHours.length)) {
    form.offpeakHours.push({
      from: "23:00",
      to: "07:00"
    })
  }
})

watch(form, (newValue, oldValue) => {
  if (newValue !== null && oldValue) {
    formHasChanged.value = true;
  }
}, {deep: true})

watch(() => form.companyName, (newValue, oldValue) => {
  if (newValue !== oldValue && oldValue !== '' && newValue === 'choose_company.other_company') {
    form.kwhBasePrice = null;
    form.kwhPeakHourPrice = null;
    form.kwhOffpeakHourPrice = null;
    form.subscriptionPeakAndOffPeakHourBasePrice = null;
    form.subscriptionBasePrice = null;
  }
})

watch(() => form.startDate, (newValue) => {
  if (newValue)
    startDateError.value = null;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (form && form.companyName)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h2", {
                    innerHTML: _ctx.$t('profile.contract.provider')
                  }, null, 8, _hoisted_7),
                  _createElementVNode("span", { onClick: emitNoProvider }, _toDisplayString(_ctx.$t('profile.contract.edit')), 1)
                ]),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(form.companyName ?? null)), 1),
                (form.businessName || form.name)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.contract.contractr')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(form.businessName ?? form.name)), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (!isSiteCountryUK.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.contract.subscribed_power')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(`${form.subscribedPower} ${_unref(EEnergyUnit).kVA}`), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.contract.contract_type_label')), 1)
                ]),
                _createElementVNode("div", _hoisted_14, _toDisplayString(form.subscriptionPeakAndOffPeakHourBasePrice !== null ? _ctx.$t('profile.contract.hours_type') : _ctx.$t('profile.contract.base_type')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        (form && form.companyName)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              onSubmit: _withModifiers(save, ["prevent"])
            }, [
              (form.offpeakHours.length )
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.offpeak_hours')), 1),
                      (_openBlock(), _createElementBlock("div", {
                        key: form.offpeakHours,
                        class: "ProfileStep__form__offpeak__progress__bar"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(offpeakHoursCalc.value, (el, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            style: _normalizeStyle({
                  position: 'absolute',
                  background: '#18D27BFF',
                  height: '100%',
                  width: offPeakWidth(el.from, el.to),
                  left: offPeakStart(el.from)
                })
                          }, null, 4))
                        }), 128))
                      ]))
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.offpeakHours, (data, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "ProfileStep__form__offpeak__hours"
                      }, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('profile.contract.period')) + " " + _toDisplayString(index + 1), 1),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.from')), 1),
                          _createVNode(MyVAntdTimepicker, {
                            onOpenChange: changePeriod,
                            "minute-step": 1,
                            modelValue: data.from,
                            "onUpdate:modelValue": ($event: any) => ((data.from) = $event),
                            allowClear: "",
                            placeholder: ''
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('profile.contract.to')), 1),
                          _createVNode(MyVAntdTimepicker, {
                            onOpenChange: changePeriod,
                            "minute-step": 1,
                            modelValue: data.to,
                            "onUpdate:modelValue": ($event: any) => ((data.to) = $event),
                            allowClear: "",
                            placeholder: ''
                          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                          (form.offpeakHours.length > 1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                onClick: ($event: any) => (removeOffPeak(index)),
                                class: "ProfileStep__form__offpeak__hours__form__remove"
                              }, _cache[8] || (_cache[8] = [
                                _createElementVNode("svg", {
                                  class: "icon icon-cross",
                                  "aria-hidden": "true"
                                }, [
                                  _createElementVNode("use", { "xlink:href": "#icon-cross" })
                                ], -1)
                              ]), 8, _hoisted_19))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(offPeakError.value), 1),
                    _createElementVNode("span", {
                      onClick: addOffPeak,
                      class: "ProfileStep__form__offpeak__add"
                    }, _toDisplayString(_ctx.$t('profile.contract.add_offpeak_time')), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_21, [
                (contractsList.value.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!isOldestContract.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createVNode(MyVDatepicker, {
                              disabledDate: disabledDates,
                              format: "DD/MM/YYYY",
                              placeholder: _ctx.$t('profile.contract.start_date'),
                              label: _ctx.$t('profile.contract.start_date') + ' *',
                              value: form.startDate,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.startDate) = $event)),
                              showTime: false
                            }, null, 8, ["placeholder", "label", "value"]),
                            (startDateError.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(startDateError.value), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (form.offpeakHours.length  )
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createVNode(MyVAntdinput, {
                        hasAnyStep: "",
                        class: "tarifInput",
                        id: "peakHourTarif",
                        label: _ctx.$t('profile.contract.peak_hour_tarif'),
                        "input-type": _unref(EInputType).NumberInput,
                        value: form.kwhPeakHourPrice,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.kwhPeakHourPrice) = $event)),
                        "input-required": "",
                        min: minPriceKwh,
                        max: maxPriceKwh,
                        addonAfter: _unref(t)('contract.month_kwh', {currency: _unref(currency)})
                      }, null, 8, ["label", "input-type", "value", "addonAfter"]),
                      _createVNode(MyVAntdinput, {
                        hasAnyStep: "",
                        class: "tarifInput",
                        id: "offpeakHourTarif",
                        label: _ctx.$t('profile.contract.offpeak_hour_tarif'),
                        "input-type": _unref(EInputType).NumberInput,
                        value: form.kwhOffpeakHourPrice,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.kwhOffpeakHourPrice) = $event)),
                        "input-required": "",
                        min: minPriceKwh,
                        max: maxPriceKwh,
                        addonAfter: _unref(t)('contract.month_kwh', {currency: _unref(currency)})
                      }, null, 8, ["label", "input-type", "value", "addonAfter"]),
                      _createVNode(MyVInput, {
                        hasUnit: "",
                        hasAnyStep: "",
                        id: "subscribedTarif",
                        label: isSiteCountryUK.value? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif'),
                        "input-type": _unref(EInputType).NumberInput,
                        value: form.subscriptionPeakAndOffPeakHourBasePrice,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.subscriptionPeakAndOffPeakHourBasePrice) = $event)),
                        "input-required": "",
                        min: minPriceSubscription,
                        max: maxPriceSubscription,
                        addonAfter: isSiteCountryUK.value ?  _unref(t)('contract.daily_price', {currency: _unref(currency)}) : _unref(t)('contract.month_price', {currency: _unref(currency)})
                      }, null, 8, ["label", "input-type", "value", "addonAfter"])
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(MyVInput, {
                        hasAnyStep: "",
                        class: "tarifInput",
                        id: "kWhTarif",
                        label: _ctx.$t('profile.contract.kwh_tarif'),
                        "input-type": _unref(EInputType).NumberInput,
                        value: form.kwhBasePrice,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((form.kwhBasePrice) = $event)),
                        "input-required": "",
                        min: minPriceKwh,
                        max: maxPriceKwh,
                        addonAfter: _unref(t)('contract.month_kwh', {currency: _unref(currency)})
                      }, null, 8, ["label", "input-type", "value", "addonAfter"]),
                      _createVNode(MyVInput, {
                        hasAnyStep: "",
                        id: "subscribedTarif",
                        label: isSiteCountryUK.value? _ctx.$t('profile.contract.subscribed_tarif.daily') : _ctx.$t('profile.contract.subscribed_tarif'),
                        "input-type": _unref(EInputType).NumberInput,
                        value: form.subscriptionBasePrice,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((form.subscriptionBasePrice) = $event)),
                        "input-required": "",
                        min: minPriceSubscription,
                        max: maxPriceSubscription,
                        addonAfter: isSiteCountryUK.value ?  _unref(t)('contract.daily_price', {currency: _unref(currency)}) : _unref(t)('contract.month_price', {currency: _unref(currency)})
                      }, null, 8, ["label", "input-type", "value", "addonAfter"])
                    ], 64))
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(MyVButton, {
                    variant: "secondary",
                    onClick: cancelContract
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.cancel')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, {
                    type: _unref(EButtonType).Submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.submit')), 1)
                    ]),
                    _: 1
                  }, 8, ["type"])
                ])
              ])
            ], 32))
          : _createCommentVNode("", true)
      ])
    ]),
    (showCancelContractPopin.value)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
          key: 0,
          onCancel: _cache[7] || (_cache[7] = ($event: any) => (showCancelContractPopin.value = false)),
          type: "popin",
          "return-action": () => showCancelContractPopin.value = false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[9] || (_cache[9] = _createElementVNode("svg", {
                class: "icon icon-big-colored-delete-forever",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
              ], -1)),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t('profile.contract.popin.cancel_title')), 1),
                _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('profile.contract.popin.cancel_subtitle')), 1)
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(MyVButton, {
                  variant: "secondary",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => {showCancelContractPopin.value = false; formHasChanged.value = false;})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, { onClick: confirmCancel }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }, 40, ["return-action"]))
      : _createCommentVNode("", true)
  ]))
}
}

})