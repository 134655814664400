import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { class: "popinForm__greyBlock" }
const _hoisted_3 = { class: "inner" }
const _hoisted_4 = { class: "popinForm__footer" }

import {onBeforeMount, reactive, ref, watch} from "vue";
import {TNewPasswordForm, TNewPasswordFormDatas} from "./interfaces";
import {checkPasswordSecurity, containsSpecialChars, hasUpper} from '@/helpers/string/password';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import MyVPasswordHelper from "@/components/ui/organisms/MyVPasswordHelper/MyVPasswordHelper.vue";

interface Props {
  formId?: TNewPasswordForm["formId"];
  onSubmit: TNewPasswordForm["onSubmit"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVNewPasswordForm',
  props: {
    formId: { default: undefined },
    onSubmit: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const form = reactive<TNewPasswordFormDatas>({
  password: '',
  confirmPassword: '',
})
const passwordIsValid = ref(true)
const passwordHasUpper = ref(false);
const passwordHasHeightCaracters = ref(false);
const passwordHasSpecialCaracter = ref(false);
const confirmPasswordIsValid = ref(false);

const updateConfirmPasswordIsValid = (password: string, confirmPassword: string) => {
  confirmPasswordIsValid.value = confirmPassword === password && passwordIsValid.value;
}

const onFinish = () => {
  props.onSubmit(form);
}

onBeforeMount(() => {
  updateConfirmPasswordIsValid(form.password, form.confirmPassword);
})

watch(() => form.password, (newValue) => {
  passwordHasHeightCaracters.value = newValue.length >= 8
  passwordHasUpper.value = hasUpper(newValue)
  passwordHasSpecialCaracter.value = containsSpecialChars(newValue)
  passwordIsValid.value = checkPasswordSecurity(newValue);
  updateConfirmPasswordIsValid(newValue, form.confirmPassword);
})

watch(() => form.confirmPassword, (newValue) => {
  updateConfirmPasswordIsValid(form.password, newValue);

})

return (_ctx: any,_cache: any) => {
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    id: _ctx.formId,
    model: form,
    onFinish: onFinish,
    class: "popinForm new-password"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('newPassword.subtitle')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(MyVAntdinput, {
            label: _ctx.$t('form.password'),
            inputType: _unref(EInputType).Password,
            value: form.password,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.password) = $event)),
            id: "password",
            inputRequired: true
          }, null, 8, ["label", "inputType", "value"]),
          _createVNode(MyVAntdinput, {
            label: _ctx.$t('form.confirm_password'),
            inputType: _unref(EInputType).Password,
            value: form.confirmPassword,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.confirmPassword) = $event)),
            id: "confirm_password",
            inputRequired: true,
            error: confirmPasswordIsValid.value ? undefined : _ctx.$t('firstConnection.confirm_password_error')
          }, null, 8, ["label", "inputType", "value", "error"]),
          _createVNode(MyVPasswordHelper)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.formId !== undefined)
          ? (_openBlock(), _createBlock(MyVButton, {
              key: 0,
              disabled: !confirmPasswordIsValid.value,
              type: _unref(EButtonType).Submit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('button.new_password_form')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "type"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["id", "model"]))
}
}

})