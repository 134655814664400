import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "HeaderMainPage__breadcrumb-link-text" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

import {
  aggregatedMonthFormat, aggregatedWeekFormat,
  aggregatedYearFormat,
  EAggregationAlias,
  labelMonthsFormat,
  weekYearFormat
} from "@/services/Conso/interfaces";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import {AuthModule, ConsoModule, IntlModule, SiteModule, SwitchConsoModule} from "@/store";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVMenuSlider from "@/components/ui/molecules/MyVMenuSlider/MyVMenuSlider.vue";
import {TRepartitionDetailItem} from "@/views/domains/Conso/RepartitionDetail/interfaces";
import ERoutes from "@/router/routes";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import MyVEquipmentPopin from "@/components/ui/organisms/MyVEquipmentPopin/MyVEquipmentPopin.vue";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import percent from "@/helpers/number/percent";
import {useRoute, useRouter} from "vue-router";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {getSubcategoriesTotalConsoByType} from "@/helpers/domains/conso";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {TSite} from "@/services/Site/interfaces";
import MyVPageConso from "@/components/ui/organisms/layouts/MyVPageConso/MyVPageConso.vue";
import {computed, onBeforeMount, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import MyVRepartitionDetailCard from "@/components/domains/Conso/MyVReparitionDetailCard/MyVRepartitionDetailCard.vue";
import MyVConsoHeader from "@/components/domains/Conso/MyVConsoHeader/MyVConsoHeader.vue";
import {useResponsive} from "@/composables/useResponsive";
import MyVDailyConsumption from "@/components/domains/Conso/MyVDailyConsumption/MyVDailyConsumption.vue";
import {useDate} from "@/helpers/dates/date-utils";
import useCurrency from "@/composables/currency";
import MyVSwitch from "@/components/ui/atoms/MyVSwitches/MyVSwitch.vue";
import {ESwitchType} from "@/components/ui/atoms/MyVSwitches/interfaces";
import MyVDropDown from "@/components/ui/atoms/MyVDropDown/MyVDropDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RepartitionDetailView',
  setup(__props) {

const showCompareValues = ref(false);
const showEquipmentPopin = ref(false);
const compareDate = ref<string | null>(null);
const compareDateFormat = ref<string | null>(null);
const currentDate = ref<string | null>(null);
const error = ref<string | null>(null);
const comparingType = ref<string | null>(null);

const route = useRoute()
const router = useRouter()
const {t} = useI18n()
const {isMobile} = useResponsive()
const {currency} = useCurrency()

const compareRepartition = computed(() => {
  return (ConsoModule.consumptionFullData!.responses[compareDateFormat.value!]?.breakdown);
})

const repartitionConso = computed(() => {
  return (ConsoModule.consumptionFullData!.responses[currentDate.value!]?.breakdown);
})

const hasDetail = computed(() => {
  return route.params.category;
})

const isYearType = computed(() => {
  return route.query.type === 'year';
})

const compareValues = computed(() => {
  const res = []
  const lastMonth = useDate(currentDate.value!).substract(1, 'months');
  const lastYear = useDate(currentDate.value!).substract(1, 'years');
  const lastYear2 = useDate(currentDate.value!).substract(2, 'years');
  if (!isYearType.value) {
    res.push(
        {
          value: lastMonth.format(aggregatedMonthFormat),
          label: t('conso.compare.last_period') + ' - ' + lastMonth.locale(locale.value).format(labelMonthsFormat),
          type: 'month'
        },
        {
          value: lastYear.format(aggregatedMonthFormat),
          label: t('conso.compare.last_year') + ' - ' + lastYear.locale(locale.value).format(labelMonthsFormat),
          type: 'year'
        }
    )
  } else {
    res.push(
        {
          value: lastYear.format(aggregatedYearFormat),
          label: t('conso.compare.last_year') + ' - ' + lastYear.locale(locale.value).format(aggregatedYearFormat),
          type: 'year'
        },
        {
          value: lastYear2.format(aggregatedYearFormat),
          label: t('conso.compare.last_year_2') + ' - ' + lastYear2.locale(locale.value).format(aggregatedYearFormat),
          type: 'year'
        }
    )
  }

  return res;
})

const currentTotalConso = computed(() => {
  if (repartitionData.value) {

    return repartitionData.value!.find((el) => {
      return el.name === route.params.category;
    })
  }
  return null;
})

const compareRepartitionData = computed(() => {
  if (!compareRepartition.value || (compareRepartition.value && !Object.keys(compareRepartition.value).length)) {
    return null;
  }

  const res: any[] = [];

  let totalSum = 0;
  compareRepartition.value.categories.forEach(el => {
    let total = 0;

    el.subcategories!.forEach(sub => {
      total += consoType.value ? sub.totalConsumptionInWh! : sub.totalConsumptionInCurrency!;
      totalSum += consoType.value ? sub.totalConsumptionInWh! : sub.totalConsumptionInCurrency!;
    });

    if (el.name !== "conso.category.other") {
      res.push({
        name: el.name,
        total,
        subcategories: el.subcategories
      });
    }
  })

  if (compareRepartition.value.disaggregationCategories?.length) {
    const name = 'conso.category.other'
    let total = 0;
    const categories = compareRepartition.value.disaggregationCategories.map((cate) => {
      const childTotal = cate.subcategories!.reduce((_total, sub) => _total + Math.round((consoType.value ? sub.totalConsumptionInWh || 0 : sub.totalConsumptionInCurrency) || 0), 0);
      total += childTotal;
      totalSum += childTotal;

      return {
        name: cate.name,
        totalConsumptionInWh: cate.subcategories!.reduce((_total, sub) => _total + (sub.totalConsumptionInWh || 0), 0),
        totalConsumptionInCurrency: cate.subcategories!.reduce((_total, sub) => _total + (sub.totalConsumptionInCurrency || 0), 0),
      }
    })

    res.push({
      name,
      subcategories: categories,
      percentage: total,
      total: consoType.value ? total : total
    });
  }

  return res.map(el => ({
    ...el,
    percentage: Math.round((el.total / totalSum) * 100),
    total: consoType.value ? el.total : el.total
  }));
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType;
})

const isCurrentMonth = computed(() => {
  const date = useDate();
  const paramMonth = useDate(currentDate.value!).month();
  const paramYear = useDate(currentDate.value!).year();

  return paramMonth === date.month() && paramYear === date.year()
})

const viewType = computed(() => {
  return route.query.type ? route.query.type : 'month';
})

const locale = computed(() => {
  return IntlModule.locale;
})

const user = computed(() => {
  return AuthModule.user;
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const menuSliderOptions = computed(() => {
  const months: any[] = [];
  const weeks: any[] = [];
  const years: any[] = [];
  const startDate = useDate(currentSite.value.dataStart, 'YYYY-MM-DD').startOf('month');
  const nowNormalized = new Date();

  if (viewType.value === 'month') {
    const startDateNormalized = startDate.startOf("month");
    while (startDateNormalized.isBefore(nowNormalized)) {
      const label = startDateNormalized.locale(locale.value).format(labelMonthsFormat);
      months.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: startDateNormalized.locale(locale.value).format(aggregatedMonthFormat).toLowerCase()
      })

      startDateNormalized.add(1, "M");
    }

  } else if (viewType.value === 'week') {
    const startDateNormalized = startDate.startOf("week");

    while (startDateNormalized.isBefore(nowNormalized)) {
      const label = startDateNormalized.locale(locale.value).format(weekYearFormat);
      weeks.push({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: startDateNormalized.locale(locale.value).format(aggregatedWeekFormat).toLowerCase()
      })

      startDateNormalized.add(1, "w");
    }

  } else {
    const startDateNormalized = startDate.startOf("year");

    while (startDateNormalized.isBefore(nowNormalized)) {
      years.push({
        label: startDateNormalized.format('YYYY'),
        value: startDateNormalized.format('YYYY')
      });

      startDateNormalized.add(1, "y");
    }
  }

  if (viewType.value === 'week') {
    return isMobile.value ? weeks : weeks.reverse()
  } else if (viewType.value === 'year') {
    return isMobile.value ? years : years.reverse()
  }

  return isMobile.value ? months : months.reverse()
})

const repartitionData = computed(() => {
  if (!repartitionConso.value || (repartitionConso.value && !Object.keys(repartitionConso.value).length)) {
    return null;
  }

  const res: TRepartitionDetailItem[] = [];
  let totalSum = 0;

  repartitionConso.value.categories.forEach(el => {
    const subcategories = el.subcategories || [];
    const total = getSubcategoriesTotalConsoByType(subcategories, consoType.value);

    totalSum += (total > 0 && total < 1) ? 1 : total;

    if (el.name === 'conso.category.other' && repartitionConso.value?.disaggregationCategories?.length) {
      let total = 0;
      const categories = repartitionConso.value.disaggregationCategories.map((cate) => {
        const childTotal = cate.subcategories!.reduce((_total, sub) => _total + (consoType.value ? fromWhToKwh(sub.totalConsumptionInWh || 0) : sub.totalConsumptionInCurrency || 0), 0);
        total += (childTotal > 0 && childTotal < 1) ? 1 : childTotal;

        return {
          name: cate.name,
          totalConsumptionInWh: cate.subcategories!.reduce((_total, sub) => _total + (sub.totalConsumptionInWh || 0), 0),
          totalConsumptionInCurrency: cate.subcategories!.reduce((_total, sub) => _total + (sub.totalConsumptionInCurrency || 0), 0),
        }
      })

      res.push({
        name: el.name,
        subcategories: categories,
        total
      });
    } else {
      res.push({
        name: el.name,
        total: Math.round((total > 0 && total < 1) ? 1 : total),
        subcategories: (el.subcategories || []).map(subcategory => ({
          ...subcategory,
          name: subcategory.name ?? t('appliance.name.empty')
        })),
      });
    }
  })

  let perc = 0;
  res.forEach((el, index) => {
    if (el.total !== 0 && index === res.length - 1) {
      el.percentage = 100 - perc;
    } else {
      el.percentage = Math.round(percent(el.total, totalSum));
      perc += el.percentage;
    }
  });

  res.sort((a, b) => b.name === 'conso.category.other' ? -1 : 1);

  return res;
})

const backLink = computed(() => {
  return () => router.push({
    path: '/conso',
    query: {date: route.params.date, type: route.query.type}
  })
})

const currentDateLabel = computed(() => {
  if (!currentDate.value) {
    return null;
  }
  let format = labelMonthsFormat;
  if (viewType.value === 'week') {
    format = weekYearFormat;
  } else if (viewType.value === 'year') {
    format = aggregatedYearFormat;
  }
  return useDate(currentDate.value).locale(locale.value).format(format);
})


const selectCompare = (val: string) => {
  ConsoModule.getConsumptionFullData({
    dates: useDate(val).format(isYearType.value ? aggregatedYearFormat : aggregatedMonthFormat),
    period: isYearType.value ? EAggregationAlias.Year : EAggregationAlias.Month
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  });

  showCompareValues.value = false;
  compareDateFormat.value = val;
  comparingType.value = compareValues.value.find(el => el.value === val)?.type ?? null;
  compareDate.value = useDate(val).locale(locale.value).format(isYearType.value ? aggregatedYearFormat : labelMonthsFormat);
}

const editCurrentDate = (date: string | null = null) => {
  if (viewType.value === ERoutes.CONSO_WEEK) {
    currentDate.value = useDate(date ? date : undefined).locale(locale.value).format(aggregatedWeekFormat).toLowerCase()
  } else if (viewType.value === ERoutes.CONSO_YEAR) {
    currentDate.value = useDate(date ? date : undefined).locale(locale.value).format(aggregatedYearFormat).toLowerCase()
  } else if (viewType.value === ERoutes.CONSO_MONTH) {
    currentDate.value = useDate(date ? date : undefined).locale(locale.value).format(aggregatedMonthFormat).toLowerCase()
  }
}

const resetCompare = () => {
  compareDate.value = null;
  showCompareValues.value = false;
  comparingType.value = null;
}

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
}

const initData = (date: string | null = null) => {
  let format = aggregatedMonthFormat;
  if (viewType.value === ERoutes.CONSO_WEEK) {
    format = aggregatedWeekFormat;
  } else if (viewType.value === ERoutes.CONSO_YEAR) {
    format = aggregatedYearFormat;
  }

  const selectedDateFormat = date ? useDate(date).format(format).toLowerCase() : useDate(currentDate.value!).format(format).toLowerCase();

  ConsoModule.getConsumptionFullData({
    dates: selectedDateFormat,
    period: viewType.value as EAggregationAlias
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  });

  router.replace({
    path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${currentDate.value}/${route.params.category ?? ''}`,
    query: {
      type: route.query.type
    }
  });
}

const loadAdjacentData = (date: string | null = null) => {
  let format = aggregatedMonthFormat;
  if (viewType.value === ERoutes.CONSO_WEEK) {
    format = aggregatedWeekFormat;
  } else if (viewType.value === ERoutes.CONSO_YEAR) {
    format = aggregatedYearFormat;
  }

  const currentDate = useDate(date ?? route.params.date as string).format(format).toLowerCase();
  const currentDateIndex = menuSliderOptions.value.map((el) => {
    return el.value;
  }).indexOf(currentDate.toLowerCase());

  const previousDate = menuSliderOptions.value[currentDateIndex + 1];
  const nextDate = menuSliderOptions.value[currentDateIndex - 1];

  if (previousDate) {
    initData(previousDate.value);
  }
  if (nextDate) {
    initData(nextDate.value);
  }
}

onBeforeMount(() => {
  editCurrentDate(route.params.date as string)
  initData()
  loadAdjacentData()
  SiteModule.getSites()
})

onMounted(() => {
  window.scrollTo(0, 0);
})

watch(currentDate, (newValue, oldValue) => {
  if (newValue !== oldValue && oldValue !== null) {
    const queryType = route.query.type;
    let type: string;
    let start: string;
    let oldInitialDay: number;
    let newInitialDay: number;

    switch (queryType) {
      case ERoutes.CONSO_WEEK:
        type = aggregatedWeekFormat;
        start = 'day';
        break;
      case ERoutes.CONSO_YEAR:
        type = aggregatedYearFormat;
        start = 'year';
        break;
      default:
        type = aggregatedMonthFormat;
        start = 'month';
        break;
    }
    const startDate = useDate(currentSite.value.dataStart, 'YYYY-MM-dd').startOf(start);
    let date = useDate(newValue!, newValue ? type : undefined);

    if (date.startOf(start) < startDate) {
      date = startDate;
    }

    if (date.endOf(start) > useDate().startOf(start)) {
      date = useDate();
    }

    switch (queryType) {
      case 'week':
        oldInitialDay = date.day();
        newInitialDay = date.startOf('w').day();

        if (newInitialDay < oldInitialDay) {
          date.add(newInitialDay - oldInitialDay, 'd');
        }

        editCurrentDate(date.format(aggregatedWeekFormat));
        router.replace({
          path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${currentDate.value}/${route.params.category}`,
          query: {
            type: route.query.type
          }
        });
        break;

      case 'year' :
        editCurrentDate(date.format(aggregatedMonthFormat));
        router.replace({
          path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${currentDate.value}/${route.params.category}`,
          query: {
            type: route.query.type
          }
        });
        break;

      default :
        editCurrentDate(date.format(aggregatedMonthFormat))
        router.replace({
          path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${currentDate.value}/${route.params.category}`,
          query: {
            type: route.query.type
          }
        });
        break;
    }

    initData(currentDate.value);
    if (compareDate.value) {
      const isNMinus2 = isYearType.value && Math.abs(parseInt(compareDate.value) - parseInt(oldValue)) !== 1;
      const date = useDate(newValue!).substract(isNMinus2 ? 2 : 1, (comparingType.value ?? 'month'));
      selectCompare(date.format(isYearType.value ? aggregatedYearFormat : aggregatedMonthFormat));
    } else {
      loadAdjacentData(currentDate.value);
    }
  }
})

watch(repartitionData, (newValue) => {
  if (newValue) {
    const otherCategoryItem = newValue?.find(item => item.name === 'conso.category.other')

    if ((!otherCategoryItem && route.params.category === 'conso.category.other') || !isCurrentMonth.value && route.params.category === 'conso.category.other' && (otherCategoryItem && otherCategoryItem?.subcategories.length <= 1)) {
      router.replace({
        path: `${ERoutes.CONSO}/${ERoutes.CONSO_REPARTITION_DETAIL}/${currentDate.value}/${isMobile.value ? '' : 'conso.category.heater'}`,
        query: {
          type: route.query.type
        }
      });
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(MyVPageConso, {
    class: _normalizeClass([_ctx.$style.RepartitionDetail]),
    title: ""
  }, {
    default: _withCtx(() => [
      _createVNode(MyVConsoHeader, {
        class: _normalizeClass([_ctx.$style.ConsoHeader]),
        "is-detail": "",
        title: ''
      }, null, 8, ["class"]),
      _createVNode(MyVHeaderMainPage, { title: "RepartitionDetail.title" }, {
        HeaderMainPage__breadcrumb: _withCtx(() => [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (backLink.value && backLink.value(...args))),
            class: "HeaderMainPage__breadcrumb-link"
          }, [
            _cache[13] || (_cache[13] = _createElementVNode("div", { class: "HeaderMainPage__breadcrumb-link-icon" }, [
              _createElementVNode("svg", {
                class: "icon icon-arrow-left",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-arrow-left" })
              ])
            ], -1)),
            _createElementVNode("span", _hoisted_1, " < " + _toDisplayString(_ctx.$t("conso.detail.breadcrumb.backLink")), 1)
          ])
        ]),
        HeaderMainPage__right: _withCtx(() => [
          _createVNode(MyVDisplayGroupStore, {
            currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT,
            hideIfDisabled: true
          }, {
            default: _withCtx(() => [
              _createVNode(MyVSwitch, {
                modelValue: consoType.value,
                "onUpdate:modelValue": [
                  _cache[1] || (_cache[1] = ($event: any) => ((consoType).value = $event)),
                  setConsoType
                ],
                type: _unref(ESwitchType).Unit,
                class: _normalizeClass([_ctx.$style.HeaderRightSwitch]),
                unCheckedChildren: _unref(currency),
                checkedChildren: _unref(EEnergyUnit).kWh
              }, null, 8, ["modelValue", "type", "class", "unCheckedChildren", "checkedChildren"])
            ]),
            _: 1
          }, 8, ["currentRight"]),
          _createVNode(MyVDropDown, {
            class: _normalizeClass([_ctx.$style.HeaderRightDates])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(currentDateLabel.value), 1)
            ]),
            overlay: _withCtx(({toggleDropdown}) => [
              _createVNode(_component_a_menu, { class: "menu" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuSliderOptions.value, (link, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      onClick: ($event: any) => {editCurrentDate(link.value); toggleDropdown()},
                      class: _normalizeClass([[currentDate.value === link.value ? 'active' : ''], "item"])
                    }, _toDisplayString(link.label), 11, _hoisted_2))
                  }), 128))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(MyVDisplayGroupStore, {
            currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
          }, {
            default: _withCtx(() => [
              _createVNode(MyVSwitch, {
                modelValue: consoType.value,
                "onUpdate:modelValue": [
                  _cache[2] || (_cache[2] = ($event: any) => ((consoType).value = $event)),
                  setConsoType
                ],
                type: _unref(ESwitchType).Unit,
                unCheckedChildren: _unref(currency),
                checkedChildren: _unref(EEnergyUnit).kWh,
                class: _normalizeClass([_ctx.$style.HeaderRightUnits])
              }, null, 8, ["modelValue", "type", "unCheckedChildren", "checkedChildren", "class"])
            ]),
            _: 1
          }, 8, ["currentRight"])
        ]),
        _: 1
      }),
      _createVNode(MyVMenuSlider, {
        onChange: editCurrentDate,
        class: _normalizeClass([_ctx.$style.MenuSlider]),
        "item-type": "div",
        options: menuSliderOptions.value,
        "default-current": currentDate.value
      }, null, 8, ["class", "options", "default-current"]),
      (error.value)
        ? (_openBlock(), _createBlock(ErrorBanner, {
            key: 0,
            error: error.value,
            class: _normalizeClass(['error-banner', _ctx.$style['error-banner-desktop']]),
            onClose: _cache[3] || (_cache[3] = ($event: any) => (error.value = null))
          }, null, 8, ["error", "class"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.$style.MainContent, compareDate.value ? _ctx.$style.compare : ''])
      }, [
        (currentTotalConso.value && !compareDate.value)
          ? (_openBlock(), _createBlock(MyVDailyConsumption, {
              key: 0,
              category: currentTotalConso.value.name,
              value: Math.round(currentTotalConso.value.total),
              class: _normalizeClass([_ctx.$style.DetailConsumption]),
              "is-detail": "",
              unit: consoType.value ? _unref(EEnergyUnit).kWh : _unref(currency)
            }, {
              DailyConsumptionUnderText: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('repartition_detail.period_consumption')), 1)
              ]),
              _: 1
            }, 8, ["category", "value", "class", "unit"]))
          : _createCommentVNode("", true),
        (compareDate.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.MobileHeaderCompare)
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('compared_to')) + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(compareDate.value), 1)
            ], 2))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.RepartitionCardContainer])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(repartitionData.value, (repartition, index) => {
            return (_openBlock(), _createBlock(MyVRepartitionDetailCard, {
              value: repartition.total,
              percentage: repartition.percentage,
              category: repartition.name,
              key: index,
              unit: consoType.value ? _unref(EEnergyUnit).kWh : _unref(currency),
              subcategories: repartition.subcategories,
              compareData: compareRepartitionData.value ?? undefined,
              isComparing: compareDate.value !== null
            }, null, 8, ["value", "percentage", "category", "unit", "subcategories", "compareData", "isComparing"]))
          }), 128)),
          _createVNode(MyVButton, {
            "full-width": true,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (showEquipmentPopin.value=true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('repartition_detail.simulate_another_use')), 1)
            ]),
            _: 1
          }),
          (!compareDate.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.CompareDataDesktopContainer)
              }, [
                _createVNode(MyVButton, {
                  class: _normalizeClass([_ctx.$style.CompareDataDesktop]),
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (showCompareValues.value = !showCompareValues.value))
                }, {
                  default: _withCtx(() => [
                    _cache[14] || (_cache[14] = _createElementVNode("svg", {
                      class: "icon icon-recipe-create",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-recipe-create" })
                    ], -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('compare_period')), 1)
                  ]),
                  _: 1
                }, 8, ["class"]),
                (showCompareValues.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.CompareValue)
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.CompareValue__overlay),
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (showCompareValues.value = false))
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style.CompareValue__content)
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(compareValues.value, (data, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            onClick: ($event: any) => (selectCompare(data.value)),
                            class: _normalizeClass(_ctx.$style.CompareValue__content__values),
                            key: index
                          }, _toDisplayString(data.label), 11, _hoisted_3))
                        }), 128))
                      ], 2)
                    ], 2))
                  : _createCommentVNode("", true)
              ], 2))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass([_ctx.$style.isComparing])
              }, [
                _createElementVNode("div", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('compared_to')) + " ", 1),
                  _createElementVNode("strong", null, _toDisplayString(compareDate.value), 1)
                ]),
                (_openBlock(), _createElementBlock("svg", {
                  onClick: resetCompare,
                  class: _normalizeClass([[_ctx.$style.isComparing__close], "icon icon-cross"]),
                  "aria-hidden": "true"
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("use", { "xlink:href": "#icon-cross" }, null, -1)
                ]), 2))
              ], 2))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.CompareDataMobileContainer)
        }, [
          (!compareDate.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass([_ctx.$style.CompareDataMobile]),
                onClick: _cache[7] || (_cache[7] = ($event: any) => (showCompareValues.value = true))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.CompareDataMobile__span)
                }, [
                  _cache[16] || (_cache[16] = _createElementVNode("svg", {
                    class: "icon icon-recipe-create",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-recipe-create" })
                  ], -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('compare_period')), 1)
                ], 2)
              ], 2))
            : _createCommentVNode("", true),
          (showCompareValues.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.CompareValue)
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.CompareValue__overlay),
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (showCompareValues.value = false))
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.CompareValue__content)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(compareValues.value, (data, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      onClick: ($event: any) => (selectCompare(data.value)),
                      class: _normalizeClass(_ctx.$style.CompareValue__content__values),
                      key: index
                    }, _toDisplayString(data.label), 11, _hoisted_4))
                  }), 128))
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ], 2),
        (error.value)
          ? (_openBlock(), _createBlock(ErrorBanner, {
              key: 2,
              error: error.value,
              class: _normalizeClass(['error-banner', _ctx.$style['error-banner']]),
              onClose: _cache[9] || (_cache[9] = ($event: any) => (error.value = null))
            }, null, 8, ["error", "class"]))
          : _createCommentVNode("", true),
        (showEquipmentPopin.value)
          ? (_openBlock(), _createBlock(MyVEquipmentPopin, {
              key: 3,
              onClose: _cache[10] || (_cache[10] = ($event: any) => (showEquipmentPopin.value = false)),
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => (initData(currentDate.value)))
            }))
          : _createCommentVNode("", true),
        (hasDetail.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 4,
              class: _normalizeClass([_ctx.$style.SubCategories])
            }, [
              (_openBlock(), _createBlock(_component_router_view, {
                comparingDate: compareDate.value,
                compareSubcategories: compareRepartitionData.value,
                consoType: consoType.value,
                subcategories: repartitionData.value ?? [],
                key: _ctx.$route.fullPath,
                onClose: _cache[12] || (_cache[12] = ($event: any) => (error.value=null))
              }, null, 8, ["comparingDate", "compareSubcategories", "consoType", "subcategories"]))
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})