import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots } from "vue"

import {ESwitchType, TSwitch} from "./interfaces";
import {ref, watch} from "vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";

export interface Props {
  checkedChildren?: TSwitch["checkedChildren"];
  checkedChildrenIcon?: TSwitch["checkedChildrenIcon"];
  unCheckedChildrenIcon?: TSwitch["uncheckedChildrenIcon"];
  unCheckedChildren?: TSwitch["unCheckedChildren"];
  label?: TSwitch["label"];
  isPaused?: TSwitch["isPaused"];
  size?: TSwitch["size"];
  type?: ESwitchType;
  error?: TSwitch["error"];
  disabled?: TSwitch["disabled"];
  value?: TSwitch["value"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCustomSwitch',
  props: {
    checkedChildren: { default: undefined },
    checkedChildrenIcon: { default: undefined },
    unCheckedChildrenIcon: { default: undefined },
    unCheckedChildren: { default: undefined },
    label: { default: undefined },
    isPaused: { type: Boolean, default: false },
    size: {},
    type: {},
    error: { default: undefined },
    disabled: { type: Boolean, default: false },
    value: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const internalValue = ref(props.value);

watch(() => props.value, (newValue) => {
  if (newValue !== internalValue.value) {
    internalValue.value = newValue;
  }
}, {immediate: true});

const handleChange = (value: boolean) => {
  if (value !== props.value) {
    emit("update:modelValue", value);
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createBlock(_component_a_switch, {
    checked: internalValue.value,
    onChange: handleChange,
    disabled: _ctx.disabled,
    unit: internalValue.value ? props.unCheckedChildren : props.checkedChildren,
    class: _normalizeClass([`ant-switch__${_ctx.size ?? 'default'}`, `ant-switch__${_ctx.type}`, {'ant-switch__paused': _ctx.isPaused}])
  }, _createSlots({ _: 2 }, [
    (_ctx.checkedChildren || _ctx.checkedChildrenIcon)
      ? {
          name: "checkedChildren",
          fn: _withCtx(() => [
            (_ctx.checkedChildrenIcon)
              ? (_openBlock(), _createBlock(MyVIcon, {
                  key: 0,
                  name: _ctx.checkedChildrenIcon
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.checkedChildren), 1)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.unCheckedChildren || _ctx.unCheckedChildrenIcon)
      ? {
          name: "unCheckedChildren",
          fn: _withCtx(() => [
            (_ctx.unCheckedChildrenIcon)
              ? (_openBlock(), _createBlock(MyVIcon, {
                  key: 0,
                  name: _ctx.unCheckedChildrenIcon
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.unCheckedChildren), 1)
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["checked", "disabled", "unit", "class"]))
}
}

})