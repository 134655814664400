import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "TabHeatingMode__list" }
const _hoisted_2 = ["onClick", "disabled"]
const _hoisted_3 = { class: "TabHeatingMode__item-title" }

import {TTabHeatingMode} from "./interfaces";
import {computed} from "vue";
import {SiteModule} from "@/store";
import MyVIconMode from "@/components/ui/atoms/MyVIconMode/MyVIconMode.vue";

export interface Props {
  listItem: TTabHeatingMode["listItem"],
  activeTab?: TTabHeatingMode["activeTab"],
  action: TTabHeatingMode["action"],
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTabHeatingMode',
  props: {
    listItem: { default: undefined },
    activeTab: { default: '' },
    action: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const isActive = computed(() => {
  return (slug: string) => props.activeTab === slug;
})

const hasBasicOffer = computed((): boolean => {
  return SiteModule.hasBasicOffer;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", {
    class: _normalizeClass(['TabHeatingMode', _ctx.listItem.length > 5 ? 'needScroll' : ''])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItem, (item) => {
        return (_openBlock(), _createElementBlock("li", {
          key: item.slug,
          class: _normalizeClass(['TabHeatingMode__listItem', {'TabHeatingMode__listItem_disabled': hasBasicOffer.value}])
        }, [
          _createElementVNode("button", {
            onClick: ($event: any) => (_ctx.action(item.slug)),
            type: "button",
            disabled: hasBasicOffer.value,
            class: _normalizeClass(['TabHeatingMode__item', ])
          }, [
            _createVNode(MyVIconMode, {
              "icon-name": item.icon,
              active: isActive.value(item.slug),
              contrast: true
            }, null, 8, ["icon-name", "active"]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(item.title), 1)
          ], 8, _hoisted_2)
        ], 2))
      }), 128))
    ])
  ], 2))
}
}

})