import {Chart} from "chart.js";

import {TAxis} from "@/graphs/interfaces";

export type ChartDataset = Chart<"bar">["data"]["datasets"][number] & {
    /**
     * Should render the tooltip ?
     */
    renderTooltip?: TGraphic['renderTooltip'];

    /**
     * Define curvature of the curve.
     */
    tension?: number;

    /**
     * If true, lines will be drawn between points with no or null data. If false, points with null data will create a break
     * in the line. Can also be a number specifying the maximum gap length to span. The unit of the value depends on the scale used.
     */
    spanGaps?: boolean | number;

    /**
     * Color of the circle to render at the last line's item.
     */
    pointBackgroundColor?: TGraphic['color'];

    /**
     * Compute position of the circle to render at the last line's item.
     */
    pointRadius?: TGraphic['datas'];

};

export type ChartDatasets = ChartDataset[];

export enum EAxis {
    /**
     * Id of left axis Y
     */
    LEFT = 'yLeft',

    /**
     * Id of right axis Y
     */
    RIGHT = 'yRight',

    /**
     * Id of total axis Y
     */
    TOTAL = 'yTotal',
}

export type TGraphicColor = string | ((data: number, index: number, barDatas: number[][]) => string);

export type TGraphic = {
    /**
     * Unique id set to the graphic.
     */
    id?: string | number;

    /**
     * Graphic datas to render.
     */
    datas: number[];

    /**
     * Graphic label
     */
    label?: string;

    /**
     * Graphic border color and/or background color.
     */
    color?: TGraphicColor;

    /**
     * Graphic border color (Default: color).
     */
    borderColor?: TGraphicColor;

    borderRadius?: {bottomLeft?: number, bottomRight?: number, topLeft?: number, topRight?: number};

    /**
     * Graphic border width.
     */
    borderWidth?: ChartDatasets[number]['borderWidth'];

    borderSkipped?: boolean | "start" | "end" | "left" | "right" | "bottom" | "top";

    /**
     * Axis used as a basis for referencing by data.
     */
    baseAxis?: EAxis;

    /**
     * Axis stack allow to merge stacked bars in group.
     */
    stack?: string;

    /**
     * Hide dataset legend.
     */
    hideLegend?: boolean;

    /**
     * Associate content with another legend.
     * If his legend is hide, the associated legends will also be hide.
     */
    associatedLegends?: (string | number)[];

    /**
     * Render the points tooltip on hover. (Default: true)
     */
    renderTooltip?: boolean;

    renderTotal?: boolean;
}

export type TLabel = {
    /**
     * Label'text that will be rendered.
     */
    text: string;

    /**
     * Label background color.
     */
    backgroundColor?: string;

    date?: string
}

type TBarLineGraphicTotalItem = {
    /**
     * Last Bar Graphic that stock data to get the toppest stacked bar.
     */
    lastIndex: number;

    /**
     * Total of stacked datas.
     */
    datas: number[];
}

export type TBarLineGraphicTotal = {
    [key: string]: TBarLineGraphicTotalItem;
};

export type TBarLineGraphicCompareReturn = {
    /**
     * Compare's text
     */
    text: string;

    /**
     * Compare's color
     */
    color: string;
};

export type TBarLineGraphicFlag = {
    /**
     * Flag's text color.
     */
    textColor: string;

    /**
     * Flag's color.
     */
    color: string;
};

export type TBarLineGraphicZoomItem = {
    /**
     * Graphic's label.
     */
    label: string;

    /**
     * Graphic's value.
     */
    value: number;
}

export type TBarLineGraphicZoomItems = TBarLineGraphicZoomItem[];

export type TBarLineGraphicZoom = {
    /**
     * Number of item rendered.
     */
    nbItemRendered: number;

    /**
     * Callback used to fill new datas on the graphic.
     */
    onRetrieveItems: (nbItemLoaded: number) => Promise<TBarLineGraphicZoomItems>;
}

export type TBarLineGraphic = {
    /**
     * Bars informations
     */
    bars: TGraphic[];

    /**
     * Lines informations
     */
    lines: TGraphic[];

    /**
     * Labels associate to graphics datas array.
     */
    labels: TLabel[];

    /**
     * Left axis informations
     */
    leftAxis?: TAxis;

    /**
     * Right axis informations
     */
    rightAxis?: TAxis;

    /**
     * Render the total of each column.
     */
    renderTotal?: boolean;

    /**
     * Axis targeted to compute the total (default: EAxis.LEFT).
     */
    axisTargetTotal?: EAxis;

    /**
     * Compare label function.
     * The first parameter is an object where axis' name is the key, containing the whole datas.
     * The second parameter is the axis name use for real total.
     * The last parameter is the current index.
     * @return the difference to render.
     */
    compare?: (datas: TBarLineGraphicTotal, totalKey: string, index: number) => TBarLineGraphicCompareReturn;

    /**
     * Should render the graphic line.
     */
    renderLine?: boolean;

    /**
     * Use label to know if we have to render a flag.
     * If return null, the flag isn't rendered.
     * Otherwise, the flag will be rendered using the returned string as content.
     */
    renderFlag?: (label: string, index: number) => string | null;

    /**
     * Flag graphic informations
     */
    flag?: TBarLineGraphicFlag;

    /**
     * Thickness of each bar
     */
    barThickness?: number;

    /**
     * Max thickness of each bar
     */
    maxBarThickness?: number;

    /**
     * Top label
     */
    generateTopLabel?: (index: number) => string;

    /**
     * Event trigger when legend is clicked.
     */
    onLegendClick?: (graphs: TGraphic) => void;

    /**
     * Must hide legend on legend's label click. (default: true)
     */
    hideOnLegendClick?: boolean;

    /**
     * True by default, check if we must show the legend or not
     */
    displayLegend?: boolean;
};