import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {THalfCircleGraphic} from "./interfaces";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {computed} from "vue";
import useCurrency from "@/composables/currency";
import {Doughnut} from "vue-chartjs";
import {SwitchConsoModule} from "@/store";
import {useResponsive} from "@/composables/useResponsive";

interface Props {
  items: THalfCircleGraphic["items"];
  targetValue?: THalfCircleGraphic["targetValue"];
  texts: THalfCircleGraphic["texts"];
  tooltip?: THalfCircleGraphic["tooltip"];
  fontFamily?: THalfCircleGraphic["fontFamily"];
  consoType: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHalfCircleGraphic',
  props: {
    items: { default: undefined },
    targetValue: { default: 0 },
    texts: { default: undefined },
    tooltip: { default: undefined },
    fontFamily: { default: undefined },
    consoType: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

const {formatCurrency} = useCurrency()
const {isTabletMax} = useResponsive()

const centerTitle = computed(() => {
  return `${props.texts[1][0]?.value} ${props.texts[1][1]?.value}`
})

const datas = computed(() => {
  const datas: number[] = [];
  const backgroundsColor: string[] = [];
  const labels: string[] = [];

  props.items?.forEach(({label, value, color}) => {
    labels.push(label);
    datas.push(value);
    backgroundsColor.push(color);
  });

  return {
    datasets: [{
      data: datas,
      backgroundColor: backgroundsColor,
      hoverBackgroundColor: backgroundsColor,
      borderWidth: 2,
      borderColor: "white",
      borderRadius: 10,
    }],
    labels: labels
  }
})

const options = computed(() => {
      return {
        type: 'doughnut',
        responsive: true,
        cutout: isTabletMax.value ? '50%' : '60%',
        aspectRatio: isTabletMax.value ? 1 : Array.isArray(props.tooltip) ? 1.6 : props.tooltip ? 2 : 1,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              usePointStyle: true,
              pointStyle: 'rectRounded'
            }
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const valueText = props.consoType ? `${context.formattedValue} ${EEnergyUnit.kWh}` : formatCurrency(context.raw)
                return ` ${context.label}: ${valueText}`
              },
              title: function () {
                return null
              }
            },
          },
          centerText: {
            text: centerTitle.value,
            fontWeight: 'bold',
            fontSize: isTabletMax ? 20 : 30,
            fontColor: '#3E3E7C',
          },
          datalabels: {
            display: 'auto',
            borderWidth: 0,
            color: 'white',
            textAlign: 'center',
            font: {
              size: 16,
              weight: 'bold'
            },
            padding: 8,
            formatter: (value: number) => {
              if (SwitchConsoModule.switchType) {
                return [Math.round(value), EEnergyUnit.kWh];

              } else {
                return `${formatCurrency(Math.round(value))}`;
              }
            }
          },
        },
        texts: props.texts,
        tooltip: props.tooltip,
        fontFamily: props.fontFamily
      }
    }
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Doughnut), {
    data: datas.value,
    options: options.value
  }, null, 8, ["data", "options"]))
}
}

})