import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "popinForm__footer" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "popinForm__greyBlock" }
const _hoisted_6 = { class: "popinForm__footer" }

import {AuthModule, RouterModule} from "@/store";

import ERoutes from "@/router/routes";

import MyVPageWithReturn from '@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue';
import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVIcon from '@/components/ui/atoms/MyVIcon/MyVIcon.vue';
import {computed, onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import MyVForgetPasswordForm from "@/components/domains/Profile/MyVForgetPasswordForm/MyVForgetPasswordForm.vue";
import {TForgetPasswordFormDatas} from "@/components/domains/Profile/MyVForgetPasswordForm/interfaces";

const FORGET_PASSWORD = 'form_forget_password'

export default /*@__PURE__*/_defineComponent({
  __name: 'ResetEmailTokenView',
  setup(__props) {

const email = ref<string | null>(null);
const loading = ref(false);
const router = useRouter()

const emailSent = computed(() => {
  return email.value !== null;
})

const sendEmail = (emailArg?: TForgetPasswordFormDatas['email']) => {
  const finalEmail = emailArg ?? email.value;

  if (!loading.value) {
    loading.value = true;
    AuthModule.requestNewPassword({
      email: finalEmail!
    })
        .then(() => {
          email.value = finalEmail!;
        })
        .finally(() => {
          loading.value = false;
        });
  }
}

const submit = ({email}: TForgetPasswordFormDatas) => {
  sendEmail(email);
}

onBeforeMount(() => {
  const lastPath = RouterModule.lastPath;

  if (!lastPath || ![ERoutes.NEW_PASSWORD, ERoutes.FIRST_CONNECTION].includes(lastPath)) {
    router.replace(ERoutes.HOME);
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('reset_email_token.title'),
    type: "popin",
    class: "reset-email-token-view",
    returnAction: _unref(ERoutes).LOGIN
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (emailSent.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.$style.icon)
              }, [
                _createVNode(MyVIcon, { name: "success" })
              ], 2),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.$style.message_success)
              }, _toDisplayString(_ctx.$t('reset_email_token.email_sent')), 3),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(MyVButton, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (sendEmail()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('reset_email_token.send_email')), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('reset_email_token.subtitle')), 1),
              _createElementVNode("div", {
                class: _normalizeClass('inner ' + _ctx.$style.form)
              }, [
                _createVNode(MyVForgetPasswordForm, {
                  formId: FORGET_PASSWORD,
                  onSubmit: submit
                })
              ], 2),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(MyVButton, {
                  variant: "secondary",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push(_unref(ERoutes).LOGIN)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, {
                  form: FORGET_PASSWORD,
                  type: _unref(EButtonType).Submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('button.forget_password_form')), 1)
                  ]),
                  _: 1
                }, 8, ["type"])
              ])
            ]))
      ])
    ]),
    _: 2
  }, [
    (!emailSent.value)
      ? {
          name: "footer",
          fn: _withCtx(() => []),
          key: "0"
        }
      : undefined
  ]), 1032, ["title", "returnAction"]))
}
}

})