import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "password-helper" }
const _hoisted_2 = { class: "password-helper-title" }
const _hoisted_3 = { class: "password-helper-item" }
const _hoisted_4 = { class: "password-helper-item" }
const _hoisted_5 = { class: "password-helper-item" }

import {TObjectiveModal} from "@/components/ui/organisms/MyVObjectiveModal/interfaces";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";

interface Props {
  passwordHasHeightCaracters: TObjectiveModal['isEditing'];
  passwordHasUpper: TObjectiveModal['currentYear'];
  passwordHasSpecialCaracter?: TObjectiveModal['objectiveData'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPasswordHelper',
  props: {
    passwordHasHeightCaracters: { type: Boolean, default: undefined },
    passwordHasUpper: { default: undefined },
    passwordHasSpecialCaracter: { default: undefined }
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('form.passwordRules')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(MyVIcon, {
        class: _normalizeClass({valid: _ctx.passwordHasHeightCaracters}),
        name: _ctx.passwordHasHeightCaracters ? 'check-circle' : 'times-circle'
      }, null, 8, ["class", "name"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('firstConnection.password_security.characters_length')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(MyVIcon, {
        class: _normalizeClass({valid: _ctx.passwordHasUpper}),
        name: _ctx.passwordHasUpper ? 'check-circle' : 'times-circle'
      }, null, 8, ["class", "name"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('firstConnection.password_security.upper')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(MyVIcon, {
        class: _normalizeClass({valid: _ctx.passwordHasSpecialCaracter}),
        name: _ctx.passwordHasSpecialCaracter ? 'check-circle' : 'times-circle'
      }, null, 8, ["class", "name"]),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('firstConnection.password_security.special_character')), 1)
    ])
  ]))
}
}

})