import {Action, Module, Mutation, VuexModule,} from "vuex-module-decorators";

import {AppliancesModule, AuthModule} from "..";

import {
    TDailyProgs,
    TPlannings,
    TProgram,
    TProgramCacheItem,
    TProgramEditable,
    TProgramFromHabit,
    TProgramPlanningDuplicateBody,
    TPrograms,
    TProgramWithPlanning
} from "@/services/Program/interfaces";
import ProgramService from "@/services/Program";

import {EModules} from "../modules";
import {TProgrammingState} from "@/services/Heating/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import i18n from '@/i18n';
import {useDate} from "@/helpers/dates/date-utils";
import {EApplianceMode} from "@/services/Appliances/interfaces";
import MixpanelService from "@/services/Mixpanel";
import {ETrackProgramEvent, ETrackProgramEventCreatedMethods} from "@/services/Mixpanel/interfaces";

export type TUpdateProgram = { id: TProgram['id'], body: Partial<TProgramEditable> };
type TDuplicateProgram = { id?: TProgram['id'], name: TProgramEditable['name'] };
type TGetProgram = { id: TProgram['id'], forceRequest?: boolean };

type TSetPlannings = {
    programId: TProgram['id'],
    plannings: TPlannings;
};

@Module({name: EModules.PROGRAM})
class Program extends VuexModule {
    private _programs: TProgramCacheItem[] | undefined = undefined;

    private _programmingState: TProgrammingState | undefined = undefined;

    private _hasEnergyManager = false;

    get programs() {
        return this._programs;
    }

    get activeProgram() {
        return this._programs?.find(({enabled}) => enabled);
    }

    get getPausedProgram() {
        return this._programs?.find(prog => this._programmingState?.id && prog.enabled && prog.id !== this._programmingState?.id)
    }

    get getProgramById() {
        return (id: TProgram['id']) => this._programs?.find(program => program.id === id) as TProgramWithPlanning;
    }

    get programmingState() {
        return this._programmingState;
    }

    get hasActiveProgramTemperatureControl() {
        if (this._programmingState?.id) {
            const program = this.getProgramById(this._programmingState?.id)
            const modes = program?.plannings?.flatMap((appliance) => {
                return appliance.dailyprogs.flatMap((dailyProg) => {
                    return dailyProg.ranges.map((range) => range.mode);
                });
            });

            return modes?.some(mode => mode === EApplianceMode.Temp)
        }
    }

    get hasEnergyManager() {
        return this._hasEnergyManager;
    }

    get endModeTitle() {
        const {t} = i18n.global
        if (this._programmingState?.geolocCurrentlyOn) {
            return t('programList.geolocation.activatedByGeolocation')
        } else if (!this.programmingState?.untilFurtherNotice) {
            return useDate(this.programmingState?.endDate).calendar()
        } else {
            return t('heating.duration.untilFurtherNotice')
        }
    }

    /**
     * Mutations
     */

    @Mutation
    public _setPrograms(programs: TProgramCacheItem[]): void {
        if (!this._programs) {
            this._programs = programs;
        } else {
            this._programs = this._programs!.map(_program => {
                const program = programs.find(({id}) => id === _program.id);

                return program ? ({
                    ...program,
                    plannings: (program as TProgramWithPlanning).plannings ?? (_program as TProgramWithPlanning).plannings
                }) : _program;
            });
        }
    }

    @Mutation
    public _setPlannings({programId, plannings}: TSetPlannings): void {
        if (this._programs) {
            const program = this._programs.find(({id}) => id === programId);

            if ((program as TProgramWithPlanning)?.plannings) {
                plannings.map(planning => {
                    const planningIndex = (program as TProgramWithPlanning).plannings.findIndex(({idAppliance}) => idAppliance === planning.idAppliance);

                    if (planningIndex !== -1) {
                        (program as TProgramWithPlanning).plannings[planningIndex] = planning;
                    }
                });
            }
        }
    }

    @Mutation
    public _setProgram(program: TProgramCacheItem): void {
        this._programs = this._programs!.map(_program => _program.id === program.id ? ({
            ...program,
            plannings: (program as TProgramWithPlanning).plannings ?? (_program as TProgramWithPlanning).plannings
        }) : _program);
    }

    @Mutation
    public _addProgram(program: TProgramCacheItem): void {
        this._programs!.push(program);
    }

    @Mutation
    public _removeProgram(programId: TProgram['id']): void {
        this._programs = this._programs!.filter(({id}) => id !== programId);
    }

    @Mutation
    public _setProgrammingState(prog: TProgrammingState): void {
        this._programmingState = prog;
    }

    @Mutation
    public _setHasEnergyManager(hasEnergyManager: boolean): void {
        this._hasEnergyManager = hasEnergyManager;
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getPrograms(forceRequest = false): Promise<TPrograms> {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                (this.programs !== undefined && !forceRequest) ? Promise.resolve(this.programs) :
                    ProgramService.getPrograms(getCurrentSiteIdFromCookie(user))
                        .then(programs => {
                            this.context.commit("_setPrograms", programs as TPrograms);
                            return programs as TPrograms;
                        })
                        .catch(e => {
                            console.error(e);
                            throw e;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getProgram({id, forceRequest = false}: TGetProgram): Promise<TProgramWithPlanning> {
        return this.getPrograms()
            .then(programs => {
                const program = programs!.find(_program => _program.id === id);

                if (!program) {
                    throw new Error('Error 404: Program not found !');
                }

                return ((program as TProgramWithPlanning).plannings !== undefined && !forceRequest) ? Promise.resolve(program as TProgramWithPlanning) :
                    ProgramService.getProgram(getCurrentSiteIdFromCookie(AuthModule.user), program.id)
                        .then(_program => {
                            this.context.commit("_setProgram", _program as TProgramWithPlanning);
                            return _program;
                        });
            })
            ;
    }

    @Action({rawError: true})
    public updateProgram({id, body}: TUpdateProgram) {
        const user = AuthModule.userOrNull;

        if (user) {
            return this.getProgram({id})
                .then(program => Promise.all([
                        ProgramService.updateProgram(getCurrentSiteIdFromCookie(user), id, {
                            name: body.name ?? program!.name,
                            enabled: body.enabled ?? program!.enabled
                        }),
                        program
                    ])
                )
                .then(([programs, oldProgram]) => {
                    const newProgram = programs.find(_program => _program.id === oldProgram.id)!;

                    this.context.commit("_setPrograms", programs);
                    return newProgram.enabled !== oldProgram.enabled ?
                        AppliancesModule.getAppliances(true)
                            .then(() => programs) : programs;
                })
                .finally(() => this.context.dispatch('getProgrammingState'));
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public createOrDuplicateProgram({id = undefined, name}: TDuplicateProgram) {
        const user = AuthModule.userOrNull;

        if (user) {
            const promises = [
                ProgramService.createOrDuplicateProgram(getCurrentSiteIdFromCookie(user), name, id),
                this.getPrograms()
            ];

            return Promise.all(promises).then(([program, programs]) => {
                const newProgram = program as TProgramWithPlanning;

                if ((programs as TPrograms).find(({id}) => (id === newProgram.id))) {
                    this.context.commit("_setProgram", newProgram);
                } else {
                    this.context.commit("_addProgram", newProgram);
                }
                MixpanelService.trackProgramEvent(ETrackProgramEvent.CREATED, {
                    name: name,
                    enabled: newProgram.enabled,
                    id: id,
                    content: program,
                    creation_method: id ? ETrackProgramEventCreatedMethods.DUPLICATE : ETrackProgramEventCreatedMethods.FROM_SCRATCH,
                });
                return newProgram;
            });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public createProgramFromHabit(body: TProgramFromHabit) {
        const user = AuthModule.userOrNull;

        if (user) {
            const promises = [
                ProgramService.createProgramFromHabit(getCurrentSiteIdFromCookie(user), body),
                this.getPrograms()
            ];

            return Promise.all(promises).then(([program, programs]) => {
                const newProgram = program as TProgramWithPlanning;

                if ((programs as TPrograms).find(({id}) => (id === (program as TProgram).id))) {
                    this.context.commit("_setProgram", newProgram);
                } else {
                    this.context.commit("_addProgram", newProgram);
                }

                MixpanelService.trackProgramEvent(ETrackProgramEvent.CREATED, {
                    name: newProgram.name,
                    enabled: newProgram.enabled,
                    id: newProgram.id,
                    content: newProgram,
                    creation_method: ETrackProgramEventCreatedMethods.FROM_HABIT,
                });

                return newProgram;
            });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public deleteProgram(id: TProgram['id']) {
        const user = AuthModule.userOrNull;

        if (user) {
            return ProgramService.deleteProgram(getCurrentSiteIdFromCookie(user), id)
                .then(program => {
                    this.context.commit("_removeProgram", id);
                    return program;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public updateProgramPlanningDailyProgs(dailyprogs: TDailyProgs) {
        const user = AuthModule.userOrNull;

        if (user) {
            return ProgramService.updateProgramPlanningDailyProgs(getCurrentSiteIdFromCookie(user), dailyprogs)
                .then(dailyProgs => {
                    let isActiveProgram = false;
                    const programs = this.programs?.map<TProgramCacheItem>(program => ({
                        ...program,
                        plannings: (program as TProgramWithPlanning).plannings?.map(planning => ({
                            ...planning,
                            dailyprogs: planning.dailyprogs.map(_dailyprog => {
                                const dailyprog = dailyProgs!.find(({id}) => id === _dailyprog.id);

                                if (dailyprog) {
                                    if (program.enabled) {
                                        isActiveProgram = true;
                                    }
                                    return dailyprog;
                                }
                                return _dailyprog;
                            })
                        }))
                    }));

                    this.context.commit("_setPrograms", programs as TPrograms);
                    return isActiveProgram ? AppliancesModule.getAppliances(true).then(() => programs as TPrograms) : programs as TPrograms;
                })
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public duplicateProgramPlanning(body: TProgramPlanningDuplicateBody) {
        const user = AuthModule.userOrNull;

        if (user) {
            return this.getPrograms()
                .then(() => ProgramService.duplicateProgramPlanning(getCurrentSiteIdFromCookie(user), body))
                .then(plannings => {
                    this.context.commit("_setPlannings", {
                        programId: body.idProgram,
                        plannings
                    });
                    return plannings;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getProgrammingState() {
        const user = AuthModule.userOrNull;

        if (user) {
            return ProgramService.getProgrammingState(getCurrentSiteIdFromCookie(user))
                .then(prog => {
                    this.context.commit("_setProgrammingState", prog);
                    return prog;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public resetProgram() {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                ProgramService.resetProgram(getCurrentSiteIdFromCookie(user))
                    .then(res => {
                        return res;
                    })
                    .catch(e => {
                        console.error(e);
                        throw e;
                    })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getEnergyManager() {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                ProgramService.getEnergyManager(getCurrentSiteIdFromCookie(user))
                    .then(res => {
                        this.context.commit("_setHasEnergyManager", res.hasEnergyManager);
                        return res;
                    })
                    .catch(e => {
                        console.error(e);
                        throw e;
                    })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }
}

export default Program;
