<template>
  <div :class="$style.geAlert">
    <div :class="$style.icon">
      <svg class="icon icon-warning-sign" aria-hidden="true">
        <use xlink:href="#icon-warning-sign"/>
      </svg>
    </div>
    <p v-html="$t('ge_alert.text')"/>
  </div>
</template>

<style lang="scss" module>
@import "MyVGeAlert";
</style>

<script setup lang="ts">
</script>
