import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import axios from 'axios';
import {setI18nLanguage} from '@/i18n';
import {EModules} from "../modules";
import Cookie from "@/helpers/cookie";
import {IntlModule} from "@/store";

import {default as fr} from "ant-design-vue/es/locale/fr_FR";
import 'dayjs/locale/fr';
import {default as fi} from "ant-design-vue/es/locale/fi_FI";
import 'dayjs/locale/fi';
import {default as sl} from "ant-design-vue/es/locale/sl_SI";
import 'dayjs/locale/sl';
import {default as en} from "ant-design-vue/es/locale/en_GB";
import 'dayjs/locale/en-gb';
import {default as es} from "ant-design-vue/es/locale/es_ES";
import 'dayjs/locale/es';

import {ECookieNames} from "@/helpers/cookie/interfaces";

/**
 * BEGIN INTERFACES
 */

/**
 * All locale known.
 */
export enum ELocale {
    en = 'en',
    fr = 'fr',
    es = 'es',
    sl = 'sl',
    fi = 'fi',
    hu = 'hu',
    fr_PPRO = "fr_PPRO",
    fr_EKW = "fr_EKW",
    cy = 'cy'
}

export type TTranslationValue = string;

export type TTranslation = {
    /**
     * Key is the translation's key use by LocaleProvider to associate translations values.
     * The first value is singular version, the second is plural version.
     */
    [key: string]: TTranslationValue | TTranslation;
}

export type TLocaleTranslation = {
    /**
     * Global translation object, the key here is the current locale.
     */
    [key in ELocale]: TTranslation;
}

/**
 * END INTERFACES
 */

const localeArray = Object.values(ELocale);

const defaultLanguage = localeArray.includes(navigator.language?.substring(0, 2) as ELocale) ? navigator.language : ELocale.en;
const defaultLocale = (Cookie.get(ECookieNames.LOCALE) as ELocale || null) ?? defaultLanguage;

let g_url: string | undefined = undefined;

@Module({name: EModules.INTL})
class Intl extends VuexModule {

    private _locale: ELocale = defaultLocale;

    private _loadedLanguages: ELocale[] = [];

    /**
     * Getters
     */

    get locale() {
        return this._locale;
    }

    get antdLocale() {
        switch (IntlModule.locale) {
            case ELocale.fr:
                return fr;
            case ELocale.en:
                return en;
            case ELocale.es:
                return es;
            case ELocale.fi:
                return fi;
            case ELocale.sl:
                return sl;
            default:
                return en;
        }
    }

    get filteredLocale() {
        if (this._locale === ELocale.fr_PPRO || this.locale === ELocale.fr_EKW)
            return ELocale.fr

        return this._locale
    }

    get loadedLanguages() {
        return this._loadedLanguages;
    }

    get availableLanguages() {
        return Object.keys(ELocale).filter(lang => !lang.includes('_'))
    }

    /**
     * Mutations
     */

    @Mutation
    public _setLocale(locale: ELocale): void {
        this._locale = locale;
    }

    @Mutation
    public _addLocale(locale: ELocale): void {
        if (!this._loadedLanguages.includes(locale))
            this._loadedLanguages.push(locale);
    }

    @Mutation
    public _setLoadedLanguages(loadedLanguages: ELocale[]): void {
        this._loadedLanguages = loadedLanguages;
    }

    /**
     * Actions
     */
    @Action({rawError: true})
    public initRemote(url: string): Promise<void> {
        g_url = url;
        return this.setLocale(this.locale?.substring(0, 2) as ELocale);
    }

    @Action({rawError: true})
    public initLocal(translations: TLocaleTranslation): void {
        const loadedLanguages: ELocale[] = [];

        for (const lang in translations) {
            setI18nLanguage(lang, translations[lang as ELocale]);
            loadedLanguages.push(lang as ELocale);
        }
        setI18nLanguage(this.context.getters.locale);
        this.context.commit("_setLoadedLanguages", loadedLanguages);
    }

    @Action({rawError: true})
    public setLocale(locale: ELocale): Promise<void> {
        const isEkwLocale = Cookie.get(ECookieNames.EKWATEUR_LOCALE) === 'true';
        const isProLocale = Cookie.get(ECookieNames.PRO_LOCALE) === 'true';
        let _locale: ELocale = locale;

        if (isEkwLocale) {
            _locale = ELocale.fr_EKW;
        }

        if (isProLocale) {
            _locale = ELocale.fr_PPRO;
        }

        if (g_url) {
            return axios.get<TTranslation>(g_url.replace(/__LOCALE__/g, _locale))
                .then(({data}) => {
                    setI18nLanguage(locale, data);
                    this.context.commit("_addLocale", _locale);
                    this.context.commit("_setLocale", _locale);
                })
                .catch(() => {
                    setI18nLanguage(_locale, {});
                });
        }
        return Promise.resolve();
    }
}

export default Intl;