import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mobilePopin PopinListCheck"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "popinForm__footer" }

import {reactive} from "vue";

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVFieldset from '@/components/ui/atoms/MyVFieldset/MyVFieldset.vue';

import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';

import {TPopinListCheck, TPopinListCheckDatas, TPopinListCheckItem} from './interfaces';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  open: TPopinListCheck['open'];
  title: TPopinListCheck['title'];
  fieldsetTitle?: TPopinListCheck['fieldsetTitle'];
  validateLabel: TPopinListCheck['validateLabel'];
  cancelLabel: TPopinListCheck['cancelLabel'];
  onValidate?: TPopinListCheck['onValidate'];
  onCancel?: TPopinListCheck['onCancel'];
  items: TPopinListCheck['items'];
  noItemsText?: TPopinListCheck['noItemsText'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPopinListCheck',
  props: {
    open: { type: Boolean, default: undefined },
    title: { default: undefined },
    fieldsetTitle: { default: undefined },
    validateLabel: { default: undefined },
    cancelLabel: { default: undefined },
    onValidate: { type: Function, default: undefined },
    onCancel: { type: Function, default: undefined },
    items: { default: undefined },
    noItemsText: { default: undefined }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const form = reactive<TPopinListCheckDatas>({
  items: []
});

const onToggleSelectItem = (id: TPopinListCheckItem['id']) => {
  const itemIndex = form.items.findIndex(item => item === id);

  if (itemIndex !== -1) {
    form.items.splice(itemIndex, 1);
  } else {
    form.items.push(id);
  }
}

const onFinish = () => {
  if (props.onValidate)
    props.onValidate(form);
}

const close = () => {
  emit('close')
}

const cancel = () => {
  emit('close')
}

return (_ctx: any,_cache: any) => {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(MyVPageWithReturn, {
          title: _ctx.title,
          type: "popin",
          returnAction: close
        }, _createSlots({
          footer: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              class: "popinForm",
              model: form,
              onFinish: onFinish
            }, {
              default: _withCtx(() => [
                _createVNode(MyVFieldset, {
                  title: _ctx.fieldsetTitle,
                  simple: !_ctx.fieldsetTitle,
                  noPadding: !_ctx.fieldsetTitle
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id,
                        class: _normalizeClass('PopinListCheck__list')
                      }, [
                        _createElementVNode("div", {
                          class: "PopinListCheck__list-item",
                          onClick: ($event: any) => (item.enable !== false && onToggleSelectItem(item.id))
                        }, [
                          _createElementVNode("div", null, _toDisplayString(item.name), 1),
                          (!item.disabledLabel)
                            ? (_openBlock(), _createBlock(_component_a_checkbox, {
                                key: 0,
                                checked: form.items.findIndex(id => id === item.id) != -1,
                                disabled: !item.enable
                              }, null, 8, ["checked", "disabled"]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.disabledLabel), 1))
                        ], 8, _hoisted_2),
                        (!_ctx.items.length && _ctx.noItemsText)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.noItemsText), 1))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["title", "simple", "noPadding"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(MyVButton, {
                    type: _unref(EButtonType).Submit
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.validateLabel), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]),
                  _createVNode(MyVButton, {
                    onClick: cancel,
                    variant: "secondary",
                    type: "button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.cancelLabel), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 2
        }, [
          (_ctx.$slots.subHeader)
            ? {
                name: "subHeader",
                fn: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "subHeader")
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["title"])
      ]))
    : _createCommentVNode("", true)
}
}

})