import {TUser} from "@/services/Auth/interfaces";
import {Dict} from "mixpanel-browser";

export type TMixpanelService = {
    init: TInitMixpanel;
    identify: TidentifyUserEvent;
    track: TTrackSimpleEvent;
    trackProgramEvent: TTrackProgramEvent;
    trackQuicksetting: TTrackQuicksettingEvent;
    trackManualSetting: TTrackManualSettingEvent;
    trackConsumption: TTrackConsumptionEvent;
    trackObjective: TTrackObjectiveEvent;
    reset: () => void;
    optOut: ()=> void;
};

type TInitMixpanel = (token: string) => void
type TTrackSimpleEvent = (eventName: string, baseProperties?: Dict) => void
type TidentifyUserEvent = (user: TUser) => void
type TTrackProgramEvent = (eventName: ETrackProgramEvent, baseProperties?: ProgramEventProperties) => void
type TTrackQuicksettingEvent = (eventName: ETrackQuicksettingEvent, baseProperties?: QuickSettingEventProperties) => void
type TTrackManualSettingEvent = (eventName: ETrackManualSettingEvent, baseProperties: ManualSettingEventProperties) => void
type TTrackConsumptionEvent = (eventName: ETrackConsumptionEvent, baseProperties: ConsumptionAggregrationTypeEventProperties | ConsumptionAggregrationPeriodEventProperties) => void
type TTrackObjectiveEvent = (eventName: ETrackObjectiveEvent, baseProperties: ObjectiveEventProperties) => void

export interface ProgramEventProperties {
    id?: number,
    name?: string | null,
    enabled?: boolean,
    content?: any,
    creation_method?: ETrackProgramEventCreatedMethods,
}

export interface ConsumptionAggregrationTypeEventProperties {
    type: string,
}

export interface ObjectiveEventProperties {
    value: string | number,
}

export interface ConsumptionAggregrationPeriodEventProperties {
    period: string,
    number_of_days?: number,
}

export interface QuickSettingEventProperties {
    id?: number,
    name?: string | null,
    enabled?: boolean,
    until_further_notice?: boolean,
    content?: any,
    mode_end_date?: any,
}

export interface ManualSettingEventProperties {
    id?: number;
    mode?: string;
    enabled?: boolean;
    content?: any;
    appliance_id?: string | number;
    appliance_name?: string;
    appliance_type?: string;
    heating_level?: number;
    temperature_target?: number | null;
}

export enum ETrackProgramEvent {
    UPDATE = "program_update_attempt",
    CREATE = "program_create_attempt",
    CREATED = "program_created",
    ACTIVATED = "program_activated",
}

export enum ETrackQuicksettingEvent {
    STARTED = "quick_settings_started",
    START = "quick_settings_attempt"
}

export enum ETrackManualSettingEvent {
    STARTED = "manual_setting_started",
}

export enum ETrackLoginEvent {
    LOGGED_IN = "user_logged_in",
}

export enum ETrackConsumptionEvent {
    AGGREGATION_TYPE_UPDATED = "consumption_aggregation_type_updated",
    AGGREGATION_PERIOD_UPDATED = "consumption_period_updated",
}

export enum ETrackObjectiveEvent {
    ADDED = "objective_added",
}

export enum ETrackPageViewEvent {
    UPDATED = "Page View",
}

export enum ETrackProgramEventCreatedMethods {
    FROM_SCRATCH = 'From scratch',
    DUPLICATE = 'Duplicate',
    FROM_HABIT = 'From habit',
}