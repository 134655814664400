import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";

import {AppliancesModule, AuthModule, ProgramModule} from "..";

import {
    EQuickSettingsName,
    TManualSetting,
    TManualSettingEditable,
    TManualSettings,
    TQuickSettings,
    TQuickSettingsEditable
} from "@/services/Heating/interfaces";
import HeatingService from "@/services/Heating";

import {EModules} from "../modules";
import {TSite} from "@/services/Site/interfaces";
import {EApplianceMode, TAppliance} from "@/services/Appliances/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {useDate} from "@/helpers/dates/date-utils";
import MixpanelService from "@/services/Mixpanel";
import {ETrackManualSettingEvent} from "@/services/Mixpanel/interfaces";

type TUpdateManualSetting = { id: TAppliance['id'], body: Partial<TManualSettingEditable> };
type TUpdateQuickSettings = { id: TSite['id'], body: Partial<TQuickSettingsEditable> };
type TChangeEnabledQuickSettings = { id: TQuickSettings['id'], enabled: TQuickSettings['enabled'] };

@Module({name: EModules.HEATING})
class Heating extends VuexModule {
    private _manualSettings: TManualSettings | undefined = undefined;
    private _quickSettings: TQuickSettings[] | undefined = undefined;

    get manualSettings() {
        return this._manualSettings;
    }

    get quickSettings() {
        return this._quickSettings;
    }

    get getPausedQuicksetting() {
        return this._quickSettings?.find(quicksetting => (quicksetting.untilFurtherNotice || useDate(quicksetting.modeEndDate!).isAfter(new Date())) && quicksetting.enabled && quicksetting.id !== this.context.rootState.Program._programmingState?.id)
    }

    get activeQuickSettings() {
        return this.quickSettings?.find((quicksetting) => quicksetting.enabled);
    }

    get hasActiveQuicksettingTemperatureControl() {
        return (id: TQuickSettings['id']) => {
            const quicksetting = this._quickSettings?.find(item => item.id === id);
            if (quicksetting) {
                return quicksetting.appliancesSettings.some(appliance =>
                    appliance.mode === EApplianceMode.Temp)
            }
        }
    }

    /**
     * Mutations
     */

    @Mutation
    public _setManualSettings(manualSettings: TManualSettings): void {
        this._manualSettings = manualSettings;
    }

    @Mutation
    public _setQuickSettings(quickSettings: TQuickSettings[]): void {
        this._quickSettings = quickSettings.map(quickSetting => {
            if (quickSetting.name === EQuickSettingsName.LongAbsence) {
                quickSetting.isDaysDuration = true;
            }
            return quickSetting
        });
    }

    /**
     * Actions
     */

    @Action({rawError: true})
    public getManualSettings(forceRequest = false): Promise<TManualSettings | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                (forceRequest === false && this.manualSettings) ? Promise.resolve(this.manualSettings) :
                    HeatingService.getManualSettings(getCurrentSiteIdFromCookie(user))
                        .then(manualSettings => {
                            this.context.commit("_setManualSettings", manualSettings as TManualSettings);
                            return manualSettings;
                        })
                        .catch(e => {
                            console.error(e);
                            throw e;
                        })
            );
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getManualSettingByApplianceId(id: TManualSetting['idAppliance']): Promise<TManualSetting> {
        return this.getManualSettings(true)
            .then(manualSettings => {
                const manualSetting = manualSettings!.find(_manualSetting => _manualSetting.idAppliance === id);

                if (!manualSetting) {
                    throw new Error('Error 404: Manual setting not found !');
                }
                return manualSetting;
            })
            ;
    }

    @Action({rawError: true})
    public updateManualSettingByApplianceId({id, body}: TUpdateManualSetting): Promise<TAppliance | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return Promise.all([
                AppliancesModule.getAppliance({id, forceRequest: true}),
                this.getManualSettingByApplianceId(id)
            ])
                .then(([appliance, manualSetting]) => (
                    HeatingService.updateManualSetting(getCurrentSiteIdFromCookie(user), manualSetting.id, {
                        enabled: body.enabled ?? manualSetting.enabled,
                        idAppliance: body.idAppliance ?? appliance!.id,
                        untilFurtherNotice: body.untilFurtherNotice === undefined ? manualSetting.untilFurtherNotice! : body.untilFurtherNotice,
                        isOn: body.isOn === undefined ? manualSetting.isOn! : body.isOn,
                        mode: body.mode ?? manualSetting.mode,
                        endDate: body.endDate === undefined ? manualSetting.endDate : body.endDate,
                        temperatureTarget: body.temperatureTarget === undefined ? manualSetting.temperatureTarget : body.temperatureTarget,
                    })
                ))
                .then(manualSetting => {
                    MixpanelService.trackManualSetting(ETrackManualSettingEvent.STARTED, {
                        appliance_id: manualSetting!.idAppliance,
                        appliance_name: manualSetting!.applianceName,
                        appliance_type: manualSetting!.applianceType,
                        enabled: manualSetting!.enabled,
                        id: manualSetting!.id,
                        content: manualSetting,
                        heating_level: manualSetting!.heatingLevel,
                        mode: manualSetting!.mode,
                        temperature_target: manualSetting!.temperatureTarget,
                    })
                    this.context.commit(
                        "_setManualSettings",
                        this.manualSettings!.map(_manualSetting => _manualSetting.id === manualSetting!.id ? manualSetting : ({
                            ..._manualSetting,
                            enabled: manualSetting!.enabled ? false : _manualSetting.enabled
                        }))
                    );

                    const appliance = AppliancesModule.getAppliance({
                        id: manualSetting!.idAppliance,
                        forceRequest: true
                    });
                    return appliance;

                })
                .catch(e => {
                    console.error(e);
                    throw e;
                });
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }


    @Action({rawError: true})

    public getQuickSettings(forceRequest = false): Promise<TQuickSettings[] | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return (
                (!forceRequest && this.quickSettings) ? Promise.resolve(this.quickSettings) :
                    HeatingService.getQuickSettings(getCurrentSiteIdFromCookie(user))
                        .then(quickSettings => {
                            this.context.commit("_setQuickSettings", (quickSettings as TQuickSettings[]))
                        })
                        .catch(e => {
                            console.error(e);
                            throw e;
                        })
            )
        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public getQuickSetting(id: TQuickSettings['id']): Promise<TQuickSettings | void> {
        return this.getQuickSettings()
            .then(quickSettings => {
                const quickSetting = quickSettings!.find(_quickSetting => _quickSetting.id === id);

                if (quickSetting === undefined) {
                    throw new Error('Error 404: Quick settings not found !');
                }
                return quickSetting;
            })

    }

    @Action({rawError: true})
    public updateQuickSetting({id, body}: TUpdateQuickSettings): Promise<TQuickSettings | void> {
        const user = AuthModule.userOrNull;

        if (user) {
            return this.getQuickSetting(id)
                .then(quickSetting => {
                        return HeatingService.updateQuickSettings(getCurrentSiteIdFromCookie(user), id, {
                            untilFurtherNotice: body.untilFurtherNotice ?? quickSetting!.untilFurtherNotice,
                            modeEndDate: body.modeEndDate ?? quickSetting!.modeEndDate,
                            appliancesSettings: (
                                body.appliancesSettings !== undefined && body.appliancesSettings !== null ? (
                                        quickSetting!.appliancesSettings !== undefined && quickSetting!.appliancesSettings !== null ?
                                            body.appliancesSettings.map(appliance => {
                                                const currentAppliance = quickSetting!.appliancesSettings.find(({idAppliance}) => idAppliance === appliance.idAppliance);

                                                return ({
                                                    ...currentAppliance,
                                                    ...appliance
                                                });
                                            })
                                            : body.appliancesSettings
                                    )
                                    : quickSetting!.appliancesSettings
                            ),
                        })
                    }
                )
                .then((quickSetting) => {
                    this.context.commit("_setQuickSettings", this.quickSettings!.map(_quickSetting => _quickSetting.id === quickSetting!.id ? quickSetting! : _quickSetting));
                    return quickSetting!;
                })
                .catch(e => {
                    console.error(e);
                    throw e;
                })

        }
        return Promise.reject(new Error('Error 403: You must be connected !'));
    }

    @Action({rawError: true})
    public changeEnableStateQuickSettings({id, enabled}: TChangeEnabledQuickSettings) {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        if (user) {
            return HeatingService.enableQuickSettings(getCurrentSiteIdFromCookie(user), id, enabled)
                .then(enableQuickSettingsResponses => {
                    this.context.commit("_setQuickSettings", this.quickSettings!.map(_quickSetting => ({
                        ..._quickSetting,
                        enabled: (enableQuickSettingsResponses!.find(({id: _id}) => _id === _quickSetting.id) || _quickSetting).enabled
                    })));
                })
                .then(() => {
                    AppliancesModule.getAppliances(true)
                    ProgramModule.getProgrammingState()
                });
        }

    }

    @Action({rawError: true})
    public startQuickSettings(id: TQuickSettings['id']) {
        this.changeEnableStateQuickSettings({id, enabled: true});
    }

    @Action({rawError: true})
    public stopQuickSettings(id? :TQuickSettings['id'] ) {
        return this.changeEnableStateQuickSettings({
            id: id ? id :this.activeQuickSettings!.id,
            enabled: false
        });
    }

    @Action({rawError: true})
    public initQuickSettings() {
        const user = AuthModule.userOrNull;

        if (!user) {
            return Promise.reject(new Error('Error 403: You must be connected !'));
        }

        return HeatingService.initQuickSettings(getCurrentSiteIdFromCookie(user))
            .then(quickSettings => {
                this.context.commit("_setQuickSettings", quickSettings as TQuickSettings[]);
                return quickSettings;
            })
            .catch(e => {
                console.error(e);
                throw e;
            })
    }
}

export default Heating;