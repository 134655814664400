import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["data-testid", "for"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["xlink:href"]

import {EInputType, TInput} from "./interfaces";

import {onBeforeMount, ref, watch} from "vue";

export interface Props {
  autoComplete?: TInput["autoComplete"];
  inputRequired?: TInput["inputRequired"];
  inputType?: TInput["inputType"];
  placeholder?: TInput["placeholder"];
  id?: TInput["id"];
  hasAnyStep?: boolean;
  icon?: TInput["icon"];
  label?: TInput["label"];
  value?: TInput["value"];
  defaultValue?: TInput["defaultValue"];
  error?: TInput["error"];
  success?: TInput["success"];
  maxlength?: TInput["maxlength"];
  min?: TInput["min"];
  max?: TInput["max"];
  disabled?: TInput['disabled'];
  allowClear?: TInput['allowClear'];
  hasUnit?: TInput['hasValue'];
  minRows?: TInput['minRows'];
  maxRows?: TInput['maxRows'];
  dataTestId?: TInput['dataTestId'];
  addonAfter?: TInput['addonAfter']
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVInput',
  props: {
    autoComplete: { default: undefined },
    inputRequired: { type: Boolean, default: false },
    inputType: { default: EInputType.Text },
    placeholder: { default: undefined },
    id: { default: undefined },
    hasAnyStep: { type: Boolean, default: false },
    icon: { default: undefined },
    label: { default: undefined },
    value: { default: undefined },
    defaultValue: { default: '' },
    error: { default: undefined },
    success: { default: undefined },
    maxlength: { default: undefined },
    min: { default: undefined },
    max: { default: undefined },
    disabled: { type: Boolean, default: false },
    allowClear: { type: Boolean, default: false },
    hasUnit: { type: Boolean, default: false },
    minRows: { default: 2 },
    maxRows: { default: 6 },
    dataTestId: { default: undefined },
    addonAfter: {}
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const type = ref(EInputType.Text)
const m_value = ref()
const hasAutofill = ref(false)
const input = ref()
const isFocused = ref(false)

const checkAnimation = (e: any) => {
  if (e.animationName == "onAutoFillStart") {
    hasAutofill.value = true;
  } else if (e.animationName == "onAutoFillCancel") {
    hasAutofill.value = false;
  }
}

const onChange = (e: any) => {
  if (props.inputType === EInputType.NumberInput && e.data === 'e') return false
  m_value.value = e.target.value;
  emit('update:value', m_value.value);
}

const togglePassword = () => {
  type.value = (type.value === EInputType.Password) ? EInputType.Text : EInputType.Password;
}

const handleFocus = () => {
  isFocused.value = true;
};

const handleBlur = () => {
  isFocused.value = false;
};

onBeforeMount(() => {
  type.value = props.inputType!;
  m_value.value = props.value || props.defaultValue;
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    m_value.value = newValue;
  }
})

return (_ctx: any,_cache: any) => {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, {
    class: "myv-input",
    "validate-status": _ctx.error ? 'error' : (_ctx.success ? 'success' : undefined)
  }, {
    default: _withCtx(() => [
      (_ctx.label!==null)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            "data-testid": `${_ctx.dataTestId}-label`,
            for: _ctx.id,
            class: "myv-input-label"
          }, [
            _createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(_ctx.label), 1),
              (_ctx.inputRequired)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, " *"))
                : _createCommentVNode("", true)
            ])
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.inputType=='textarea')
        ? (_openBlock(), _createBlock(_component_a_textarea, {
            key: 1,
            "auto-size": { minRows: _ctx.minRows, maxRows: _ctx.maxRows },
            placeholder: _ctx.placeholder,
            type: _ctx.inputType,
            required: _ctx.inputRequired,
            id: _ctx.id,
            name: _ctx.id,
            class: "has-value",
            onChange: onChange,
            value: m_value.value,
            defaultValue: _ctx.defaultValue,
            maxlength: _ctx.maxlength,
            disabled: _ctx.disabled
          }, null, 8, ["auto-size", "placeholder", "type", "required", "id", "name", "value", "defaultValue", "maxlength", "disabled"]))
        : (_ctx.inputType=='password')
          ? (_openBlock(), _createBlock(_component_a_input_password, {
              key: 2,
              value: m_value.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((m_value).value = $event)),
              id: _ctx.id,
              onChange: onChange,
              onFocus: handleFocus,
              required: _ctx.inputRequired,
              onBlur: handleBlur
            }, null, 8, ["value", "id", "required"]))
          : (_openBlock(), _createBlock(_component_a_input, {
              "data-testid": `${_ctx.dataTestId}-input`,
              allowClear: _ctx.allowClear,
              onAnimationstart: checkAnimation,
              step: _ctx.hasAnyStep ? 'any' : null,
              autocomplete: _ctx.autoComplete,
              ref_key: "input",
              ref: input,
              key: _ctx.id,
              disabled: _ctx.disabled,
              placeholder: _ctx.placeholder,
              type: type.value,
              required: _ctx.inputRequired,
              id: _ctx.id,
              name: _ctx.id,
              class: _normalizeClass(['has-value', {'has-unit': _ctx.hasUnit}]),
              onChange: onChange,
              value: m_value.value,
              defaultValue: _ctx.defaultValue,
              maxlength: _ctx.maxlength,
              min: _ctx.min,
              max: _ctx.max,
              autocapitalize: "off",
              addonAfter: _ctx.addonAfter,
              onFocus: handleFocus,
              onBlur: handleBlur
            }, _createSlots({ _: 2 }, [
              (_ctx.icon || _ctx.inputType=='password')
                ? {
                    name: "suffix",
                    fn: _withCtx(() => [
                      (_ctx.inputType==='password')
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: _normalizeClass(['form__togglePassword',{'active':type.value!=='password'}]),
                            onClick: togglePassword
                          }, _cache[1] || (_cache[1] = [
                            _createElementVNode("svg", {
                              class: "icon ion-eye",
                              "aria-hidden": "true"
                            }, [
                              _createElementVNode("use", { "xlink:href": "#icon-eye" })
                            ], -1)
                          ]), 2))
                        : (_openBlock(), _createElementBlock("svg", {
                            key: 1,
                            class: _normalizeClass('icon icon-'+_ctx.icon),
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", {
                              "xlink:href": '#icon-'+_ctx.icon
                            }, null, 8, _hoisted_3)
                          ], 2))
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["data-testid", "allowClear", "step", "autocomplete", "disabled", "placeholder", "type", "required", "id", "name", "class", "value", "defaultValue", "maxlength", "min", "max", "addonAfter"])),
      _renderSlot(_ctx.$slots, "help"),
      _renderSlot(_ctx.$slots, "unit")
    ]),
    _: 3
  }, 8, ["validate-status"]))
}
}

})