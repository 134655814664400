import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "ProfileMenu__container" }
const _hoisted_2 = { class: "ProfileMenu__header" }
const _hoisted_3 = { class: "ProfileMenu__headerTop" }
const _hoisted_4 = { class: "ProfileMenu__title" }
const _hoisted_5 = { class: "ProfileMenu__address" }
const _hoisted_6 = {
  key: 0,
  class: "editSite"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "ProfileMenu__main" }
const _hoisted_11 = { class: "ProfileMenu__section" }
const _hoisted_12 = { class: "ProfileMenu__section-title" }
const _hoisted_13 = { class: "ProfileMenu__section-content" }
const _hoisted_14 = { class: "ProfileMenu__notifContainer" }
const _hoisted_15 = {
  key: 0,
  class: "ProfileMenu__notif"
}
const _hoisted_16 = { class: "ProfileMenu__section" }
const _hoisted_17 = { class: "ProfileMenu__section-title" }
const _hoisted_18 = { class: "ProfileMenu__section-content" }
const _hoisted_19 = { class: "ProfileMenu__section" }
const _hoisted_20 = { class: "ProfileMenu__section-title" }
const _hoisted_21 = { class: "ProfileMenu__help" }
const _hoisted_22 = ["type"]

import ERoutes from "@/router/routes";

import {AuthModule, clearStoreCache, ContractModule, DiagnosticModule, SiteModule} from "@/store";

import {TUser} from "@/services/Auth/interfaces";
import {TSite} from "@/services/Site/interfaces";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";

import MyVLangSelect from "@/components/ui/molecules/MyVLangSelect/MyVLangSelect.vue";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";

import {TProfileMenu} from './interfaces';
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import Cookie from "@/helpers/cookie";
import API from "@/helpers/api";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";

import {isWebViewMobileApp} from "@/helpers/domains/device";
import {computed, onBeforeMount, ref} from "vue";
import {useRouter} from "vue-router";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  isOverlay?: TProfileMenu["isOverlay"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVProfileMenu',
  props: {
    isOverlay: { type: Boolean, default: undefined }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const dropdownSiteVisible = ref(false);
const sensorsLink = ref<string | null>(null);
const router = useRouter()

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)

const user = computed((): TUser => {
  return AuthModule.user;
})

const links = computed(() => {
  return {
    contact: ERoutes.CONTACT,
    myAccount: ERoutes.MY_ACCOUNT
  };
})

const shouldDisplaySavings = computed(() => {
  return SiteModule.isSiteCountryFrance && !AuthModule.isPetitPro
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(user.value);
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const hasParticularLocale = computed(() => {
  return Cookie.hasParticularLocale();
})

const isMultiSites = computed(() => {
  return user.value.otherSites.length > 0;
})

const hasSensorsLink = computed(() => {
  return sensorsLink.value && sensorsLink.value !== '';
})

const isPdlValid = computed(() => {
  return ContractModule.isPdlValid;
})

const sites = computed((): TSite[] => {
  return SiteModule.sites;
})

const hasBasicOffer = computed((): boolean => {
  return SiteModule.hasBasicOffer;
})

const updateSite = (siteId: TSite['id']) => {
  AuthModule.manageCurrentSiteId({
    user: user.value,
    siteId
  });
  API.put(`/api/site/${siteId}/init`).then(() => {
    clearStoreCache(true);
  });
  pageScroll(true);
}

const closeMenu = () => {
  emit('close');
  pageScroll(true);
}

const logout = () => {
  AuthModule.logout();
  router.push(ERoutes.HOME);
}

const handleDropdownSite = (visible: boolean) => {
  dropdownSiteVisible.value = visible;
}
const goToAccount = () => {
  router.push(ERoutes.MY_ACCOUNT);
}

const goToSensorsLink = () => {
  if (sensorsLink.value) {
    if (isWebViewMobileApp(window)) {
      window.flutter_inappwebview.callHandler('voltalis', {'type': 'open', 'url': sensorsLink.value});
    } else {
      window.open(sensorsLink.value);
    }
  }
}

onBeforeMount(() => {
  SiteModule.getSites();
  API.get<TProfileMenu['sensorsLink']>(`/api/site/${currentSiteId.value}/temperature-sensor`)
      .then(({data}) => {
        if (data.hasTemperatureSensor && data.redirectionLink !== '') {
          sensorsLink.value = data.redirectionLink;
        }
      });
})

return (_ctx: any,_cache: any) => {
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ProfileMenu', {'isOverlay': _ctx.isOverlay}])
  }, [
    _createElementVNode("div", {
      class: "ProfileMenu__overlay",
      onClick: closeMenu
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "ProfileMenu__close",
            onClick: closeMenu
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("svg", {
              class: "icon icon-cross",
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", { "xlink:href": "#icon-cross" })
            ], -1)
          ])),
          (!hasParticularLocale.value)
            ? (_openBlock(), _createBlock(MyVLangSelect, {
                key: 0,
                "text-color": "white",
                buttonClassName: "ProfileMenu__headerTop--langSelect"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(user.value.firstname) + " " + _toDisplayString(user.value.lastname), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, [
            (isMultiSites.value && currentSite.value.name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(currentSite.value.name), 1),
                  _createVNode(MyVIcon, {
                    name: "pen",
                    onClick: goToAccount
                  })
                ]))
              : (currentSite.value.address && currentSite.value.address !== '')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(currentSite.value.address) + ", ", 1),
                      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                    ]),
                    _createTextVNode(" " + _toDisplayString(currentSite.value.postalCode) + " " + _toDisplayString(currentSite.value.city), 1)
                  ], 64))
                : _createCommentVNode("", true)
          ]),
          (sites.value && sites.value.length > 1)
            ? (_openBlock(), _createBlock(_component_a_dropdown, {
                key: 0,
                class: "__view",
                open: dropdownSiteVisible.value,
                onOpenChange: handleDropdownSite,
                trigger: ['click']
              }, {
                overlay: _withCtx(() => [
                  _createVNode(_component_a_menu, { class: "menu" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sites.value, (site) => {
                        return (_openBlock(), _createElementBlock("button", {
                          key: site.id,
                          onClick: ($event: any) => (updateSite(site.id)),
                          class: _normalizeClass(['item', {'active':(currentSiteId.value === site.id )} ])
                        }, [
                          (site.name)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(site.name), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_9, [
                                _createTextVNode(_toDisplayString(site.address) + ", ", 1),
                                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                                _createTextVNode(" " + _toDisplayString(site.postalCode), 1)
                              ]))
                        ], 10, _hoisted_7))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("button", {
                    type: "button",
                    class: "ProfileMenu__changeSites"
                  }, [
                    _createElementVNode("svg", {
                      class: "icon icon-two-house",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-two-house" })
                    ])
                  ], -1))
                ]),
                _: 1
              }, 8, ["open"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h2", _hoisted_12, _toDisplayString(_ctx.$t('profile.my_space')), 1),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_router_link, {
              to: links.value.myAccount,
              class: "ProfileMenu__link"
            }, {
              default: _withCtx(() => [
                _cache[4] || (_cache[4] = _createElementVNode("svg", {
                  class: "icon icon-account",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-account" })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.my_account')), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_router_link, {
              to: hasBasicOffer.value ? '' : _unref(ERoutes).HOUSING,
              class: "ProfileMenu__link"
            }, {
              default: _withCtx(() => [
                _cache[5] || (_cache[5] = _createElementVNode("svg", {
                  class: "icon icon-Housing",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-Housing" })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.my_housing')) + " ", 1),
                (hasBasicOffer.value)
                  ? (_openBlock(), _createBlock(MyVIcon, {
                      key: 0,
                      name: "locked"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(MyVDisplayGroupStore, {
              currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT,
              hideIfDisabled: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: hasBasicOffer.value ? '' : _unref(ERoutes).CONTRACT,
                  class: "ProfileMenu__link"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[6] || (_cache[6] = _createElementVNode("svg", {
                        class: "icon icon-contract",
                        "aria-hidden": "true"
                      }, [
                        _createElementVNode("use", { "xlink:href": "#icon-contract" })
                      ], -1)),
                      (!isPdlValid.value && !_unref(AuthModule).user.defaultSite.hasDsoMeasure && isSiteCountryFrance.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15))
                        : _createCommentVNode("", true)
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.my_contract')) + " ", 1),
                    (hasBasicOffer.value)
                      ? (_openBlock(), _createBlock(MyVIcon, {
                          key: 0,
                          name: "locked"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }, 8, ["currentRight"]),
            (hasSensorsLink.value)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "ProfileMenu__link",
                  onClick: goToSensorsLink,
                  target: "_blank"
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("svg", {
                    class: "icon icon-sensors",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-sensors" })
                  ], -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.sensors')), 1)
                ]))
              : _createCommentVNode("", true),
            (shouldDisplaySavings.value)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: _unref(ERoutes).ADVICES,
                  class: "ProfileMenu__link"
                }, {
                  default: _withCtx(() => [
                    _cache[8] || (_cache[8] = _createElementVNode("svg", {
                      class: "icon icon-menu-advices",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-menu-advices" })
                    ], -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.advices')), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("h2", _hoisted_17, _toDisplayString(_ctx.$t('profile.control_center')), 1),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_router_link, {
              to: _unref(ERoutes).SECURITY,
              class: "ProfileMenu__link"
            }, {
              default: _withCtx(() => [
                _cache[9] || (_cache[9] = _createElementVNode("svg", {
                  class: "icon icon-security",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-security" })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.security')), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_router_link, {
              to: _unref(ERoutes).MANAGE_CHOICES,
              class: "ProfileMenu__link"
            }, {
              default: _withCtx(() => [
                _cache[10] || (_cache[10] = _createElementVNode("svg", {
                  class: "icon icon-choice",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-choice" })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.manage_my_choices')), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            (_unref(DiagnosticModule).diagnostics?.length)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: _unref(ERoutes).DIAGNOSTIC,
                  class: "ProfileMenu__link"
                }, {
                  default: _withCtx(() => [
                    _cache[11] || (_cache[11] = _createElementVNode("svg", {
                      class: "white-icon icon icon-diagnostic",
                      "aria-hidden": "true"
                    }, [
                      _createElementVNode("use", { "xlink:href": "#icon-diagnostic" })
                    ], -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('profile.devices_status')), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("h2", _hoisted_20, _toDisplayString(_ctx.$t('profile.help_and_contact')), 1),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(MyVDisplayGroupStore, {
              currentRight: _unref(EDisplayGroupRightName).HELP,
              hideIfDisabled: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: links.value.contact,
                  class: "ProfileMenu__help-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.contact_us')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }, 8, ["currentRight"]),
            _createVNode(MyVDisplayGroupStore, {
              currentRight: _unref(EDisplayGroupRightName).CONTACT,
              hideIfDisabled: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_router_link, {
                  to: _unref(ERoutes).FAQ,
                  class: "ProfileMenu__help-link"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.FAQ')), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }, 8, ["currentRight"]),
            _createVNode(_component_router_link, {
              to: _unref(ERoutes).LEGAL_INFORMATION,
              class: "ProfileMenu__help-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('profile.LEGAL_INFORMATION')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ]),
      (_ctx.isOverlay)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: _unref(EButtonType).Button,
            class: "ProfileMenu__logout",
            onClick: logout
          }, _toDisplayString(_ctx.$t('global.logout')), 9, _hoisted_22))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})