import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "programCreate__success" }
const _hoisted_2 = { class: "programCreate__success-main programCreate__success-inner" }
const _hoisted_3 = {
  key: 0,
  class: "programCreate__success-title"
}
const _hoisted_4 = {
  key: 1,
  class: "programCreate__success-title"
}
const _hoisted_5 = { class: "programCreate__success-footer" }

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import ProgReminder from "@/components/domains/Heating/programs/MyVProgReminder/MyVProgReminder.vue";
import {useRouter} from "vue-router";


interface Props {
  linkToProgram?: string;
  isVx?: boolean;
  isStartingFromZero?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramCreateSuccessView',
  props: {
    linkToProgram: { default: undefined },
    isVx: { type: Boolean, default: false },
    isStartingFromZero: { type: Boolean, default: false }
  },
  setup(__props: any) {



const router = useRouter()


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isStartingFromZero)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('programCreate.startingFromZero.success.title')), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('programCreate.success.title')), 1)),
      (_ctx.isVx)
        ? (_openBlock(), _createBlock(ProgReminder, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isStartingFromZero)
        ? (_openBlock(), _createBlock(MyVButton, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('discover')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('programCreate.startingFromZero.success.discover.new_program')), 1)
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(MyVButton, {
            key: 1,
            variant: "primary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push(_ctx.linkToProgram)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('programCreate.success.discover.new_program')), 1)
            ]),
            _: 1
          }))
    ])
  ]))
}
}

})