import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots } from "vue"

import {ESwitchType, TSwitch} from "./interfaces";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {computed} from "vue";

export interface Props {
  checkedChildren?: TSwitch["checkedChildren"];
  checkedChildrenIcon?: TSwitch["checkedChildrenIcon"];
  unCheckedChildrenIcon?: TSwitch["uncheckedChildrenIcon"];
  unCheckedChildren?: TSwitch["unCheckedChildren"];
  label?: TSwitch["label"];
  isPaused?: TSwitch["isPaused"];
  size?: TSwitch["size"];
  type?: ESwitchType;
  error?: TSwitch["error"];
  disabled?: TSwitch["disabled"];
  value?: TSwitch["value"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVSwitch',
  props: /*@__PURE__*/_mergeModels({
    checkedChildren: { default: undefined },
    checkedChildrenIcon: { default: undefined },
    unCheckedChildrenIcon: { default: undefined },
    unCheckedChildren: { default: undefined },
    label: { default: undefined },
    isPaused: { type: Boolean, default: false },
    size: {},
    type: {},
    error: { default: undefined },
    disabled: { type: Boolean, default: false },
    value: { type: Boolean }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const props = __props

const model = _useModel(__props, "modelValue")
const unit = computed(() => {
  return model.value ?  props.checkedChildren : props.unCheckedChildren ;
})


return (_ctx: any,_cache: any) => {
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createBlock(_component_a_switch, {
    checked: model.value,
    "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    disabled: _ctx.disabled,
    style: _normalizeStyle({ '--unit': `'${unit.value}'` }),
    class: _normalizeClass([`ant-switch__${_ctx.size ?? 'default'}`, `ant-switch__${_ctx.type}`, {'ant-switch__paused': _ctx.isPaused}])
  }, _createSlots({ _: 2 }, [
    (_ctx.checkedChildren || _ctx.checkedChildrenIcon)
      ? {
          name: "checkedChildren",
          fn: _withCtx(() => [
            (_ctx.checkedChildrenIcon)
              ? (_openBlock(), _createBlock(MyVIcon, {
                  key: 0,
                  name: _ctx.checkedChildrenIcon
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.unCheckedChildren), 1)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.unCheckedChildren || _ctx.unCheckedChildrenIcon)
      ? {
          name: "unCheckedChildren",
          fn: _withCtx(() => [
            (_ctx.unCheckedChildrenIcon)
              ? (_openBlock(), _createBlock(MyVIcon, {
                  key: 0,
                  name: _ctx.unCheckedChildrenIcon
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.checkedChildren), 1)
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["checked", "disabled", "style", "class"]))
}
}

})