import {TTabHeatingModeItem} from "@/components/domains/Heating/MyVTabHeatingMode/interfaces";
import {EApplianceMode} from "@/services/Appliances/interfaces";

export const TabHeatingModeFakeDatas:  TTabHeatingModeItem[]  = [
  {
    icon: 'thermometer-warm',
    title: 'mode.confort',
    slug: EApplianceMode.Comfort
  },
  {
    icon: 'thermometer-warm',
    title: 'mode.confort1',
    slug: EApplianceMode.Comfort1
  },
  {
    icon: 'thermometer-warm',
    title: 'mode.confort2',
    slug: EApplianceMode.Comfort2
  },
  {
    icon: 'thermometer-warm',
    title: 'mode.normal',
    slug: EApplianceMode.Normal,
  },
  {
    icon: 'leaf-2',
    title: 'mode.eco',
    slug: EApplianceMode.Eco,
  },
  {
    icon: 'leaf-2',
    title: 'mode.ecov',
    slug: EApplianceMode.EcoV,
  },
  {
    icon: 'snowflake-01',
    title: 'mode.frostProtection',
    slug: EApplianceMode.FrostProtection,
  },
  {
    icon: 'thermometer-03',
    title: 'mode.temperature',
    slug: EApplianceMode.Temp,
  },
];