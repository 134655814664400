import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["xlink:href"]
const _hoisted_2 = ["type", "disabled"]
const _hoisted_3 = ["xlink:href"]

import {EButtonSize, EButtonType, TButton} from "./interfaces";
import {computed} from "vue";

export interface Props {
  primary?: TButton["primary"],
  inline?: TButton["inline"],
  disabled?: TButton["disabled"],
  icon?: TButton["icon"],
  size?: TButton["size"],
  type?: TButton["type"],
  variant?: TButton["variant"],
  textColor?: TButton["textColor"],
  textCase?: TButton["textCase"],
  to?: TButton["to"],
  round?: TButton["round"],
  onClick?: TButton["onClick"]
  fullWidth?: TButton["fullWidth"]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVButton',
  props: {
    primary: { type: Boolean, default: true },
    inline: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    icon: { default: undefined },
    size: { default: EButtonSize.Large },
    type: { default: EButtonType.Button },
    variant: { default: 'primary' },
    textColor: {},
    textCase: { default: undefined },
    to: { default: "" },
    round: { type: Boolean, default: false },
    onClick: { type: Function, default: undefined },
    fullWidth: { type: Boolean, default: undefined }
  },
  setup(__props: any) {

const props = __props

const generateClassName = computed(() => {
  let className = `button button--${props.size}`;

  if (props.disabled) {
    className += " button--disabled";
  }
  if (!props.primary) {
    className += " button--secondary";
  }
  if (props.icon) {
    className += " button--icon";
  }
  if (props.round) {
    className += " button--round";
  }
  if (props.inline) {
    className += " button--inline";
  }

  return className + (props.class !== null ? ` ${props.class}` : '');
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.type=='link')
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        class: _normalizeClass(generateClassName.value),
        to: _ctx.to,
        onClick: _ctx.onClick
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _cache[1] || (_cache[1] = _createTextVNode("Button"))
          ]),
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("svg", {
                key: 0,
                class: _normalizeClass(['icon', 'icon-'+_ctx.icon, 'icon-'+_ctx.icon]),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": ['#icon-'+_ctx.icon]
                }, null, 8, _hoisted_1)
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["class", "to", "onClick"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(['myv-button', props.variant ? `myv-button__${props.variant}` : '', props.fullWidth ? 'myv-button__full-width' : '', props.textColor ? `text-${props.textColor}` : '', props.textCase ? `text-${props.textCase}` : '', {'myv-button__icon' : _ctx.icon}]),
        type: _ctx.type,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        disabled: _ctx.disabled
      }, [
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 0,
              class: _normalizeClass(['icon', 'icon-'+_ctx.icon, 'icon-'+_ctx.icon]),
              "aria-hidden": "true"
            }, [
              _createElementVNode("use", {
                "xlink:href": ['#icon-'+_ctx.icon]
              }, null, 8, _hoisted_3)
            ], 2))
          : _createCommentVNode("", true)
      ], 10, _hoisted_2))
}
}

})