import {computed} from "vue";
import {ECookieNames} from "@/helpers/cookie/interfaces";
import {AuthModule} from "@/store";

export function useStorageKeys() {

    const temperatureControlReminder = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_TEMPERATURE_CONTROL_REMINDER}${AuthModule.user?.id}`
        }
    )

    const defaultContrat = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_DEFAULT_CONTRACT}${AuthModule.user?.id}`
        }
    )

    const progReminder = computed((): string => {
            return `${ECookieNames.DONT_ASK_AGAIN_PROG_REMINDER}${AuthModule.user?.id}`
        }
    )

    const enedisCounter = computed((): string => {
            return `${ECookieNames.ENEDIS_COUNTER}${AuthModule.user?.id}`
        }
    )

    const enedisDate = computed((): string => {
            return `${ECookieNames.ENEDIS_DATE}${AuthModule.user?.id}`
        }
    )

    const contractNotification = computed((): string => {
            return `${ECookieNames.CONTRACT_NOTIF}${AuthModule.user?.id}`
        }
    )

    return {
        temperatureControlReminder,
        defaultContrat,
        progReminder,
        enedisCounter,
        enedisDate,
        contractNotification
    }
}
