<template>
  <div class='loader-container'>
    <div class='center'>
      <a-spin size="large"/>
    </div>
  </div>
</template>

<style lang="scss">
@import "MyVLoader.module";
</style>

<script setup lang="ts">

</script>