import {Chart} from "chart.js";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import ChartJSPluginZoom from 'chartjs-plugin-zoom';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    LineController

} from 'chart.js'
import centerTextPlugin from "@/graphs/plugins/centerText";

Chart.register(ChartJSPluginDatalabels);
Chart.register(ChartJSPluginZoom);
Chart.register(centerTextPlugin);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement, LineController
)
