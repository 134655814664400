import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(['heaterSlide__content'])
}
const _hoisted_2 = { class: "heaterSlide__header" }
const _hoisted_3 = { class: "heaterSlide__title" }
const _hoisted_4 = { class: "heaterSlide__main" }
const _hoisted_5 = { class: "heaterSlide__mode" }

import {TAppliancesSlide} from "./interfaces"
import {EApplianceMode, EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";
import {heatingType} from "@/helpers/domains/heatingType";
import {computed} from "vue";
import {digitalFormat} from "@/helpers/number/formatNumber";
import {useI18n} from "vue-i18n";
import MyVIconMode from "@/components/ui/atoms/MyVIconMode/MyVIconMode.vue";
import MyVIconModeTemperature from "@/components/ui/atoms/MyVIconModeTemperature/MyVIconModeTemperature.vue";

export interface Props {
  name: TAppliancesSlide['name'];
  heatingLevel: TAppliancesSlide['heatingLevel'];
  isOn: TAppliancesSlide['isOn'];
  mode: TAppliancesSlide['mode'];
  link: TAppliancesSlide['link'];
  type?: TAppliancesSlide['type'];
  temp?: TAppliancesSlide["temp"];
  isFrostProtection?: TAppliancesSlide["isFrostProtection"];
  progType?: TAppliancesSlide["progType"];
  progName?: TAppliancesSlide["progName"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesSlide',
  props: {
    name: { default: undefined },
    heatingLevel: { default: undefined },
    isOn: { type: Boolean, default: false },
    mode: { default: undefined },
    link: { default: undefined },
    type: { default: undefined },
    temp: { default: undefined },
    isFrostProtection: { type: Boolean, default: false },
    progType: { default: undefined },
    progName: { default: '' }
  },
  setup(__props: any) {

const props = __props

const {t} = useI18n()

const formatTemp = computed(() => {
  return props.temp ? digitalFormat(props.temp, 1) as string : ''
})

const formattedName = computed((): string => {
  const name = props.name || "";
  return name.length > 25 ? name.slice(0, 25) + "..." : name;
});


const hasNoProg = computed(() => {
  return props.progType! === EApplianceProgType.Default;
})

const iconName = computed(() => {
  if (props.progType === EApplianceProgType.Default) {
    return 'prog-off';
  } else if (props.type === EApplianceType.WaterHeater) {
    return props.isOn ? ('zap') : ('x-circle');
  } else {
    switch (props.mode) {
      case EApplianceMode.Comfort:
      case EApplianceMode.Comfort1:
      case EApplianceMode.Comfort2:
        return 'thermometer-warm';
      case EApplianceMode.Eco:
      case EApplianceMode.EcoV:
        return 'leaf-2';
      case EApplianceMode.FrostProtection:
        return 'snowflake-01';
      case EApplianceMode.Temp:
        return 'thermometer-03';
      case EApplianceMode.Normal:
        return props.isOn ? 'thermometer-warm' : 'zap'
    }
  }
  throw new Error(`Appliance's mode ${props.mode} not supported !`);
})

const getHeatingType = computed(() => {
  if (hasNoProg.value) {
    return t('appliance_progType.default')
  }
  return t(heatingType(props.mode, props.isOn));
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    class: _normalizeClass(['heaterSlide', {'heaterSlide--green': _ctx.isOn && !_ctx.isFrostProtection && !hasNoProg.value}, {'heaterSlide--noProg': hasNoProg.value}]),
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(formattedName.value), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isOn)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.mode === _unref(EApplianceMode).Temp)
                  ? (_openBlock(), _createBlock(MyVIconModeTemperature, {
                      key: 0,
                      temperature: formatTemp.value,
                      size: "small"
                    }, null, 8, ["temperature"]))
                  : (_openBlock(), _createBlock(MyVIconMode, {
                      key: 1,
                      "icon-name": iconName.value,
                      active: true,
                      size: "small"
                    }, null, 8, ["icon-name"]))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.progType === _unref(EApplianceProgType).Manual && !_ctx.isOn)
                  ? (_openBlock(), _createBlock(MyVIconMode, {
                      key: 0,
                      "icon-name": "x-circle",
                      active: true,
                      size: "small"
                    }))
                  : (_openBlock(), _createBlock(MyVIconMode, {
                      key: 1,
                      size: "small",
                      "icon-name": "zap-off",
                      active: true
                    }))
              ], 64)),
          _createElementVNode("div", _hoisted_5, _toDisplayString(getHeatingType.value), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to", "class"]))
}
}

})