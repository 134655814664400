import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "myv-datepicker" }

import {TDatepicker} from "./interfaces";
import {computed, onMounted, ref} from "vue";
import dayjs, {Dayjs} from "dayjs";
import {EAggregationAlias} from "@/services/Conso/interfaces";

export interface Props {
  inputRequired?: TDatepicker["inputRequired"];
  placeholder?: TDatepicker["placeholder"];
  id?: TDatepicker["id"];
  label?: TDatepicker["label"];
  value?: TDatepicker["value"];
  dateDecorator?: TDatepicker["dateDecorator"];
  disabled?: TDatepicker["disabled"];
  onlyFuture?: TDatepicker["onlyFuture"];
  showTime?: TDatepicker["showTime"];
  error?: TDatepicker["error"];
  picker?: TDatepicker["picker"];
  format?: TDatepicker["format"];
  placement?: TDatepicker["placement"];
  bordered?: TDatepicker["bordered"];
  disabledDate?: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDatepicker',
  props: /*@__PURE__*/_mergeModels({
    inputRequired: { type: Boolean, default: false },
    placeholder: { default: undefined },
    id: { default: undefined },
    label: { default: undefined },
    value: { default: undefined },
    dateDecorator: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    onlyFuture: { type: Boolean, default: false },
    showTime: { type: Boolean, default: true },
    error: { default: undefined },
    picker: { default: 'date' },
    format: { default: 'YYYY-MM-DD HH:mm' },
    placement: { default: undefined },
    bordered: { type: Boolean, default: true },
    disabledDate: { default: undefined }
  }, {
    "open": {default: false},
    "openModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['update:value', 'ok'], ["update:open"]),
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const open = _useModel(__props, "open")
const m_value = ref<Dayjs>();

const showTimeFormat = computed(() => {
  if (props.showTime) {
    return {
      format: 'HH:mm',
      'minute-step': 15
    }
  }
  return false;
})

const getPopupContainer = (triggerNode: any) => {
  return triggerNode.parentNode.parentNode.parentNode;
}

const onOk = (value: any) => {
  emit('ok', value);
  open.value = false;
}

const onChange = (value: Dayjs) => {
  m_value.value = value;
  emit('update:value', m_value.value);
  open.value = false;
}

const onFocus = () => {
  open.value = true;
}

const onBlur = () => {
  open.value = false;
}

onMounted(() => {
  if (props.value) {
    m_value.value = dayjs(props.value);
  }
})

return (_ctx: any,_cache: any) => {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(['myv-datepicker-label',{'hasDateDecorator': _ctx.dateDecorator}])
        }, _toDisplayString(_ctx.label), 3))
      : _createCommentVNode("", true),
    _createVNode(_component_a_date_picker, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (open.value = true)),
      placeholder: _ctx.placeholder,
      value: m_value.value,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((m_value).value = $event)),
      "show-time": showTimeFormat.value,
      format: _ctx.format,
      picker: (props.picker && props.picker !== _unref(EAggregationAlias).Day) ? props.picker : undefined,
      "allow-clear": false,
      open: open.value,
      bordered: props.bordered,
      onChange: onChange,
      showNow: false,
      showToday: false,
      placement: _ctx.placement,
      disabled: _ctx.disabled,
      "disabled-date": _ctx.disabledDate,
      "get-popup-container": getPopupContainer,
      onOk: onOk,
      onFocus: onFocus,
      onBlur: onBlur,
      inputReadOnly: ""
    }, null, 8, ["placeholder", "value", "show-time", "format", "picker", "open", "bordered", "placement", "disabled", "disabled-date"])
  ]))
}
}

})