import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "program-state" }

import {HeatingModule, ProgramModule} from "@/store";
import ERoutes from "@/router/routes";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {computed} from "vue";
import {EQuickSettingsName} from "@/services/Heating/interfaces";

import {useRouter} from "vue-router";
import {EApplianceProgType} from "@/services/Appliances/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVProgramState',
  emits: ['update:quickSetting', 'update:stop'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const router = useRouter()

const handleRoute = (route: ERoutes) => {
  router.push(route)
}

const quickSettingsSelect = () => {
  if (ProgramModule.programmingState?.programType === EApplianceProgType.Quick) {
    emit('update:quickSetting', HeatingModule.quickSettings?.find(quickSetting => quickSetting.id === ProgramModule.programmingState?.id))
  }
}

const handleStopQuickMode = () => {
  emit('update:stop')
}

const iconQuickSetting = computed(() => {
  return (quickSettingName: string) => {
    switch (quickSettingName) {
      case EQuickSettingsName.AtHome:
      default:
        return 'home-user';
      case EQuickSettingsName.ShortAbsence:
        return 'log-out';
      case EQuickSettingsName.LongAbsence:
        return 'briefcase-02';
    }
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(ProgramModule).programmingState && _unref(ProgramModule).programmingState.programType === _unref(EApplianceProgType).Program)
      ? (_openBlock(), _createBlock(MyVButton, {
          key: 0,
          class: "active program",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleRoute(_unref(ERoutes).PROGRAM)))
        }, {
          default: _withCtx(() => [
            _createVNode(MyVIcon, { name: "play3" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(_unref(ProgramModule).programmingState?.programName)), 1)
          ]),
          _: 1
        }))
      : (_unref(ProgramModule).programmingState && _unref(ProgramModule).programmingState.programType === _unref(EApplianceProgType).Quick)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(MyVButton, {
              class: "active",
              onClick: quickSettingsSelect
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(MyVIcon, {
                    name: iconQuickSetting.value(_unref(ProgramModule).programmingState?.programName!)
                  }, null, 8, ["name"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t(_unref(ProgramModule).programmingState?.programName)), 1)
                ]),
                (!_unref(ProgramModule).programmingState.geolocCurrentlyOn)
                  ? (_openBlock(), _createBlock(MyVIcon, {
                      key: 0,
                      name: "pen"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(MyVButton, {
              class: "close-button",
              onClick: handleStopQuickMode
            }, {
              default: _withCtx(() => [
                _createVNode(MyVIcon, { name: "cross" })
              ]),
              _: 1
            })
          ], 64))
        : (_openBlock(), _createBlock(MyVButton, {
            key: 2,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (handleRoute(_unref(ERoutes).PROGRAM))),
            class: "program"
          }, {
            default: _withCtx(() => [
              _createVNode(MyVIcon, { name: "power1" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('global.no_program')), 1)
            ]),
            _: 1
          }))
  ]))
}
}

})