import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = { class: "MultiStep__footer ProgramDuplicate__footer" }

import {computed, onBeforeMount, reactive, ref, watch} from "vue";
import {ProgramModule} from "@/store";
import router from "@/router";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import {TProgramCreateDuplicate, TProgramCreateFormProps} from './interfaces';
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVAntdradio from "@/components/ui/atoms/MyVAntdradio/MyVAntdradio.vue";
import {useI18n} from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramDuplicateView',
  props: {
    cancelCreate: { type: Function },
    baseForm: {},
    generateEditLink: { type: Function }
  },
  setup(__props: any) {

const props = __props

const form = reactive<TProgramCreateDuplicate>({
  id: undefined,
});
const noIdSelectedError = ref('');

const {t} = useI18n()

const options = computed(() => {
  return ProgramModule.programs!.map(({id, name}) => ({
    label: t(name ?? ''),
    value: id
  }));
})

const onFinish = () => {
  if (form.id) {
    ProgramModule.createOrDuplicateProgram({
      id: form.id,
      name: props.baseForm.name
    })
        .then(({id}) => {
          router.push(props.generateEditLink(id));
        });
  } else {
    noIdSelectedError.value = t('programCreate.tunnel.from_duplicate.form.error.no_program_selected');
  }
}

onBeforeMount(() => {
  ProgramModule.getPrograms();
})

watch(() => form.id, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    noIdSelectedError.value = '';
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('programCreate.tunnel.title.from_duplicate'),
    class: "program-duplicate-view",
    type: "page",
    returnAction: _ctx.cancelCreate
  }, {
    default: _withCtx(() => [
      (_unref(ProgramModule).programs)
        ? (_openBlock(), _createBlock(_component_a_form, {
            key: 0,
            model: form,
            onFinish: onFinish,
            class: "MultiStep__form"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(MyVFieldset, {
                  title: _ctx.$t('ProgramDuplicate.choose_program')
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(MyVAntdradio, {
                        options: options.value,
                        value: form.id,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.id) = $event)),
                        error: noIdSelectedError.value,
                        fullWidth: true
                      }, null, 8, ["options", "value", "error"])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"])
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(MyVButton, {
                  type: _unref(EButtonType).Submit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('ProgramDuplicate.submit')), 1)
                  ]),
                  _: 1
                }, 8, ["type"]),
                (typeof(_ctx.cancelCreate)=='function')
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.cancelCreate && _ctx.cancelCreate(...args))),
                      type: "button",
                      class: "popinForm__footer-back"
                    }, _toDisplayString(_ctx.$t('global.cancel')), 1))
                  : (_ctx.cancelCreate)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: _ctx.cancelCreate,
                        class: "popinForm__footer-back"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"]))
                    : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["model"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "returnAction"]))
}
}

})