import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "MultiStep__main ProgramCreateZoneAppliances__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = { class: "ProgramCreateZoneAppliances__legend" }
const _hoisted_4 = { class: "MultiStep__footer inner" }
const _hoisted_5 = { class: "programCreate__legend" }
const _hoisted_6 = { class: "text-center" }

import {computed, onBeforeMount, ref} from "vue";

import MyVButton from '@/components/ui/atoms/MyVButton/MyVButton.vue';
import MyVFieldset from '@/components/ui/atoms/MyVFieldset/MyVFieldset.vue';
import MyVBehaviourCheckbox from '@/components/ui/atoms/MyVBehaviourCheckbox/MyVBehaviourCheckbox.vue';

import {EBehaviourPeriod, TProgramCreateZoneAppliances} from "./interfaces";
import {EApplianceType} from "@/services/Appliances/interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {useI18n} from "vue-i18n";

interface Props {
  onSubmit: TProgramCreateZoneAppliances["onSubmit"];
  returnAction?: TProgramCreateZoneAppliances["returnAction"];
  appliances?: TProgramCreateZoneAppliances["appliances"];
  behaviours?: TProgramCreateZoneAppliances["behaviours"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVProgramCreateZoneAppliances',
  props: {
    onSubmit: { type: Function, default: undefined },
    returnAction: { default: undefined },
    appliances: { default: () => [] },
    behaviours: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const form = ref<TProgramCreateZoneAppliances["behaviours"]>({});
const {t} = useI18n()

const optionsZone = computed(() => {
  return [
    {
      "label": t('programCreate.tunnel.appliance_zone.day'),
      value: EBehaviourPeriod.Day,
    },
    {
      "label": t('programCreate.tunnel.appliance_zone.night'),
      value: EBehaviourPeriod.Night,
    },
    {
      label: t('programCreate.tunnel.appliance_zone.none'),
      value: EBehaviourPeriod.Void
    }
  ]
})

const onFinish = () => {
  props.onSubmit(form.value);
}

onBeforeMount(() => {
  if (props.behaviours) {
    Object.assign(form, props.behaviours);
  } else {
    for (const {id} of props.appliances) {
      form.value[id] = [];
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    model: form.value,
    onFinish: onFinish,
    class: "MultiStep__form program-create-zone-appliances"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('programCreate.tunnel.zone_appliances')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appliances, (appliance) => {
                return (_openBlock(), _createBlock(MyVBehaviourCheckbox, {
                  key: appliance.id,
                  label: _ctx.$t(appliance.name),
                  options: optionsZone.value,
                  values: form.value[appliance.id],
                  "onUpdate:values": ($event: any) => ((form.value[appliance.id]) = $event),
                  noSelectedlabel: "",
                  showBox: appliance.applianceType !== _unref(EApplianceType).WaterHeater
                }, null, 8, ["label", "options", "values", "onUpdate:values", "showBox"]))
              }), 128)),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('programCreate.tunnel.appliance_zone.legend')), 1)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('programCreate.tunnel.infos_modify_after')), 1),
        _createVNode(MyVButton, {
          type: _unref(EButtonType).Submit,
          icon: "carret-right"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('programCreate.tunnel.create')), 1)
          ]),
          _: 1
        }, 8, ["type"]),
        _createElementVNode("div", _hoisted_6, [
          (typeof(_ctx.returnAction)=='function')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnAction && _ctx.returnAction(...args))),
                type: "button",
                class: "MultiStep__form-back"
              }, _toDisplayString(_ctx.$t('global.cancel')), 1))
            : (_ctx.returnAction)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: _ctx.returnAction,
                  class: "MultiStep__form-back"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["to"]))
              : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})