import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {TIconMode} from "@/components/ui/atoms/MyVIconMode/interfaces";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";

interface Props {
  iconName: TIconMode["iconName"];
  size?: TIconMode["size"];
  active?: TIconMode["active"];
  contrast?: TIconMode["contrast"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVIconMode',
  props: {
    iconName: { default: undefined },
    size: { default: "medium" },
    active: { type: Boolean, default: false },
    contrast: { type: Boolean }
  },
  setup(__props: any) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['myv-icon-mode', `myv-icon-mode__${props.size}`,`myv-icon-mode-type__${props.iconName}`, {active: props.active}, {contrast: props.contrast}])
  }, [
    _createVNode(MyVIcon, {
      name: props.iconName
    }, null, 8, ["name"])
  ], 2))
}
}

})