import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "PlanningTimeline" }
const _hoisted_2 = {
  key: 0,
  class: "PlanningTimeline__time"
}
const _hoisted_3 = { class: "PlanningTimeline__item" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "PlanningTimeline__time" }

import {TAppliance} from "@/services/Appliances/interfaces";

import {TDailyProg} from './interfaces';
import {heatingIcon, heatingType} from "@/helpers/domains/heatingType";
import {IntlModule} from "@/store";
import {ELocale} from "@/store/modules/Intl";
import {EPlainTextHours} from "@/components/domains/Heating/MyVEditRange/interfaces";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useDate} from "@/helpers/dates/date-utils";
import MyVIconMode from "@/components/ui/atoms/MyVIconMode/MyVIconMode.vue";

interface Props {
  ranges: TDailyProg["ranges"];
  className?: TDailyProg["className"];
  action?: TDailyProg["action"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPlanningTimeline',
  props: {
    ranges: { default: undefined },
    className: { default: undefined },
    action: { default: undefined }
  },
  setup(__props: any) {



const {t} = useI18n()

const locale = computed(() => {
  return IntlModule.locale
})

const isLocaleEn = computed(() => {
  return locale.value === ELocale.en
})

const icon = (mode: TAppliance['programming']['mode'], isOn: TAppliance['programming']['isOn']): string => {
  return heatingIcon(mode, isOn);
}
const rangeHourFormatted = (hour: string) => {
  if (hour == EPlainTextHours.OneMinuteBeforeMidnight) {
    return t('global.hour.midnight')
  }
  return useDate(hour, 'HH:mm').locale(locale.value).format("LT")
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['PlanningTimeline__container', _ctx.className])
  }, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranges, (range, index) => {
        return (_openBlock(), _createElementBlock("li", { key: index }, [
          (index == 0 || index > 0 && _ctx.ranges[index-1].to != range.from)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", {
                  class: _normalizeClass({'timeLocaleEn': isLocaleEn.value})
                }, _toDisplayString(rangeHourFormatted(range.from)), 3)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(MyVIconMode, {
              class: "PlanningTimeline__icon",
              active: true,
              "icon-name": icon(range.mode, range.isOn)
            }, null, 8, ["icon-name"]),
            _createElementVNode("button", {
              type: "button",
              onClick: ($event: any) => (_ctx.action(_ctx.e, index)),
              class: "PlanningTimeline__edit"
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t(_unref(heatingType)(range.mode, range.isOn))) + " ", 1),
              _cache[0] || (_cache[0] = _createElementVNode("svg", {
                class: "icon icon-pen",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-pen" })
              ], -1))
            ], 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", {
              class: _normalizeClass({'timeLocaleEn': isLocaleEn.value})
            }, _toDisplayString(rangeHourFormatted(range.to)), 3)
          ])
        ]))
      }), 128))
    ])
  ], 2))
}
}

})