import dayjs, {Dayjs, ManipulateType, OpUnitType} from 'dayjs';
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import dayOfYearPlugin from "dayjs/plugin/dayOfYear";
import updateLocalePlugin from "dayjs/plugin/updateLocale";
import calendarPlugin from "dayjs/plugin/calendar";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";
import relativeTimePlugin from "dayjs/plugin/relativeTime";
import isoWeekPlugin from "dayjs/plugin/isoWeek";
import timezonePlugin from "dayjs/plugin/timezone";
import utcPlugin from "dayjs/plugin/utc";

dayjs.extend(localizedFormatPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(updateLocalePlugin);
dayjs.extend(relativeTimePlugin);
dayjs.extend(calendarPlugin);
dayjs.extend(customParseFormatPlugin);
dayjs.extend(isoWeekPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(utcPlugin);

import "dayjs/locale/en";
import "dayjs/locale/fi";
import "dayjs/locale/hu";
import "dayjs/locale/fr";
import "dayjs/locale/sl";
import "dayjs/locale/de";
import "dayjs/locale/es";

import {ELocale} from "@/store/modules/Intl";
import {calendars} from "@/helpers/locale/calendar";
import {AuthModule, IntlModule} from "@/store";

Object.values(ELocale).forEach((key) => {
    dayjs.updateLocale(key, calendars[key])
})

export class DateUtils {
    private dateInstance: Dayjs;

    constructor(dateStr?: string | number | Date | Dayjs | null, format?: string) {
        if (typeof dateStr === 'number') {
            this.dateInstance = dayjs.unix(dateStr);
        } else if (dateStr) {
            this.dateInstance = dayjs(dateStr, format);
        } else if (!dayjs(dateStr).isValid()) {
            this.dateInstance = dayjs('')
        } else {
            this.dateInstance = dayjs()
        }

        this.dateInstance.locale(IntlModule.locale)
    }

    add(amount: number, unit: string): DateUtils {
        this.dateInstance = this.dateInstance.add(amount, unit as ManipulateType);
        return this;
    }

    substract(amount: number, unit: string): DateUtils {
        this.dateInstance = this.dateInstance.subtract(amount, unit as ManipulateType);
        return this;
    }

    year(): number {
        return this.dateInstance.year();
    }

    day(): number {
        return this.dateInstance.day();
    }

    dayOfYear(): number {
        return this.dateInstance.dayOfYear();
    }

    month(): number {
        return this.dateInstance.month();
    }

    isBefore(compareDate: string | number | Date | Dayjs | DateUtils, granularity?: OpUnitType): boolean {
        if (compareDate instanceof DateUtils) {
            return this.dateInstance.isBefore(dayjs(compareDate.toDate()), granularity);
        }
        return this.dateInstance.isBefore(dayjs(compareDate), granularity);
    }

    isSame(compareDate: string | number | Date | Dayjs, granularity?: string): boolean {
        return this.dateInstance.isSame(compareDate, granularity as OpUnitType);
    }

    isValid(): boolean {
        return this.dateInstance.isValid();
    }

    localTimezoneDate() {
        const timezone = AuthModule.user.defaultSite.timezone;

        return this.dateInstance.tz(timezone)
    }

    diff(compareDate: string | number | Date | Dayjs, granularity: string): number {
        return this.dateInstance.diff(compareDate, granularity as OpUnitType);
    }

    calendar(): string {
        return this.dateInstance.locale(IntlModule.locale).calendar()
    }

    isSameOrAfter(compareDate: string | number | Date | Dayjs | DateUtils | undefined, granularity?: string): boolean {
        if (compareDate instanceof DateUtils) {
            return this.dateInstance.isSameOrAfter(dayjs(compareDate.toDate()), granularity as OpUnitType);
        }
        return this.dateInstance.isSameOrAfter(compareDate, granularity as OpUnitType);
    }

    isSameOrBefore(compareDate: string | number | Date | Dayjs): boolean {
        return this.dateInstance.isSameOrBefore(compareDate);
    }

    isAfter(compareDate: string | number | Date | Dayjs | DateUtils, granularity?: OpUnitType): boolean {
        if (compareDate instanceof DateUtils) {
            return this.dateInstance.isAfter(dayjs(compareDate.toDate()), granularity);
        }
        return this.dateInstance.isAfter(compareDate);
    }

    format(formatString: string): string {
        return this.dateInstance.format(formatString);
    }

    locale(locale: string): DateUtils {
        dayjs.locale(locale);
        return this;
    }

    startOf(unit: string) {
        this.dateInstance = this.dateInstance.startOf(unit as ManipulateType);
        return this;
    }

    endOf(unit: string) {
        this.dateInstance = this.dateInstance.endOf(unit as ManipulateType);
        return this;
    }

    hour(hour?: number): DateUtils {
        if (hour !== undefined) {
            this.dateInstance = this.dateInstance.hour(hour);
            return this;
        } else {
            return this;
        }
    }

    unix(): number {
        return this.dateInstance.unix();
    }

    isoWeekday(number: number): DateUtils {
        return useDate(dayjs().isoWeekday(number).toDate());
    }

    toDate(): Date {
        return this.dateInstance.toDate();
    }

    diffTime(timeA: dayjs.Dayjs | string, timeB: dayjs.Dayjs | string): number {
        const timeToNumber = (time: string) => dayjs(time, ['YYYY-MM-DDTHH:mm:ss.SSSZ', 'HH:mm']);

        if (typeof timeA === 'string') {
            timeA = timeToNumber(timeA);
        }
        if (typeof timeB === 'string') {
            timeB = timeToNumber(timeB);
        }

        const diffInMinutes = timeA.diff(timeB, 'minute');

        return Math.trunc(diffInMinutes / 60) + (diffInMinutes % 60) / 100;
    }
}

export function useDate(dateStr?: string | Date | DateUtils | Dayjs | undefined | null | number, format?: string): DateUtils {
    if (dateStr instanceof DateUtils) {
        return dateStr;
    }

    return new DateUtils(dateStr, format);
}