import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "range-slider" }
const _hoisted_2 = { class: "range-slider-text" }
const _hoisted_3 = { class: "range-slider-text__hours" }
const _hoisted_4 = { class: "range-slider-text__hour" }
const _hoisted_5 = { class: "range-slider-text__hour" }

import {computed, onMounted, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

interface Props {
  end?: string;
  start?: string;
  min: number;
  max: number;
  steps?: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVRangeSlider',
  props: {
    end: { default: undefined },
    start: { default: undefined },
    min: { default: 0 },
    max: { default: 1440 },
    steps: { default: 15 }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit

const values = ref<[number, number]>([0, 0])

const {t} = useI18n()

const marks = computed(() => {
  return {
    [props.min]: formatHours(props.min),
    [props.max / 2]: formatHours(props.max / 2),
    [props.max / 4]: formatHours(props.max / 4),
    [props.max * 0.75]: formatHours(props.max * 0.75),
    [props.max]: props.max === 1440 ? t('global.hour.midnight') : formatHours(props.max)
  }
})

const toMinutes = (hours: string | undefined) => {
  if (hours) {
    const fieldArray = hours.split(":");
    return parseInt(fieldArray[0]) * 60 + parseInt(fieldArray[1])
  }
}

const formatHours = (minutes: number) => {
  return ('00' + Math.floor(minutes / 60)).slice(-2) + ':' + ('00' + (minutes % 60)).slice(-2)
}

onMounted(() => {
  values.value[0] = toMinutes(props.start) ?? props.max * 0.25
  values.value[1] = toMinutes(props.end) ?? props.max * 0.75
})

watch(values, (newValue) => {
  if (newValue[0] === newValue[1]) {
    if (newValue[1] < props.max) {
      values.value[1] += 15
    } else if (newValue[1] === props.max) {
      values.value[0] -= 15
    }
  }

  /!* If 00h00 is selected, we remove 1 minute to comply with the backend which waits for 23h59 *!/
  emit('update:modelValue', newValue.map((hours: number) => hours === 1440 ? formatHours(hours - 1) : formatHours(hours)));
})

return (_ctx: any,_cache: any) => {
  const _component_a_slider = _resolveComponent("a-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_slider, {
      value: values.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((values).value = $event)),
      min: _ctx.min,
      max: _ctx.max,
      step: _ctx.steps,
      range: "",
      "tooltip-open": false,
      marks: marks.value
    }, null, 8, ["value", "min", "max", "step", "marks"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(formatHours(values.value[0])), 1),
        _cache[1] || (_cache[1] = _createTextVNode(" à ")),
        _createElementVNode("span", _hoisted_5, _toDisplayString(values.value[1] >= 1439 ? _ctx.$t('global.hour.midnight') : formatHours(values.value[1])), 1)
      ])
    ])
  ]))
}
}

})