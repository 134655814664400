import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "pdl-validation" }
const _hoisted_2 = {
  key: 0,
  class: "pdl-validation-wrapper"
}
const _hoisted_3 = { class: "pdl-validation-steps-content" }
const _hoisted_4 = { class: "pdl-validation-buttons" }
const _hoisted_5 = {
  key: 1,
  class: "result-content"
}
const _hoisted_6 = {
  key: 2,
  class: "result-content"
}

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {AuthModule, ContractModule} from "@/store";
import {TSite} from "@/services/Site/interfaces";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {TStep} from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationModal/interfaces";
import {computed, markRaw, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import PdlService from "@/services/Pdl";
import MyVPdlValidationExplanationStep
  from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationExplanationStep/MyVPdlValidationExplanationStep.vue";
import MyVPdlValidationModalImageUploadStep
  from "@/components/ui/organisms/MyVPdlValidation/MyVPdlValidationImageUploadStep/MyVPdlValidationModalImageUploadStep.vue";
import {useResponsive} from "@/composables/useResponsive";

interface Props {
  isPreviousButtonDisplayed?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPdlValidationModal',
  props: {
    isPreviousButtonDisplayed: { type: Boolean, default: true }
  },
  emits: ['close', 'close-and-restore'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

const current = ref(0);
const steps = ref<TStep[]>([]);
const isSuccess = ref(false);
const isError = ref(false);
const {t} = useI18n()

const {isMobile} = useResponsive()


const pdlValidationInvoice = computed(() => {
  return ContractModule.pdlValidationInvoice
})

const currentSiteId = computed((): TSite['id'] => {
      return getCurrentSiteIdFromCookie(AuthModule.user);
    }
)

const close = () => {
  resetFile()
  isSuccess.value = false
  isError.value = false
  emit("close")
}

const resetFile = () => {
  ContractModule.updatePdlValidationInvoice({file: null, previewUrl: undefined});
}

onMounted(() => {
  steps.value = [{
    title: t('conso.pdl.validation.first_step'),
    content: markRaw(MyVPdlValidationExplanationStep),
  }, {
    title: t('conso.pdl.validation.send'),
    content: markRaw(MyVPdlValidationModalImageUploadStep),
  }]
})


const handlePdlValidation = () => {
  if (pdlValidationInvoice.value.file && pdlValidationInvoice.value.file instanceof Blob) {
    const data = new FormData();
    data.append('name', `${currentSiteId.value}_pdl_validation`);
    data.append('file', pdlValidationInvoice.value.file);

    PdlService.postInvoice(currentSiteId.value, data).then(() => {
      isSuccess.value = true
      isError.value = false
      current.value++
      resetFile()
    }).catch(() => {
      isError.value = true
      resetFile()
    })
  } else {
    resetFile()
    close()
  }

}

const closeAndRestore = () => {
  emit("close-and-restore")
  isSuccess.value = false
  isError.value = false
}


return (_ctx: any,_cache: any) => {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyVPageWithReturn, {
      title: _ctx.$t('conso.pdl.validation.title'),
      type: "popin",
      "return-action": close,
      class: "WelcomeConsoTooltip"
    }, {
      default: _withCtx(() => [
        (!isSuccess.value && !isError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_a_steps, {
                current: current.value,
                direction: _unref(isMobile) ? 'vertical' : 'horizontal'
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value, (item) => {
                    return (_openBlock(), _createBlock(_component_a_step, {
                      key: item.title,
                      title: item.title,
                      content: item.content
                    }, null, 8, ["title", "content"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["current", "direction"]),
              _createElementVNode("div", _hoisted_3, [
                (current.value === 0)
                  ? (_openBlock(), _createBlock(MyVPdlValidationExplanationStep, { key: 0 }))
                  : _createCommentVNode("", true),
                (current.value === 1)
                  ? (_openBlock(), _createBlock(MyVPdlValidationModalImageUploadStep, {
                      key: 1,
                      content: steps.value[current.value].content
                    }, null, 8, ["content"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                (current.value === 0 && _ctx.isPreviousButtonDisplayed)
                  ? (_openBlock(), _createBlock(MyVButton, {
                      key: 0,
                      primary: false,
                      size: "small",
                      onClick: closeAndRestore
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.previous')), 1)
                      ]),
                      _: 1
                    }))
                  : (_ctx.isPreviousButtonDisplayed)
                    ? (_openBlock(), _createBlock(MyVButton, {
                        key: 1,
                        size: "small",
                        primary: false,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (current.value = current.value - 1)),
                        disabled: current.value === 0
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.previous')), 1)
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                (current.value === 0)
                  ? (_openBlock(), _createBlock(MyVButton, {
                      key: 2,
                      size: "small",
                      primary: false,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (current.value = current.value +1))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.next')), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(MyVButton, {
                      key: 3,
                      size: "small",
                      onClick: handlePdlValidation,
                      disabled: !pdlValidationInvoice.value.file
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.validate')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
              ])
            ]))
          : _createCommentVNode("", true),
        (isSuccess.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("svg", {
                class: "icon icon-check-circle",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-check-circle" })
              ], -1)),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('conso.pdl.validation.validate.success.message')), 1),
              _createVNode(MyVButton, {
                size: "small",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.validate.success.acquit')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (isError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("svg", {
                class: "icon icon-exclamation-circle",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-exclamation-circle" })
              ], -1)),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('conso.pdl.validation.validate.error.message')), 1),
              _createVNode(MyVButton, {
                size: "small",
                onClick: closeAndRestore
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('conso.pdl.validation.validate.error.retry')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}
}

})