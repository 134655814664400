import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "device-status-calibration-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {computed, ref, useCssModule} from "vue";
import {AppliancesModule} from "@/store";
import {TAppliance} from "@/services/Appliances/interfaces";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCalibrationPopin',
  props: {
    appliance: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

useCssModule()

const props = __props
const emit = __emit

const temperature = ref(20);
const minTemperature = ref(7);
const maxTemperature = ref(35);
const isSuccess = ref();
const isError = ref();

const isTemperatureAllowed = computed(() => {
  return temperature.value >= minTemperature.value && temperature.value <= maxTemperature.value
})

const validateCalibration = () => {
  AppliancesModule.updateApplianceTemperature({
    id: props.appliance.id,
    body: {
      temperatureMeasurement: temperature.value
    }
  })
      .then((result) => {
        if (result) {
          isSuccess.value = true
        } else {
          isError.value = true
        }
      })
      .catch(() => {
        isError.value = true
      });
}

const retry = () => {
  isSuccess.value = false
  isError.value = false
}

const close = () => {
  emit('close')
}

return (_ctx: any,_cache: any) => {
  const _component_a_input_number = _resolveComponent("a-input-number")!

  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('device_status.manual_calibration.title'),
    type: "popin",
    class: "device-status-calibration",
    "return-action": close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (!isSuccess.value && !isError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", null, _toDisplayString(_ctx.appliance.name), 1),
              _createElementVNode("div", null, [
                _createVNode(MyVIcon, { name: "temperature" })
              ]),
              _createElementVNode("div", {
                innerHTML:  _ctx.$t('device_status.manual_calibration.description')
              }, null, 8, _hoisted_3),
              _createVNode(_component_a_input_number, {
                size: "large",
                "default-value": "20",
                autofocus: "",
                formatter: value => `${value}°`,
                label: _ctx.$t('device_status.manual_calibration.temperature_placeholder'),
                value: temperature.value,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((temperature).value = $event)),
                min: minTemperature.value,
                max: maxTemperature.value
              }, null, 8, ["formatter", "label", "value", "min", "max"]),
              _createVNode(MyVButton, {
                size: "small",
                onClick: validateCalibration,
                disabled: !isTemperatureAllowed.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('device_status.manual_calibration.validate')), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]))
          : _createCommentVNode("", true),
        (isSuccess.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("svg", {
                class: "icon icon-check-circle",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-check-circle" })
              ], -1)),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('device_status.manual_calibration.success.message')), 1),
              _createVNode(MyVButton, {
                size: "small",
                onClick: close
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('device_status.manual_calibration.success.acquit')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (isError.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("svg", {
                class: "icon icon-exclamation-circle",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-exclamation-circle" })
              ], -1)),
              _createElementVNode("div", null, _toDisplayString(_ctx.$t('device_status.manual_calibration.error.message')), 1),
              _createVNode(MyVButton, {
                size: "small",
                onClick: retry
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('device_status.manual_calibration.error.retry')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})