import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/videos/A5.mp4'


const _hoisted_1 = ["innerHTML"]

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {useResponsive} from "@/composables/useResponsive";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {ref} from "vue";
import {Checkbox} from "ant-design-vue";
import {useReminder} from "@/composables/useReminder";
import {openAppRatingModalWithTimeout} from "@/helpers/domains/mobileApps/rating";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTemperatureControlModal',
  emits: ['close', 'setPeriod', 'hasEndDate', 'openReminder'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const {isMobile} = useResponsive()
const {
  setReminder: setTemperatureControlReminder,
  showReminder: hasTemperatureControlReminder
} = useReminder("temperatureControlReminder");

const dontShowAgain = ref(false);

const close = () => {
  emit('close');
  openAppRatingModalWithTimeout()
}

const handleClick = () => {
  if (hasTemperatureControlReminder() && dontShowAgain.value) {
    setTemperatureControlReminder(false);
  }
  close()
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
    class: "temperature-control",
    type: "popin",
    title: _ctx.$t('prog_reminder.title'),
    "return-action": close,
    onCancel: close
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        innerHTML: _ctx.$t('heating.temperatureControl.description')
      }, null, 8, _hoisted_1),
      _cache[1] || (_cache[1] = _createElementVNode("video", {
        src: _imports_0,
        autoplay: "",
        controls: "",
        muted: "",
        playsinline: ""
      }, null, -1)),
      _createVNode(MyVButton, { onClick: handleClick }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.ok')), 1)
        ]),
        _: 1
      }),
      _createVNode(_unref(Checkbox), {
        class: "dontAskAgain",
        checked: dontShowAgain.value,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((dontShowAgain).value = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.dont_ask_again')), 1)
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    _: 1
  }, 40, ["title"]))
}
}

})