import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "Housing__housing" }
const _hoisted_2 = { class: "Housing__housing" }
const _hoisted_3 = { class: "Housing__erasures" }
const _hoisted_4 = { class: "Housing__erasures__stop text-center" }
const _hoisted_5 = { key: 0 }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdselect from "@/components/ui/atoms/MyVAntdselect/MyVSelect.vue";
import {THousing} from "@/views/domains/Profile/Housing/interfaces";
import {AppliancesModule, ConsoModule, HousingModule} from "@/store";
import {TUnmanagedAppliance} from "@/services/Appliances/interfaces";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {computed, onBeforeMount, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import MyVHousingEquipment from "@/components/domains/Profile/MyVHousingEquipments/MyVHousingEquipment.vue";
import {EButtonSize, EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import {notification} from "ant-design-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HousingView',
  setup(__props) {

const form = reactive<THousing>({
  name: '',
  layout: 1,
  type: '',
  surface: '0',
  occupantNumber: '1',
  mainRoomOrientation: '',
})
const _appliances = ref<any[]>([])
const error = ref<string | null>(null)
const showErasureMessage = ref(false)
const appliancesCategoryToHide = ['unmanagedappliance.category.lighting']
const initialForm = ref('')

const {t} = useI18n()

const typeOptions = computed(() => {
  return [
    {
      value: 'maison',
      label: t('profile.housing.choices.house')
    },
    {
      value: 'appartement',
      label: t('profile.housing.choices.apartment')
    },
  ]
})

const orientationOptions = computed(() => {
  return [
    {
      value: 'est',
      label: t('profile.housing.choices.east')
    },
    {
      value: 'ouest',
      label: t('profile.housing.choices.west')
    },
    {
      value: 'nord',
      label: t('profile.housing.choices.north')
    },
    {
      value: 'sud',
      label: t('profile.housing.choices.south')
    },
  ]
})

const validateFields = computed(() => {
  return form.type !== "" && form.mainRoomOrientation !== "" && parseInt(form.surface) > 0 && form.layout > 0 && parseInt(form.occupantNumber) > 0 && !isSameForm.value
})

const isSameForm = computed(() => {
  return initialForm.value === JSON.stringify(form).replace(/ /g, '');
})

const saveHousing = () => {
  if (validateFields.value) {
    HousingModule.updateHousing(form)
        .then(() => {
          initialForm.value = JSON.stringify(form)

          notification.success({
            message: t('notification.success.title'),
            description: t('notification.success.desc'),
            duration: 3
          })
        })
        .catch(e => {
          error.value = e.response ? e.response.data.message : 'Erreur serveur'
        })
  }
}

const setAppliancesIcon = () => {
  _appliances.value = _appliances.value.map((el: TUnmanagedAppliance) => {
    return {
      ...el,
      icon: el.category.split('.')[1]
    }
  }).filter(appliance => !appliancesCategoryToHide.includes(appliance.category))
}

const stopModulation = () => {
  ConsoModule.stopModulation().then(() => {
    showErasureMessage.value = true
  }).catch(() => {
    showErasureMessage.value = false
  })
}

onBeforeMount(() => {
  HousingModule.getHousing().then(data => {
    form.name = (data as THousing).name
    form.layout = (data as THousing).layout
    form.type = (data as THousing).type
    form.mainRoomOrientation = (data as THousing).mainRoomOrientation
    form.surface = String((data as THousing).surface)
    form.occupantNumber = String((data as THousing).occupantNumber)

    initialForm.value = JSON.stringify(form)

  }).catch(e => {
    if (e.response.status !== 404) {
      error.value = e.response?.data ? e.response.data.message : 'Erreur serveur';
    }
  })

  AppliancesModule.getUnmanagedAppliances().then(data => {
    _appliances.value = data!.filter(appliance => !appliancesCategoryToHide.includes(appliance.category))
    setAppliancesIcon()
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur'
  })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('page.housing.title'),
    type: "page",
    "return-action": _unref(ERoutes).HOME,
    class: "Housing"
  }, {
    default: _withCtx(() => [
      (error.value)
        ? (_openBlock(), _createBlock(MyVErrorBanner, {
            key: 0,
            error: error.value,
            class: "error-banner",
            onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null))
          }, null, 8, ["error"]))
        : _createCommentVNode("", true),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.housing.infos.title')), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('profile.housing.infos.description')), 1),
        _createElementVNode("form", {
          onSubmit: _withModifiers(saveHousing, ["prevent"]),
          class: "Housing__housing__form"
        }, [
          _createVNode(MyVAntdselect, {
            options: typeOptions.value,
            id: "type",
            label: `${_ctx.$t('profile.housing.label.type')} *`,
            value: form.type,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.type) = $event))
          }, null, 8, ["options", "label", "value"]),
          _createVNode(MyVAntdinput, {
            "input-type": _unref(EInputType).NumberInput,
            id: "surface",
            label: _ctx.$t('profile.housing.label.surface'),
            value: form.surface,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.surface) = $event)),
            min: 0,
            max: 10000,
            "input-required": ""
          }, null, 8, ["input-type", "label", "value"]),
          _createVNode(MyVAntdinput, {
            "input-type": _unref(EInputType).NumberInput,
            id: "roomNumber",
            "input-required": "",
            label: _ctx.$t('profile.housing.label.number_rooms'),
            value: form.layout,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.layout) = $event)),
            min: 1,
            max: 100
          }, null, 8, ["input-type", "label", "value"]),
          _createVNode(MyVAntdinput, {
            "input-type": _unref(EInputType).NumberInput,
            id: "personNumber",
            label: `${_ctx.$t('profile.housing.label.occupantNumber')} *`,
            value: form.occupantNumber,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((form.occupantNumber) = $event)),
            min: 1,
            max: 100
          }, null, 8, ["input-type", "label", "value"]),
          _createVNode(MyVAntdselect, {
            options: orientationOptions.value,
            id: "orientation",
            label: `${_ctx.$t('profile.housing.label.main_room_orientation')} *`,
            value: form.mainRoomOrientation,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((form.mainRoomOrientation) = $event))
          }, null, 8, ["options", "label", "value"]),
          _createVNode(MyVButton, {
            type: _unref(EButtonType).Submit,
            class: "Housing__housing__form__save",
            disabled: !validateFields.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.housing.button.save')), 1)
            ]),
            _: 1
          }, 8, ["type", "disabled"])
        ], 32)
      ]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.housing.infos_appliances.title')), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(MyVHousingEquipment)
      ]),
      _createVNode(MyVDisplayGroupStore, {
        currentRight: _unref(EDisplayGroupRightName).MY_HOUSE,
        hideIfDisabled: true
      }, null, 8, ["currentRight"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.housing.erasures.title')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(MyVButton, {
            onClick: stopModulation,
            type: _unref(EButtonType).Button,
            primary: true,
            size: _unref(EButtonSize).Small
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.housing.erasures.stop')), 1)
            ]),
            _: 1
          }, 8, ["type", "size"]),
          (showErasureMessage.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('profile.housing.erasures_stopped')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "return-action"]))
}
}

})