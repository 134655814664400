/* eslint-disable @typescript-eslint/ban-ts-comment */
import {createI18n} from 'vue-i18n';

import {ELocale} from './store/modules/Intl';

import API from '@/helpers/api';
import Cookie from './helpers/cookie';

import {ECookieNames} from "@/helpers/cookie/interfaces";
import dayjs from "dayjs";

const i18n = createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
    globalInjection: true,
    silentFallbackWarn: process.env.NODE_ENV !== 'production',
    formatFallbackMessages: true
});

export const setI18nLanguage = (lang: string, translations: any | null = null) => {
    const isLegacy = i18n.mode === 'legacy';
    const isEkwLocale = Cookie.get(ECookieNames.EKWATEUR_LOCALE) === 'true';
    const isProLocale = Cookie.get(ECookieNames.PRO_LOCALE) === 'true';

    let _lang = lang;

    if (isEkwLocale || isProLocale) {
        _lang = ELocale.fr;
    }

    if (isLegacy) {
        // @ts-ignore
        i18n.global.locale = _lang;
    } else {
        i18n.global.locale.value = _lang;
    }
    API.defaults.headers.common['Accept-Language'] = _lang;
    document.querySelector('html')!.setAttribute('lang', _lang);

    if (translations !== null) {
        i18n.global.setLocaleMessage(_lang, translations);
    }

    Cookie.set(ECookieNames.LOCALE, _lang);
    dayjs.locale(_lang)
};

export default i18n;
