import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { class: "popinForm__sliderWrapper" }
const _hoisted_3 = { class: "edit-range-modes-wrapper" }
const _hoisted_4 = { class: "edit-range-modes-description" }
const _hoisted_5 = { class: "popinForm__footer" }

import {computed, onBeforeMount, reactive} from "vue";
import {useI18n} from "vue-i18n";
import {g_firstDayTime, g_lastDayTime} from "@/helpers/domains/dailyprogs";

import RangeSlider from "@/components/domains/Heating/MyVEditRange/MyVRangeSlider/MyVRangeSlider.vue";

import {g_modeOff, TEditRange} from "./interfaces";
import {EApplianceMode} from "@/services/Appliances/interfaces";

import {TabHeatingModeFakeDatas} from "@/dummies/TabHeatingModeFakeDatas";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVTemperatureControl from "@/components/domains/Heating/MyVTemperatureControl/MyVTemperatureControl.vue";
import MyVTabHeatingMode from "@/components/domains/Heating/MyVTabHeatingMode/MyVTabHeatingMode.vue";

interface Props {
  onSubmit: TEditRange["onSubmit"];
  returnAction?: TEditRange["returnAction"];
  day: TEditRange["day"];
  start_hour?: TEditRange["start_hour"];
  end_hour?: TEditRange["end_hour"];
  mode: TEditRange["mode"];
  temperature: TEditRange["temperature"];
  isOn: TEditRange["isOn"];
  availableModes: TEditRange["availableModes"];

}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVEditRange',
  props: {
    onSubmit: { type: Function, default: undefined },
    returnAction: { default: undefined },
    day: { default: undefined },
    start_hour: { default: g_firstDayTime },
    end_hour: { default: g_lastDayTime },
    mode: { default: EApplianceMode.Comfort },
    temperature: { default: undefined },
    isOn: { default: undefined },
    availableModes: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const form = reactive({
  start_day: 0,
  start_hour: '',
  end_day: 0,
  end_hour: '',
  mode: EApplianceMode.Comfort,
  temperature: 19
});
const {t} = useI18n()

const optionsMode = computed(() => {
  return [
    ...TabHeatingModeFakeDatas,
    {
      icon: 'x-circle',
      title: 'mode.off',
      slug: g_modeOff as EApplianceMode
    }
  ]
      .filter(({slug}) => (
              slug === (g_modeOff as EApplianceMode)) ||
          props.availableModes.includes(slug as EApplianceMode)
      )
      .map(({
              icon,
              title,
              slug,
            }) => ({
        icon,
        label: t(title),
        slug,
      }));
})

const onFinish = () => {
  const offMode = props.availableModes.includes(EApplianceMode.Normal) ? EApplianceMode.Normal : EApplianceMode.Comfort
  const isModeOff = (form.mode === g_modeOff);

  const payloadForm = {
    ...form,
    mode: isModeOff ? offMode : form.mode,
    isOn: !isModeOff
  }

  props.onSubmit(payloadForm);
}

const changeMode = (applianceMode: EApplianceMode) => {
  form.mode = applianceMode;
}

onBeforeMount(() => {
  form.start_day = form.end_day = props.day;
  form.start_hour = props.start_hour;
  form.end_hour = props.end_hour;
  form.mode = props.isOn ? props.mode : (g_modeOff as EApplianceMode);
  form.temperature = props.temperature;
})


return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    model: form,
    onFinish: onFinish,
    class: "popinForm edit-range"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(RangeSlider, {
            dots: "",
            max: 1440,
            min: 0,
            start: form.start_hour,
            end: form.end_hour,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (form.start_hour = $event[0], form.end_hour= $event[1]))
          }, null, 8, ["start", "end"])
        ]),
        _createVNode(_component_a_divider),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ProgramPlanningRangeForm.select_mode')), 1),
          _createVNode(MyVTabHeatingMode, {
            listItem: optionsMode.value,
            "active-tab": form.mode,
            action: changeMode
          }, null, 8, ["listItem", "active-tab"]),
          (form.mode === _unref(EApplianceMode).Temp)
            ? (_openBlock(), _createBlock(MyVTemperatureControl, {
                key: 0,
                modelValue: form.temperature,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.temperature) = $event)),
                max: 30,
                min: 7
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        (typeof(_ctx.returnAction)=='function')
          ? (_openBlock(), _createBlock(MyVButton, {
              key: 0,
              onClick: _ctx.returnAction,
              variant: "secondary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : (_ctx.returnAction)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: _ctx.returnAction,
                class: "popinForm__footer-back"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
        _createVNode(MyVButton, {
          disabled: form.start_hour === '' || form.end_hour === '',
          type: _unref(EButtonType).Submit
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('form.apply_planning_range.submit')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "type"])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})