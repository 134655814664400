import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {reactive} from "vue";

import {TLoginForm} from "./interfaces";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVLoginForm',
  props: {
    onSubmit: { type: Function }
  },
  setup(__props: any) {

const props = __props

const form = reactive({
  username: '',
  password: '',
})

const onFinish = () => {
  if (form.username === '' || form.password === '') return false;
  props.onSubmit(form);
}

return (_ctx: any,_cache: any) => {
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    "data-testid": "login-form",
    model: form,
    onFinish: onFinish,
    autocomplete: "on",
    action: "#"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVAntdinput, {
        "data-test-id": "login-form-username-input",
        label: _ctx.$t('form.username'),
        inputType: _unref(EInputType).Text,
        value: form.username,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.username) = $event)),
        id: "username",
        inputRequired: true
      }, null, 8, ["label", "inputType", "value"]),
      _createVNode(MyVAntdinput, {
        "data-test-id": "login-form-password-input",
        label: _ctx.$t('form.password'),
        inputType: _unref(EInputType).Password,
        value: form.password,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.password) = $event)),
        id: "password",
        inputRequired: true
      }, null, 8, ["label", "inputType", "value"]),
      _createVNode(MyVButton, {
        "data-testid": "login-form-submit-button",
        class: "login-form-submit-button",
        type: _unref(EButtonType).Submit
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('button.login')), 1)
        ]),
        _: 1
      }, 8, ["type"])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})