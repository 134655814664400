import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "device-status"
}
const _hoisted_2 = {
  key: 0,
  class: "device-status-icon"
}
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = {
  key: 1,
  class: "device-status-title"
}
const _hoisted_5 = {
  key: 2,
  class: "device-status-card"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 3,
  class: "device-status-card-bullets"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 5,
  class: "device-status-buttons"
}
const _hoisted_11 = {
  key: 6,
  class: "device-status-help"
}

import {EDiagnosticStatus, TDiagnostic} from "@/services/Diagnostic/interfaces";
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from "vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {AuthModule, DiagnosticModule, IntlModule} from "@/store";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {useI18n} from "vue-i18n";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {useRouter} from "vue-router";
import {EDiagnosticAnswers, TResolutionStep} from "@/components/domains/Device/MyVDeviceStatus/interfaces";
import ERoutes from "@/router/routes";
import {EButtonSize} from "@/components/ui/atoms/MyVButton/interfaces";
import {useDate} from "@/helpers/dates/date-utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDeviceStatus',
  props: {
    diagnostic: {}
  },
  setup(__props: any) {

const currentStep = ref<TResolutionStep>()
const currentStepId = ref()

const props = __props

const {diagnostic} = toRefs(props)

const {t} = useI18n()
const router = useRouter()
const interval = ref()
const show = ref(true)
const animatedBullets = ref<string[]>([])
const allowedContactDiagnostics = [EDiagnosticStatus.NO_CONSUMPTION, EDiagnosticStatus.PILOTING_ERROR, EDiagnosticStatus.NOK_UNREACHABLE, EDiagnosticStatus.NOK_UNREACHABLE_MOMBOX_OFF_SITE, EDiagnosticStatus.COMM_ERROR]
const user = computed(() => {
  return AuthModule.user;
})

const loadAnimation = (link: string) => {
  if (link)
    return require(`@/assets/videos/${link}.mp4`);
}

const commonButtons = computed(() => {
  return {
    done: {
      label: t('global.done'),
      action: () => currentStepId.value++
    },
    contact: {
      label: t('global.contactUs'),
      action: () => router.push({
        name: 'Contact',
        query: {prefilledMessage: prefilledMessage.value, origin: ERoutes.DIAGNOSTIC}
      })
    },
    yes: {
      label: t('confirm'),
      action: () => answerQuestion(EDiagnosticAnswers.YES)
    },
    no: {
      label: t('global.no'),
      action: () => answerQuestion(EDiagnosticAnswers.NO),
      variant: 'secondary'
    },
    test: {
      label: t('diagnostic.deviceStatus.startTest'),
      action: () => startDiagnosticSelfTest()
    },
    refreshAndTest: {
      label: t('diagnostic.deviceStatus.startRefreshTest'),
      action: () => startDiagnosticSelfTest(),
      isRefresh: true,
      variant: 'secondary'
    },
    fix: {
      label: t('diagnostic.deviceStatus.fix'),
      action: () => currentStepId.value++
    },
    softFix: {
      label: t('diagnostic.deviceStatus.softFix'),
      action: () => currentStepId.value++
    }
  }
})

const isDiagnosticKo = computed(() => {
  return diagnostic.value.status === EDiagnosticStatus.PILOTING_ERROR || diagnostic.value.status === EDiagnosticStatus.COMM_ERROR || diagnostic.value.status === EDiagnosticStatus.NO_CONSUMPTION;
})

const diagnosticsInProgress = computed(() => {
  return DiagnosticModule.diagnosticsInProgress
})

const bullets = computed(() => {
  return t('diagnostic.deviceStatus.testInProgress.resolutionSteps.1.waitingSentences')?.trim().split("<li>").slice(1)
})

const resolutionSteps = computed((): TResolutionStep[] => {
  switch (diagnostic.value.status) {
    case EDiagnosticStatus.OK:
      return [
        {
          id: 1,
          title: t('diagnostic.deviceStatus.ok.resolutionSteps.1.title'),
          text: t('diagnostic.deviceStatus.ok.resolutionSteps.1.text'),
          icon: 'check-circle',
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.test] : undefined
        }
      ]
    case EDiagnosticStatus.TEST_IN_PROGRESS:
      return [
        {
          id: 1,
          title: t('diagnostic.deviceStatus.testInProgress.resolutionSteps.1.title'),
          text: animatedBullets.value,
          icon: 'spinner'
        }
      ]
    case EDiagnosticStatus.NOK:
      return [
        {
          id: 1,
          title: t('diagnostic.deviceStatus.koError.resolutionSteps.1.title'),
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.koError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.koError.resolutionSteps.1.text.noTest'),
          icon: 'exclamation-circle',
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.fix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.koError.resolutionSteps.2.text'),
          animation: loadAnimation('A1'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.koError.resolutionSteps.3.text'),
          animation: loadAnimation('A2'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.koError.resolutionSteps.4.text'),
          buttons: [commonButtons.value.test]
        }
      ]
    case EDiagnosticStatus.PILOTING_ERROR:
      return [
        {
          id: 1,
          title: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.1.title'),
          icon: 'exclamation-circle',
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.pilotingError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.pilotingError.resolutionSteps.1.text.noTest'),
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.fix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.2.text'),
          animation: loadAnimation('A3'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.3.text'),
          animation: loadAnimation('A4'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.4.text'),
          animation: loadAnimation('A4'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 5,
          title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
          text: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.5.text'),
          animation: loadAnimation('A6'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 6,
          title: t('diagnostic.deviceStatus.resolutionSteps.step5.title'),
          text: t('diagnostic.deviceStatus.pilotingError.resolutionSteps.6.text'),
          buttons: [commonButtons.value.test]
        }
      ]
    case EDiagnosticStatus.COMM_ERROR:
      return [
        {
          id: 1,
          icon: 'exclamation-circle',
          title: t('diagnostic.deviceStatus.communicationError.resolutionSteps.1.title'),
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.communicationError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.communicationError.resolutionSteps.1.text.noTest'),
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.fix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.communicationError.resolutionSteps.2.text'),
          animation: loadAnimation('A7'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.communicationError.resolutionSteps.3.text'),
          animation: loadAnimation('A8'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.communicationError.resolutionSteps.4.text'),
          animation: loadAnimation('A9'),
          buttons: [commonButtons.value.yes, commonButtons.value.no],
          children: [
            {
              id: 5,
              answerType: EDiagnosticAnswers.NO,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.communicationError.resolutionSteps.4.1.text'),
              animation: loadAnimation('A10'),
              buttons: [commonButtons.value.contact]
            },
            {
              id: 5,
              answerType: EDiagnosticAnswers.YES,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.communicationError.resolutionSteps.4.2.text'),
              buttons: [commonButtons.value.test]
            },
          ]
        }
      ]
    case EDiagnosticStatus.NO_CONSUMPTION:
      return [
        {
          id: 1,
          icon: 'question-circle',
          title: t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.1.title'),
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.1.text.noTest'),
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.softFix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.2.text'),
          animation: loadAnimation('A3'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.3.text'),
          animation: loadAnimation('A4'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.4.text'),
          animation: loadAnimation('A5'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 5,
          title: t('diagnostic.deviceStatus.resolutionSteps.step5.title'),
          text: t('diagnostic.deviceStatus.noConsumptionError.resolutionSteps.5.text'),
          buttons: [commonButtons.value.test]
        }
      ]
    case EDiagnosticStatus.NOK_UNREACHABLE_MOMBOX_OFF_SITE:
      return [
        {
          id: 1,
          icon: 'exclamation-circle',
          title: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.1.title'),
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.1.text.noTest'),
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.fix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.2.text'),
          animation: loadAnimation('A7'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.3.text'),
          animation: loadAnimation('A8'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.4.text'),
          animation: loadAnimation('A9'),
          buttons: [commonButtons.value.yes, commonButtons.value.no],
          children: [
            {
              id: 5,
              answerType: EDiagnosticAnswers.NO,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.5.1.text'),
              animation: loadAnimation('A10'),
              buttons: [commonButtons.value.contact]
            },
            {
              id: 5,
              answerType: EDiagnosticAnswers.YES,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.unreachableOffSiteError.resolutionSteps.5.2.text'),
              buttons: [commonButtons.value.test]
            }
          ]
        }
      ]
    case EDiagnosticStatus.NOK_UNREACHABLE:
      return [
        {
          id: 1,
          icon: 'exclamation-circle',
          title: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.1.title'),
          text: diagnostic.value.diagTestEnabled ? t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.1.text') : t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.1.text.noTest'),
          buttons: diagnostic.value.diagTestEnabled ? [commonButtons.value.fix, commonButtons.value.refreshAndTest] : [commonButtons.value.contact]
        },
        {
          id: 2,
          title: t('diagnostic.deviceStatus.resolutionSteps.step1.title'),
          text: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.2.text'),
          animation: loadAnimation('A11'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 3,
          title: t('diagnostic.deviceStatus.resolutionSteps.step2.title'),
          text: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.3.text'),
          animation: loadAnimation('A12'),
          buttons: [commonButtons.value.done]
        },
        {
          id: 4,
          title: t('diagnostic.deviceStatus.resolutionSteps.step3.title'),
          text: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.4.text'),
          animation: loadAnimation('A13'),
          buttons: [commonButtons.value.yes, commonButtons.value.no],
          children: [
            {
              id: 6,
              answerType: EDiagnosticAnswers.NO,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.4.1.text'),
              animation: loadAnimation('A14'),
              buttons: [commonButtons.value.contact]
            },
            {
              id: 6,
              answerType: EDiagnosticAnswers.YES,
              title: t('diagnostic.deviceStatus.resolutionSteps.step4.title'),
              text: t('diagnostic.deviceStatus.unreachableOnSiteError.resolutionSteps.4.2.text'),
              buttons: [commonButtons.value.test]
            }
          ]
        }
      ]
    default:
      return []
  }
})

const prefilledMessage = computed(() => {
  return `${t(resolutionSteps.value[0].title!)} - ${diagnostic.value.name} \n${t('diagnostic.deviceStatus.prefilledMessage')}:`
})

const locale = computed(() => {
  return IntlModule.locale
})

const startDiagnosticSelfTest = () => {
  show.value = false;

  if (!props.diagnostic) return;

  const diagToUpdate = DiagnosticModule.diagnostics.find(diag => diag.csApplianceId === props.diagnostic.csApplianceId)
  diagToUpdate!.status = EDiagnosticStatus.TEST_IN_PROGRESS

  const siteId = getCurrentSiteIdFromCookie(user.value);
  const csModulatorId = diagnostic.value.csModulatorId;

  DiagnosticModule.startSelfDiagnostic({siteId, csModulatorId})
      .then(() => DiagnosticModule.getDiagnostics({siteId, forceRequest: true, skipLoading: false}))
      .finally(() => show.value = true)
      .finally(() => {
        currentStep.value = resolutionSteps.value.find(step => step.id === 1);
        currentStepId.value = currentStep.value?.id;
      });
};

onMounted(() => {
  currentStep.value = resolutionSteps.value.find(step => step.id === 1)
  currentStepId.value = currentStep.value?.id
})

onUnmounted(() => {
  clearInterval(interval.value)
})

const answerQuestion = (answer: string) => {
  if (answer === EDiagnosticAnswers.YES)
    currentStep.value = currentStep.value?.children?.find(step => step.answerType === EDiagnosticAnswers.YES)

  if (answer === EDiagnosticAnswers.NO)
    currentStep.value = currentStep.value?.children?.find(step => step.answerType === EDiagnosticAnswers.NO)
}

watch(currentStepId, (newValue) => {
  currentStep.value = resolutionSteps.value.find(step => step.id === newValue)
})

watch(diagnostic, (newValue, oldValue) => {
  if (newValue.status !== oldValue.status)
    currentStep.value = resolutionSteps.value[0]
})

watch(diagnosticsInProgress.value, (newValue) => {
  const currentDiag = newValue.find(diag => props.diagnostic.csApplianceId === diag.csApplianceId)

  if (currentDiag && currentDiag?.startDate) {
    const secondsUntilNow = useDate(currentDiag.currentDate).diff(currentDiag.startDate, 'second')

    switch (true) {
      case secondsUntilNow < 30:
        animatedBullets.value = bullets.value?.slice(0, 1);
        break;
      case secondsUntilNow < 60:
        animatedBullets.value = bullets.value?.slice(0, 2);
        break;
      case secondsUntilNow < 100:
        animatedBullets.value = bullets.value?.slice(0, 3);
        break;
      default:
        animatedBullets.value = bullets.value?.slice(0, 4);
        break;
    }
  }

}, {immediate: true})

watch(locale, () => {
  currentStep.value = resolutionSteps.value.find(step => step.id === currentStep.value?.id)
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (currentStep.value && show.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (currentStep.value.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass(['icon', `icon-${currentStep.value.icon}`]),
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", {
                  "xlink:href": ['#icon-' + currentStep.value.icon]
                }, null, 8, _hoisted_3)
              ], 2))
            ]))
          : _createCommentVNode("", true),
        ( currentStep.value.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(currentStep.value.title), 1))
          : _createCommentVNode("", true),
        (typeof currentStep.value.text === 'string')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", {
                innerHTML: currentStep.value.text
              }, null, 8, _hoisted_6),
              (currentStep.value.id === 1 && allowedContactDiagnostics.includes(_unref(diagnostic).status))
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.$t('diagnostic.deviceStatus.contact')) + " ", 1),
                    _createVNode(_component_router_link, {
                      to: {name: 'Contact',query: {prefilledMessage: prefilledMessage.value,origin: _unref(ERoutes).DIAGNOSTIC}}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.support')), 1)
                      ]),
                      _: 1
                    }, 8, ["to"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (Array.isArray(currentStep.value.text))
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(animatedBullets.value, (text, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createVNode(MyVIcon, { name: "checkmark" }),
                  _createTextVNode(" " + _toDisplayString(text), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (currentStep.value.animation)
          ? (_openBlock(), _createElementBlock("video", {
              key: 4,
              src: currentStep.value.animation,
              autoplay: "",
              controls: "",
              muted: "",
              playsinline: ""
            }, null, 8, _hoisted_9))
          : _createCommentVNode("", true),
        (currentStep.value.buttons)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentStep.value.buttons, (button, index) => {
                return (_openBlock(), _createBlock(MyVButton, {
                  round: !button.isRefresh,
                  icon: button.isRefresh ? 'loop2' : undefined,
                  size: button.isRefresh ? _unref(EButtonSize).Slim : _unref(EButtonSize).Large,
                  key: index,
                  variant: button.variant,
                  onClick: button.action
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(button.label), 1)
                  ]),
                  _: 2
                }, 1032, ["round", "icon", "size", "variant", "onClick"]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (isDiagnosticKo.value &&  currentStep.value.id === resolutionSteps.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createTextVNode(_toDisplayString(_ctx.$t('diagnostic.deviceStatus.help')) + " ", 1),
              _createVNode(_component_router_link, {
                to: {name: 'Contact',query: {prefilledMessage: prefilledMessage.value,origin: _unref(ERoutes).DIAGNOSTIC}}
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('global.memberService')), 1)
                ]),
                _: 1
              }, 8, ["to"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})