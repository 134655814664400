import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment } from "vue"

import {TBarGraphic} from "./interfaces";
import {computed} from "vue";
import {Bar} from "vue-chartjs";

interface Props {
  labels: TBarGraphic["labels"];
  datas: TBarGraphic["datas"];
  leftAxis: TBarGraphic["leftAxis"];
  color: TBarGraphic["color"];
  minBarLength?: TBarGraphic["minBarLength"];
  labelFilter?: TBarGraphic["labelFilter"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBarGraphic',
  props: {
    labels: { default: undefined },
    datas: { default: undefined },
    leftAxis: { default: undefined },
    color: { default: undefined },
    minBarLength: { default: undefined },
    labelFilter: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const rgbColor = computed(() => {
  const { red, green, blue } = props.color;
  return `${red}, ${green}, ${blue}`;
});

const options = computed(() => {
  const labelFilter = props.labelFilter !== undefined ? props.labelFilter : () => true;
  const labels = props.labels;

  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 24,
      },
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          labelColor: () => ({backgroundColor: `rgb(${rgbColor.value})`}),
        },
      }
    },
    scales: {
      x: {
        ticks: {
          callback: function (value: number) {
            const label: string = (this as any) ? (this as any).getLabelForValue(value) : undefined;

            return labelFilter(label, value, labels) ? label : '';
          },
        },
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        min: props.leftAxis.min,
        max: props.leftAxis.max,
        ticks: {
          stepSize: props.leftAxis.step || 1
        },
        grid: {
          display: true,
          borderWidth: false,
          borderDash: [8, 4]
        },
      },
    },
  }
})
const data = computed(() => {
  const labels = props.labels;

  const datasets = [{
    type: "bar",
    barPercentage: 1,
    categoryPercentage: 1,
    data: props.datas,
    minBarLength: props.minBarLength,
    borderColor: `rgb(${rgbColor.value})`,
    borderWidth: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    backgroundColor: (args: any) => (args.dataIndex === (labels.length - 1)) ? `rgb(${rgbColor.value})` : `rgba(${rgbColor.value}, 0.4)`,
    hoverBackgroundColor: (args: any) => (args.dataIndex === (labels.length - 1)) ? `rgb(${rgbColor.value})` : `rgba(${rgbColor.value}, 0.4)`,
    order: 1,
    datalabels: {
      display: false,
    },
  }];

  return {
    datasets,
    labels: props.labels,
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.leftAxis)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.axisTitles)
        }, _toDisplayString(_ctx.leftAxis.title), 3))
      : _createCommentVNode("", true),
    _createVNode(_unref(Bar), {
      data: data.value,
      options: options.value
    }, null, 8, ["data", "options"])
  ], 64))
}
}

})