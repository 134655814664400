import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "popinForm__main" }
const _hoisted_2 = { class: "Security__passwordSection" }
const _hoisted_3 = { class: "Security__passwordSectionContainer inner" }
const _hoisted_4 = { class: "popinForm__footer" }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {TSecurityForm} from "@/views/domains/Profile/Security/interfaces";
import {AuthModule} from "@/store";
import ErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import {containsSpecialChars, hasUpper} from "@/helpers/string/password";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import MyVPasswordHelper from "@/components/ui/organisms/MyVPasswordHelper/MyVPasswordHelper.vue";
import {notification} from "ant-design-vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'SecurityView',
  setup(__props) {

const passwordIsValid = ref(false);
const confirmPasswordIsValid = ref(true);
const passwordHasUpper = ref(false);
const passwordHasHeightCharacters = ref(false);
const passwordHasSpecialCharacter = ref(false);
const error = ref<string | null>(null)
const securityForm = ref(null)
const form = reactive<TSecurityForm>({
  password: '',
  newPassword: '',
  confirm: ''
})

const {t} = useI18n()

const save = async () => {
  if (form.password.length && passwordIsValid.value && confirmPasswordIsValid.value) {
    await AuthModule.changePassword({
      password: form.password,
      newPassword: form.newPassword,
      invalidateTokens: false
    }).then(() => {
      (securityForm.value as any)?.reset()
      notification.success({
        message: t('notification.change_password.success.title'),
        duration: 3
      })
    }).catch(e => {
      error.value = e.response ? e.response.data.message : 'Erreur serveur'
    });
  }
}

const updateConfirmPasswordIsValid = (password: string, confirmPassword: string) => {
  confirmPasswordIsValid.value = confirmPassword === password && confirmPassword.length > 0;
}

watch(() => form.newPassword, (newValue) => {
  passwordHasHeightCharacters.value = newValue.length >= 8
  passwordHasUpper.value = hasUpper(newValue)
  passwordHasSpecialCharacter.value = containsSpecialChars(newValue)
  passwordIsValid.value = passwordHasHeightCharacters.value && passwordHasUpper.value && passwordHasSpecialCharacter.value;
})


watch(() => form.confirm, (newValue) => {
  updateConfirmPasswordIsValid(form.newPassword, newValue)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    title: _ctx.$t('profile.security.title'),
    type: "page",
    "return-action": _unref(ERoutes).HOME,
    class: "Security"
  }, {
    default: _withCtx(() => [
      (error.value)
        ? (_openBlock(), _createBlock(ErrorBanner, {
            key: 0,
            error: error.value,
            class: "error-banner",
            onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null))
          }, null, 8, ["error"]))
        : _createCommentVNode("", true),
      _createElementVNode("form", {
        onSubmit: _withModifiers(save, ["prevent"]),
        class: "Security__passwordSection__form popinForm",
        ref_key: "securityForm",
        ref: securityForm
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('profile.security.edit_password')), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(MyVAntdinput, {
                id: "password",
                inputType: _unref(EInputType).Password,
                label: _ctx.$t('profile.security.old_password'),
                "auto-complete": "current-password",
                value: form.password,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.password) = $event))
              }, null, 8, ["inputType", "label", "value"]),
              _createVNode(MyVAntdinput, {
                id: "newPassword",
                inputType: _unref(EInputType).Password,
                label: _ctx.$t('profile.security.new_password'),
                "auto-complete": "new-password",
                value: form.newPassword,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.newPassword) = $event))
              }, null, 8, ["inputType", "label", "value"]),
              _createVNode(MyVPasswordHelper, {
                "password-has-height-caracters": passwordHasHeightCharacters.value,
                "password-has-special-caracter": passwordHasSpecialCharacter.value,
                "password-has-upper": passwordHasUpper.value
              }, null, 8, ["password-has-height-caracters", "password-has-special-caracter", "password-has-upper"]),
              _createVNode(MyVAntdinput, {
                id: "confirm",
                class: "Security__passwordSection__form__confirm",
                inputType: _unref(EInputType).Password,
                label: _ctx.$t('profile.security.confirm_password'),
                "auto-complete": "new-password",
                value: form.confirm,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.confirm) = $event))
              }, null, 8, ["inputType", "label", "value"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(MyVButton, {
            type: "submit",
            class: "Security__passwordSection__form__save",
            disabled: !(form.password.length && passwordIsValid.value && confirmPasswordIsValid.value && form.confirm.length)
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.security.submit')), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ], 544)
    ]),
    _: 1
  }, 8, ["title", "return-action"]))
}
}

})