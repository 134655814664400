import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "myv-icon-mode-temperature-text" }
const _hoisted_2 = { class: "temperature" }

import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {TIconModeTemperature} from "@/components/ui/atoms/MyVIconModeTemperature/interfaces";

interface Props {
  temperature: TIconModeTemperature["temperature"];
  size?:  TIconModeTemperature["size"]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVIconModeTemperature',
  props: {
    temperature: { default: undefined },
    size: { default: "medium" }
  },
  setup(__props: any) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['myv-icon-mode-temperature', `myv-icon-mode-temperature__${props.size}`])
  }, [
    _createVNode(MyVIcon, { name: "thermometer-03" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createTextVNode(_toDisplayString(props.temperature), 1),
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "celsius" }, "C", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "unit" }, "°", -1))
      ])
    ])
  ], 2))
}
}

})