import {TProgramCreateHabitHoursDatas} from "@/components/domains/Heating/programs/create/MyVProgramCreateHabitHours/interfaces";
import {TProgramCreateWeekPresenceDatas} from "@/components/domains/Heating/programs/create/MyVProgramCreateWeekPresence/interfaces";
import {TProgramCreateZoneAppliancesDatas} from "@/components/domains/Device/MyVProgramCreateZoneAppliances/interfaces";
import {TProgram, TProgramFromHabit} from "@/services/Program/interfaces";

export enum EProgramCreateMethod {
    /**
     * Program is created from habit.
     */
    Habit = 'HABIT',

    /**
     * Program is created from duplication.
     */
    Duplicate = 'DUPLICATE',

    /**
     * Program is created from zero.
     */
    Zero = 'ZERO',
}

export type TProgramCreateFormProps = {
    /**
     * Callback allowing sub page to roll back to first page.
     */
    cancelCreate: () => void;

    /**
     * Base form given by the creation selection method page.
     */
    baseForm: TProgramCreateBase;

    /**
     * Function to generate program link to redirect when creation is done.
     */
    generateEditLink: (id: TProgram['id']) => string;
}

/**
 * Forms
 */

export type TProgramCreateBase = {
    /**
     * New program's name.
     */
    name: TProgramFromHabit['name'];
};

export type TProgramCreateFromHabit = {
    /**
     * Program creation from habit first step.
     * Retrieves datas about usual weekly awaken/sleeping period.
     */
    createHabitHours?: TProgramCreateHabitHoursDatas;

    /**
     * Program creation from habit second step.
     * Retrieves datas about usual weekly presence period habit.
     */
    createWeekPresence?: TProgramCreateWeekPresenceDatas;

    /**
     * Program creation from habit third step.
     * Retrieves datas about usual weekly appliances usage period.
     */
    createZoneAppliances?: TProgramCreateZoneAppliancesDatas;
}

export type TProgramCreateDuplicate = {
    /**
     * New program's name.
     */
    id?: TProgram['id'];
};