import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment } from "vue"

import {Chart} from 'chart.js';
import {TBarRangeColorGraphic} from "./interfaces";
import {computed} from "vue";
import {Bar} from "vue-chartjs";

type ChartDatasets = Chart<'bar'>['data']['datasets'];

interface Props {
  labels: TBarRangeColorGraphic["labels"];
  peakDatas: TBarRangeColorGraphic["datas"];
  offpeakDatas: TBarRangeColorGraphic["datas"];
  leftAxis: TBarRangeColorGraphic["leftAxis"];
  columnBackgroundColor: TBarRangeColorGraphic["columnBackgroundColor"];
  labelFilter?: TBarRangeColorGraphic["labelFilter"];
  ranges: TBarRangeColorGraphic["ranges"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVBarRangeColorGraphic',
  props: {
    labels: { default: undefined },
    peakDatas: { default: undefined },
    offpeakDatas: { default: undefined },
    leftAxis: { default: undefined },
    columnBackgroundColor: { default: undefined },
    labelFilter: { type: Function, default: undefined },
    ranges: { default: undefined }
  },
  setup(__props: any) {

const props = __props

const data = computed(() => {
  const legendHidden = ['Total'];

  const datasets = [{
    type: "bar",
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    data: props.peakDatas,
    backgroundColor: "rgb(122, 221, 235)",
    order: 1,
    datalabels: {
      display: false,
    },
  }, {
    type: "bar",
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    data: props.offpeakDatas,
    backgroundColor: "rgb(73, 233, 159)",
    order: 1,
    datalabels: {
      display: false,
    },
  }] as ChartDatasets;

  datasets.push({
    label: legendHidden[0],
    data: props.labels.map(() => props.leftAxis.max - ((props.leftAxis.step || 1) / 20)),
    backgroundColor: props.columnBackgroundColor,
    hoverBackgroundColor: props.columnBackgroundColor,
    maxBarThickness: 10,
    barPercentage: 1,
    categoryPercentage: 1,
    borderRadius: 24,
    order: 2,
    datalabels: {
      display: false,
    },
  });
  return {
    datasets,
    labels: props.labels,
  }
})

const options = computed(() => {
  const legendHidden = ['Total'];
  const labelFilter = props.labelFilter !== undefined ? props.labelFilter : () => true;

  const ranges = props.ranges
  ranges.sort((ra, rb) => ra.start - rb.start);

  return {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 24,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
        reverse: true,
        labels: {
          boxWidth: 8,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          filter: (item: any): boolean => {
            return legendHidden.indexOf(item.text) === -1;
          },
          generateLabels: () => {
            const labels: any[] = [];

            props.ranges.forEach((range, index) => {
              if (labels.findIndex(label => label.text === range.title) === -1) {
                labels.push({
                  text: range.title,
                  fillStyle: range.color,
                  boxWidth: 8,
                  datasetIndex: index,
                  fontColor: "#7c7c7c",
                  lineWidth: 0,
                  pointStyle: 'rectRounded',
                  usePointStyle: true,
                });
              }
            });
            return labels;
          }
        },
        onClick: () => ''
      },
      tooltip: {
        mode: 'index',
        intersect: true,
        filter: function (ctx: any) {
          return ctx.raw
        },
        callbacks: {
          label: function (ctx: any) {
            if (ctx.raw)
              return `${props.ranges.slice().reverse()[ctx.datasetIndex].title} : ${ctx.raw.toFixed(2)} ${props.leftAxis.title}`
          },
          title: (context: any) => {
            return context[0]?.label
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          callback: function (value: number) {
            const label: string = (this as any).getLabelForValue(value);

            return labelFilter(label) ? label : '';
          },
        },
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        min: props.leftAxis.min,
        max: props.leftAxis.max,
        ticks: {
          stepSize: props.leftAxis.step || 1
        },
        grid: {
          display: true,
          borderWidth: false,
          borderDash: [8, 4]
        },
        stacked: true
      },
    },
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.leftAxis)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.axisTitles)
        }, _toDisplayString(_ctx.leftAxis.title), 3))
      : _createCommentVNode("", true),
    _createVNode(_unref(Bar), {
      data: data.value,
      options: options.value
    }, null, 8, ["data", "options"])
  ], 64))
}
}

})