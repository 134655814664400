import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

import {reactive, ref, watch} from "vue";

import {TForgetPasswordForm} from "./interfaces";
import {EValidationState} from "@/helpers/constants/interfaces";
import {validateEmail} from "@/helpers/string/email";
import {useI18n} from "vue-i18n";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";

interface Props {
  formId?: TForgetPasswordForm["formId"];
  onSubmit: TForgetPasswordForm["onSubmit"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVForgetPasswordForm',
  props: {
    formId: { default: undefined },
    onSubmit: { type: Function, default: undefined }
  },
  setup(__props: any) {

const props = __props

const {t} = useI18n()
const form = reactive({
  email: ''
})

const isEmailValid = ref<EValidationState>(EValidationState.EMPTY)
const emailError = ref<string | undefined>(undefined)

const onFinish = () => {
  if (isEmailValid.value === EValidationState.SUCCESS) {
    props.onSubmit(form);
  } else {
    emailError.value = t('form.email_security');
  }
}

watch(() => form.email, (newValue) => {
  if (!newValue || newValue.length === 0) {
    isEmailValid.value = EValidationState.EMPTY
  } else {
    isEmailValid.value = validateEmail(newValue) ? EValidationState.SUCCESS : EValidationState.ERROR
  }
})

watch(isEmailValid, (newValue) => {
  if (newValue === EValidationState.SUCCESS || newValue === EValidationState.EMPTY) {
    emailError.value = undefined;
  }
})

return (_ctx: any,_cache: any) => {
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    id: _ctx.formId,
    model: form,
    onFinish: onFinish,
    "data-test-id": "forgot-password-form"
  }, {
    default: _withCtx(() => [
      _createVNode(MyVAntdinput, {
        "data-test-id": "forgot-password-form-email",
        label: _ctx.$t('form.email'),
        inputType: _unref(EInputType).Text,
        help: emailError.value,
        error: emailError.value,
        value: form.email,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.email) = $event)),
        id: "email"
      }, null, 8, ["label", "inputType", "help", "error", "value"]),
      (_ctx.formId === undefined)
        ? (_openBlock(), _createBlock(MyVButton, {
            key: 0,
            "data-test-id": "forgot-password-form-submit-button",
            type: _unref(EButtonType).Submit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('button.forget_password_form')), 1)
            ]),
            _: 1
          }, 8, ["type"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["id", "model"]))
}
}

})