import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "AppliancesTile__top" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "AppliancesTile__level" }
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__iconContainer'])
}
const _hoisted_5 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(['AppliancesTile__modeName'])
}
const _hoisted_6 = {
  key: 1,
  class: "AppliancesTile__modeName"
}
const _hoisted_7 = {
  key: 2,
  class: "AppliancesTile__modeName"
}
const _hoisted_8 = { class: "AppliancesTile__prog" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }

import {EApplianceMode, EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";
import {heatingType} from "@/helpers/domains/heatingType";
import {TAppliancesTile} from "@/components/domains/Heating/appliances/MyVAppliancesTile/interfaces";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {DiagnosticModule} from "@/store";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {EDiagnosticStatus} from "@/services/Diagnostic/interfaces";
import MyVIconMode from "@/components/ui/atoms/MyVIconMode/MyVIconMode.vue";
import MyVIconModeTemperature from "@/components/ui/atoms/MyVIconModeTemperature/MyVIconModeTemperature.vue";

export interface Props {
  name: TAppliancesTile["name"];
  mode: TAppliancesTile["mode"];
  temp?: TAppliancesTile["temp"];
  prog: TAppliancesTile["prog"];
  heatingLevel?: TAppliancesTile["heatingLevel"];
  link: TAppliancesTile["link"];
  id?: TAppliancesTile["id"];
  isOn?: TAppliancesTile["isOn"];
  type?: EApplianceType;
  progType?: EApplianceProgType;
  progName?: TAppliancesTile["progName"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAppliancesTile',
  props: {
    name: { default: undefined },
    mode: { default: undefined },
    temp: { default: undefined },
    prog: { default: undefined },
    heatingLevel: { default: 0 },
    link: { default: undefined },
    id: { default: undefined },
    isOn: { default: false },
    type: { default: undefined },
    progType: { default: EApplianceProgType.Default },
    progName: { default: '' }
  },
  setup(__props: any) {


const props = __props

const {t} = useI18n()

const formatedName = computed(() => {
  let formatedName = props.name;
  if (props.name.length > 25) {
    formatedName = formatedName.slice(0, 25) + "...";
  }
  return formatedName;
})

const isDiagnosticKo = computed(() => {
  const diag = DiagnosticModule.diagnostics.find(diag => diag.csApplianceId === props.id!)

  if (diag) {
    return diag?.status !== EDiagnosticStatus.OK && diag?.status !== EDiagnosticStatus.TEST_IN_PROGRESS
  }

  return false;
})

const iconName = computed(() => {
  if (props.progType === EApplianceProgType.Default) {
    return 'prog-off';
  } else if (props.type === EApplianceType.WaterHeater) {
    return props.isOn ? ('zap') : ('x-circle');
  } else {
    switch (props.mode) {
      case EApplianceMode.Comfort:
      case EApplianceMode.Comfort1:
      case EApplianceMode.Comfort2:
        return 'thermometer-warm';
      case EApplianceMode.Eco:
      case EApplianceMode.EcoV:
        return 'leaf-2';
      case EApplianceMode.FrostProtection:
        return 'snowflake-01';
      case EApplianceMode.Temp:
        return 'thermometer-03';
      case EApplianceMode.Normal:
        return props.isOn ? (
            'thermometer-warm'
        ) : (
            'zap'
        );
    }
  }
  throw new Error(`Appliance's mode ${props.mode} not supported !`);
})

const getHeatingType = computed(() => {
  return t(heatingType(props.mode, props.isOn));
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link!,
    class: "AppliancesTile",
    "active-class": "active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", { title: _ctx.name }, _toDisplayString(formatedName.value), 9, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          (isDiagnosticKo.value)
            ? (_openBlock(), _createBlock(MyVIcon, {
                key: 0,
                name: "exclamation-circle"
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['AppliancesTile__iconArea', _ctx.progType === _unref(EApplianceProgType).Default ? 'offProg' : ''])
      }, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.isOn)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.mode === _unref(EApplianceMode).Temp)
                  ? (_openBlock(), _createBlock(MyVIconModeTemperature, {
                      key: 0,
                      temperature: _ctx.temp as string
                    }, null, 8, ["temperature"]))
                  : (_openBlock(), _createBlock(MyVIconMode, {
                      key: 1,
                      "icon-name": iconName.value,
                      active: true
                    }, null, 8, ["icon-name"]))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.progType === _unref(EApplianceProgType).Manual && !_ctx.isOn)
                  ? (_openBlock(), _createBlock(MyVIconMode, {
                      key: 0,
                      "icon-name": "x-circle",
                      active: true
                    }))
                  : (_openBlock(), _createBlock(MyVIconMode, {
                      key: 1,
                      "icon-name": "zap-off",
                      active: true
                    }))
              ], 64))
        ])
      ], 2),
      _createElementVNode("div", null, [
        ((_ctx.type !== _unref(EApplianceType).WaterHeater && _ctx.mode!==_unref(EApplianceMode).Normal) && _ctx.isOn && _ctx.progType !== _unref(EApplianceProgType).Default)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(getHeatingType.value), 1))
          : _createCommentVNode("", true),
        ((_ctx.type === _unref(EApplianceType).WaterHeater || _ctx.mode==_unref(EApplianceMode).Normal) && _ctx.isOn && _ctx.progType !== _unref(EApplianceProgType).Default)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('mode.on')), 1))
          : _createCommentVNode("", true),
        (!_ctx.isOn)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('mode.off')), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.progType == _unref(EApplianceProgType).Program)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t(_ctx.progName) ?? ''), 1))
            : (_ctx.progType !== _unref(EApplianceProgType).Default)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t(_ctx.prog)), 1))
              : (_ctx.progType == _unref(EApplianceProgType).Default)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('appliance_progType.default')), 1))
                : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})