import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "monthly-consumption-graph" }
const _hoisted_2 = { class: "monthly-consumption-graph-content" }
const _hoisted_3 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-total" }
const _hoisted_4 = { class: "monthly-consumption-graph-content-total-subtitle" }
const _hoisted_5 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-infos" }
const _hoisted_6 = {
  key: 0,
  class: "monthly-consumption-graph-content-infos-item offPeak"
}
const _hoisted_7 = { class: "monthly-consumption-graph-content-infos-item peak" }
const _hoisted_8 = { class: "monthly-consumption-graph-content-infos-item" }
const _hoisted_9 = { class: "monthly-consumption-graph-content-block monthly-consumption-graph-content-details" }
const _hoisted_10 = { class: "monthly-consumption-graph-content-details-item" }

import {computed, ref} from "vue";
import {TMonthlyConsumptionType} from "@/components/domains/Conso/MyVMonthlyConsumptionGraph/interfaces";
import {ContractModule, SiteModule, SwitchConsoModule} from "@/store";
import {EEnergyUnit} from "@/helpers/constants/unit";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {useRouter} from "vue-router";
import ERoutes from "@/router/routes";
import Historic from "@/components/domains/Conso/MyVHistoric/MyVHistoric.vue";
import {useResponsive} from "@/composables/useResponsive";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import useCurrency from "@/composables/currency";
import MyVLockedOverlay from "@/components/ui/molecules/MyVLockedOverlay/MyVLockedOverlay.vue";
import {Doughnut} from "vue-chartjs";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVMonthlyConsumptionGraph',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props

const showHistoric = ref(false);
const {isMobile} = useResponsive()
const {formatCurrency} = useCurrency()

const dataSet = computed(() => {
  const total = Math.max(props.data.total, props.data.estimated);

  const peak = {value: (props.data.peakHour / total) * 100, color: '#7ADDEB'};
  const offPeak = {value: (props.data.offPeakHour / total) * 100, color: '#49E99F'};
  const estimated = {value: 100 - (peak.value + offPeak.value), color: '#F0F0F4'};

  return [estimated, offPeak, peak].filter(item => item.value > 0);
});

const chartOptions = computed(() => {
  return {
    type: "doughnut",
    animation: {
      animateScale: true
    },
    responsive: true,
    rotation: 219.5,
    circumference: 280,
    cutout: '92%',
    hover: {mode: null},
    layout: {
      padding: isMobile.value ? 0 : 15
    },
    plugins: {
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      },
    }
  }
})
const chartData = computed(() => {
  return {
    datasets: [{
      data: dataSet.value.map(item => item.value),
      backgroundColor: dataSet.value.map(item => item.color),
      borderWidth: 0,
      borderRadius: 20,
    }]
  }
})

const router = useRouter()

const consoType = computed<boolean>(() => {
  return SwitchConsoModule.switchType;
})

const hasBasicOffer = computed(() => SiteModule.hasBasicOffer)

function formatConsoValue(value: number) {
  const valueRounded: number = Math.round(value ?? 0);

  if (consoType.value) return `${valueRounded} ${EEnergyUnit.kWh}`

  return formatCurrency(valueRounded)
}

const toggleShowHistoric = () => {
  if (hasBasicOffer.value) return;

  showHistoric.value = !showHistoric.value;
  pageScroll(!showHistoric.value);
};

const handleClick = () => {
  if (hasBasicOffer.value) return;

  router.push({
    name: ERoutes.CONSO_MONTH,
    query: {type: 'month'},
  })
}

return (_ctx: any,_cache: any) => {
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(Doughnut), {
        data: chartData.value,
        options: chartOptions.value
      }, null, 8, ["data", "options"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: _normalizeClass(['monthly-consumption-graph-content-total-title',{'blurred' : hasBasicOffer.value}])
          }, _toDisplayString(formatConsoValue(_ctx.data.total)), 3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(consoType.value ? _ctx.$t('monthlyConsumptionGraph.total.consumed') : _ctx.$t('monthlyConsumptionGraph.total.estimated')), 1)
        ]),
        _createVNode(_component_a_divider),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.data.offPeakHour > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('welcome.chart.off_peak_hour')), 1),
                _createElementVNode("div", {
                  class: _normalizeClass({'blurred' : hasBasicOffer.value})
                }, _toDisplayString(formatConsoValue(_ctx.data.offPeakHour)), 3)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, _toDisplayString(_unref(ContractModule).currentContract?.isPeakOffPeakContract || _ctx.data.offPeakHour > 0 ? _ctx.$t('welcome.chart.peak_hour') : _ctx.$t('conso.chart.consumption')), 1),
            _createElementVNode("div", {
              class: _normalizeClass({'blurred' : hasBasicOffer.value})
            }, _toDisplayString(formatConsoValue(_ctx.data.peakHour)), 3)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", null, _toDisplayString(_ctx.$t('monthlyConsumptionGraph.infos.forecast')), 1),
            _createElementVNode("div", {
              class: _normalizeClass({'blurred' : hasBasicOffer.value})
            }, _toDisplayString(formatConsoValue(_ctx.data.estimated)), 3)
          ])
        ]),
        _createVNode(_component_a_divider),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(MyVButton, {
              onClick: handleClick,
              round: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('monthlyConsumptionGraph.details.viewDetails')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("a", { onClick: toggleShowHistoric }, _toDisplayString(_ctx.$t('monthlyConsumptionGraph.details.viewHistory')), 1)
          ])
        ])
      ]),
      (hasBasicOffer.value)
        ? (_openBlock(), _createBlock(MyVLockedOverlay, {
            key: 0,
            description: _ctx.$t('subscriberOffer.locked.messages.monitoring')
          }, null, 8, ["description"]))
        : _createCommentVNode("", true)
    ]),
    (showHistoric.value)
      ? (_openBlock(), _createBlock(Historic, {
          key: 0,
          onClose: toggleShowHistoric
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})