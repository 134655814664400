import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "heater-view" }
const _hoisted_2 = { class: "mobilePopin" }
const _hoisted_3 = { class: "heaterModeMain" }
const _hoisted_4 = {
  key: 0,
  class: "heaterMode__wrapper"
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(['heaterMode__icon icon icon-big-colored-chauffe-eau']),
  "aria-hidden": "true"
}
const _hoisted_6 = {
  key: 0,
  class: "heaterMode__title"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "heaterMode__temp"
}
const _hoisted_9 = { class: "heaterMode__title" }
const _hoisted_10 = { class: "heaterMode__title" }
const _hoisted_11 = { class: "heaterMode__blockMode" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "heaterMode__title" }
const _hoisted_17 = { class: "heaterMode__blockMode" }
const _hoisted_18 = { class: "heaterMode__progMode" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 0,
  class: "mobilePopin"
}
const _hoisted_24 = { class: "changeDuration__title" }
const _hoisted_25 = { class: "changeDuration__list" }
const _hoisted_26 = { class: "changeDuration__item" }
const _hoisted_27 = { class: "changeDuration__item" }
const _hoisted_28 = { class: "changeDuration__item" }
const _hoisted_29 = { class: "changeDuration__item" }
const _hoisted_30 = { class: "changeDuration__item" }
const _hoisted_31 = { class: "changeDuration__item" }
const _hoisted_32 = { class: "changeDuration__btn" }
const _hoisted_33 = { class: "changeDuration__item" }

import {
  AppliancesModule,
  DiagnosticModule,
  HeatingModule,
  IntlModule,
  ProgramModule,
  RouterModule
} from "@/store";

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";

import {createDateString, createUntilText} from "@/helpers/dates/date";
import ERoutes from "@/router/routes";
import {EApplianceMode, EApplianceProgType, EApplianceType} from "@/services/Appliances/interfaces";

import {heatingType as heatingTypeHelper} from "@/helpers/domains/heatingType";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {TabHeatingModeFakeDatas as TabHeatingModeFakeDatasConst} from "@/dummies/TabHeatingModeFakeDatas";

import MyVAntddatepicker from '@/components/ui/molecules/MyVDatepicker/MyVDatepicker.vue';
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {digitalFormat} from "@/helpers/number/formatNumber";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import {applianceTypeName} from "@/helpers/domains/applianceProgType";
import {TRenameApplianceDatas} from "@/components/domains/Device/MyVRenameAppliance/interfaces";
import MyVCalibrationPopin from "@/components/domains/Device/MyVCalibrationPopin/MyVCalibrationPopin.vue";
import MyVTabHeatingMode from "@/components/domains/Heating/MyVTabHeatingMode/MyVTabHeatingMode.vue";
import {TTabHeatingModeItem} from "@/components/domains/Heating/MyVTabHeatingMode/interfaces";
import MyVGeAlert from "@/components/ui/atoms/MyVGeAlert/MyVGeAlert.vue";
import SliderRound from "@/components/ui/molecules/MyVSliderRound/MyVSliderRound.vue";
import {TChangeDurationHeaterDatas} from "@/components/domains/Heating/MyVChangeDurationHeater/interfaces";
import MyVRenameAppliance from "@/components/domains/Device/MyVRenameAppliance/MyVRenameAppliance.vue";
import {computed, onBeforeMount, ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {useRoute, useRouter,} from "vue-router";
import {EDiagnosticStatus} from "@/services/Diagnostic/interfaces";
import {useResponsive} from "@/composables/useResponsive";
import useApplianceLoader from "@/composables/useAppliance";
import {ETemperatureUnit} from "@/components/domains/Heating/MyVTemperature/interfaces";
import MyVTemperatureControlModal
  from "@/components/domains/Heating/programs/MyVTemperatureControlModal/MyVTemperatureControlModal.vue";
import {useDate} from "@/helpers/dates/date-utils";
import {useReminder} from "@/composables/useReminder";
import MyVIconMode from "@/components/ui/atoms/MyVIconMode/MyVIconMode.vue";
import PlanningBar from "@/components/domains/Heating/programs/MyVPlanningBar/MyVPlanningBar.vue";
import {TProgramWithPlanning} from "@/services/Program/interfaces";
import {fillDailyProgSegments} from "@/helpers/domains/dailyprogs";
import {TPlanningBarItem} from "@/components/domains/Heating/programs/MyVPlanningBar/interfaces";

const maxTemp = 30
const minTemp = 7


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaterView',
  setup(__props) {

const isOpenPopinDuration = ref(false);
const duration = ref("");
const isTemperatureControlModalOpen = ref(false);
const temperature = ref(0);
const updatedTempKey = ref(0);
const isOpenPopinEdit = ref(false);
const showCalibrationPopin = ref(false);
const selectTabMode = ref<EApplianceMode | null>(null);
const idTimeout = ref<any | null>(null);

const route = useRoute()
const router = useRouter()
const {t} = useI18n()
const {isMobile} = useResponsive()
const {loadedAppliance} = useApplianceLoader()
const {showReminder: hasTemperatureControlReminder} = useReminder("temperatureControlReminder");
const appliance = computed(() => {
  return AppliancesModule.appliances?.find(({id}) => parseInt(route?.params?.id as string) === id);
})

const TabHeatingModeFakeDatas = computed(() => {
  let fakeDatas = [];
  if (appliance.value?.applianceType === EApplianceType.Heater) {
    fakeDatas = TabHeatingModeFakeDatasConst
        .filter(({slug}) => appliance.value!.availableModes.includes(slug))
        .map(data => ({...data, title: t(data.title)}));
  } else {
    fakeDatas.push({
      icon: 'zap',
      title: t('mode.on'),
      slug: EApplianceMode.Normal,
    })
  }

  fakeDatas.push(tabHeatingStopMode.value);
  return fakeDatas;
})

const tabHeatingStopMode = computed((): TTabHeatingModeItem => {
  return {
    icon: 'x-circle',
    title: t('mode.off'),
    slug: EApplianceMode.Stop,
  }
})

const prog = computed(() => {
  return createDateString(t, appliance.value!.programming.untilFurtherNotice!, appliance.value!.programming.endDate);
})

const untilText = computed(() => {
  return createUntilText(t, appliance.value!.programming.untilFurtherNotice, appliance.value!.programming.endDate);
})

const isApplianceFaulty = computed(() => {
  const status = DiagnosticModule.diagnostics.find(diag => diag.csApplianceId === loadedAppliance.value?.id)?.status

  if (status) {
    return status !== EDiagnosticStatus.TEST_IN_PROGRESS && status !== EDiagnosticStatus.OK
  }
  return false;
})

const isProgTypeProgram = computed(() => {
  return appliance.value?.programming.progType === EApplianceProgType.Program;
})

const heatingType = computed(() => {
  return t(heatingTypeHelper(appliance.value!.programming.mode, appliance.value!.programming.isOn));
})

const backLink = computed(() => {
  return () => RouterModule.goBack(ERoutes.HEATING);
})

const activeProgram = computed(() => {
  return ProgramModule.activeProgram;
})

const defaultTemperature = computed(() => {
  return appliance.value?.programming.temperatureTarget || 0;
})

const planning = computed(() => {
  const plannings = (activeProgram.value as TProgramWithPlanning)?.plannings;
  const applianceValue = appliance.value;

  if (applianceValue && plannings) {
    const dailyProg = plannings.find(_planning => (
        _planning.id === applianceValue.programming.idPlanning
    ))?.dailyprogs[currentDayNumber.value - 1];

    if (dailyProg) {
      const currentDayPlanning = fillDailyProgSegments([{...dailyProg}], undefined, {
        mergeSlot: true
      })[0];

      currentDayPlanning.ranges.forEach(range => {
        (range as any).duration = useDate().diffTime(range.to, range.from);
      });
      return currentDayPlanning.ranges as unknown as TPlanningBarItem[];
    }
  }
  return undefined;
})

const temperatureTooltip = computed(() => {
  return (value: number) => `
      <div class="heaterMode__tempTooltip">
        <svg class="heaterMode__tempTooltip-icon icon icon-thermometer-03" aria-hidden="true"><use xlink:href="#icon-thermometer-03" /></svg>
        <div class="heaterMode__tempTooltip-value">${digitalFormat(value)}<span class="heaterMode__tempTooltip-unit">${ETemperatureUnit.Celsius}</span></div>
        <div class="heaterMode__tempTooltip-subtitle">${t('mode.temperature')}</div>
      </div>
    `;
})

const currentDayNumber = computed(() => {
  return useDate().day();
})

const programEditRoute = computed(() => {
  const activeProgramValue = activeProgram.value;
  const applianceValue = appliance.value;

  return (applianceValue && activeProgram) ? `${ERoutes.PROGRAM}/${activeProgramValue?.id}/${ERoutes.ADDON_PLANNING}/${applianceValue.id}/${ERoutes.ADDON_EDIT}/${currentDayNumber.value}` : '';
})

const isQuickProgType = computed(() => {
  return appliance.value?.programming.progType === EApplianceProgType.Quick
})

const currentProg = computed(() => {
  let _currentProg = '';

  switch (appliance.value!.programming.progType) {
      // Appliance is configurate following program's instructions
    case EApplianceProgType.Program:
      _currentProg = t('heater.prog_mode.program') + " <span class='heaterMode__progMode-name'>" + t(appliance.value!.programming.progName ?? '') + "</span>";
      break;

      // Appliance is configurate following quick settings' instructions.
    case EApplianceProgType.Quick:
      _currentProg = t('heater.prog_mode.quick') + " : <span class='heaterMode__progMode-name'>" + t(appliance.value!.programming.progName ?? '') + "</span>";
      break;

      // Appliance is configurate following manual settings' instructions.
    case EApplianceProgType.Manual:
      _currentProg = t('heater.prog_mode.manual');
      break;

      // Appliance is configurate following default settings' instructions.
    case EApplianceProgType.Default:
      _currentProg = t('heater.prog_mode.default');
      break;
  }

  return _currentProg;
})

const hasEnergyManager = computed(() => {
  return ProgramModule.hasEnergyManager;
})

const iconName = computed(() => {
  switch (appliance.value!.programming.mode) {
    case EApplianceMode.Comfort:
    case EApplianceMode.Comfort1:
    case EApplianceMode.Comfort2:
      return 'thermometer-warm';
    case EApplianceMode.Eco:
    case EApplianceMode.EcoV:
      return 'leaf-2';
    case EApplianceMode.FrostProtection:
      return 'snowflake-01';
    case EApplianceMode.Temp:
      return 'thermometer-03';
    case EApplianceMode.Normal:
      // If appliance is a water heater.
      return (appliance.value!.applianceType === EApplianceType.WaterHeater) ? (
          'big-colored-chauffe-eau'
      ) : (
          // If appliance isn't a water heater.
          // If appliance is on
          appliance.value!.programming.isOn ? (
              'thermometer-warm'
          ) : (
              'zap-off'
          )
      )
  }
  throw new Error(`Appliance's mode ${appliance.value!.programming.mode} not supported !`);
})

const formatedName = computed(() => {
  let formatedTitle = appliance.value!.name
  if (formatedTitle && appliance.value!.name.length > 50) {
    formatedTitle = "<div title='" + formatedTitle + "''>" + formatedTitle.slice(0, 50) + "..." + "</div>";
  }
  return formatedTitle;
})

const currentActiveManualSetting = computed(() => {
  if (appliance.value?.programming.progType === (EApplianceProgType.Manual)) {
    return appliance.value?.programming?.isOn ? appliance.value.programming.mode : EApplianceMode.Stop;
  }

  return '';
})

const applianceTypeTrad = (applianceType: EApplianceType) => {
  return t(applianceTypeName(applianceType))
}

const changeMode = (applianceMode: EApplianceMode) => {
  selectTabMode.value = applianceMode;
  if (applianceMode === currentActiveManualSetting.value) {
    stopManualMode()
  } else {
    openPopinDuration();
  }
}

const stopManualMode = () => {
  HeatingModule.updateManualSettingByApplianceId({
    id: appliance.value!.id,
    body: {
      enabled: false
    }
  }).then(() => {
    temperature.value = appliance.value!.programming.temperatureTarget!;
    temperature.value = appliance.value!.programming.temperatureTarget!;
    updatedTempKey.value++;
  })
}

const getManualModeTitle = (modeTitle: EApplianceMode) => {
  if (modeTitle === EApplianceMode.Stop) return 'mode.off';
  return TabHeatingModeFakeDatasConst.find(({slug}) => slug === modeTitle)!.title;
}

const openPopinDuration = () => {
  isOpenPopinDuration.value = true;
  pageScroll(false);
}

const closePopinDuration = () => {
  isOpenPopinDuration.value = false;
  pageScroll(true);
}

const onCustomDurationChange = (date: any) => {
  submitDuration({
    "untilFurtherNotice": false,
    "endDate": date.format('YYYY-MM-DD[T]HH:mm:ss')
  });
}

const changeDuration = (duration: number | string) => {

  let untilFurtherNotice = false;
  let endDate = null;
  if (duration == "untilFurtherNotice") {
    untilFurtherNotice = true;
  } else {
    endDate = useDate().add(duration as number, 'hours').format('YYYY-MM-DD[T]HH:mm:ss');
  }
  submitDuration({
    "untilFurtherNotice": untilFurtherNotice,
    "endDate": endDate
  });
}


const changeTemperature = (val: number) => {
  if (idTimeout.value) clearTimeout(idTimeout.value);
  idTimeout.value = setTimeout(() => {
    HeatingModule.updateManualSettingByApplianceId({
      id: appliance.value!.id,
      body: {
        enabled: true,
        mode: EApplianceMode.Temp,
        temperatureTarget: val
      }
    });
  }, 500);
}

const submitDuration = (formDatas: TChangeDurationHeaterDatas) => {
  if (selectTabMode.value === EApplianceMode.Stop) {
    HeatingModule.updateManualSettingByApplianceId({
      id: appliance.value!.id,
      body: {
        enabled: true,
        endDate: formDatas.endDate,
        isOn: false,
        untilFurtherNotice: formDatas.untilFurtherNotice,
      }
    }).then(() => {
      selectTabMode.value = null;
      closePopinDuration();
    });
  } else if (selectTabMode.value) {
    HeatingModule.updateManualSettingByApplianceId({
      id: appliance.value!.id,
      body: {
        mode: selectTabMode.value,
        enabled: true,
        endDate: formDatas.endDate,
        isOn: true,
        untilFurtherNotice: formDatas.untilFurtherNotice,
      }
    }).then(() => {
      if (selectTabMode.value === EApplianceMode.Temp && hasTemperatureControlReminder()) {
        isTemperatureControlModalOpen.value = true
      }
      selectTabMode.value = null;
      closePopinDuration();
      temperature.value = appliance.value!.programming.temperatureTarget!;
      updatedTempKey.value++;
    });
  } else {
    HeatingModule.updateManualSettingByApplianceId({
      id: parseInt(route.params.id as string),
      body: {
        untilFurtherNotice: formDatas.untilFurtherNotice,
        endDate: formDatas.endDate
      }
    }).then(() => {
      closePopinDuration();
    });
  }
}

const openPopinEdit = () => {
  isOpenPopinEdit.value = true;
  pageScroll(false);
}

const handleDiagnosticDetail = () => {
  router.push(`${ERoutes.DIAGNOSTIC}/${loadedAppliance.value?.id}`)
}

const closePopinEdit = () => {
  isOpenPopinEdit.value = false;
  pageScroll(true);
}

const submitRename = (formDatas: TRenameApplianceDatas) => {
  AppliancesModule.updateAppliance({
    id: parseInt(route.params.id as string),
    body: {
      name: formDatas.applianceName
    }
  })
      .then(() => {
        AppliancesModule.getAppliances(true)
        closePopinEdit();
      });
}

onBeforeMount(() => {
      temperature.value = defaultTemperature.value;

      if (activeProgram.value) {
        ProgramModule.getProgram({id: activeProgram.value.id});
      }
    }
)
watch(defaultTemperature, () => {
  if (route?.params) {
    temperature.value = defaultTemperature.value;
  }
})

watch(() => IntlModule.locale, () => {
  updatedTempKey.value++
})

watch(activeProgram, (newValue, oldVAlue) => {
  if (newValue && oldVAlue?.id !== newValue.id) {
    ProgramModule.getProgram({id: newValue.id});
  }
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (appliance.value)
          ? (_openBlock(), _createBlock(MyVPageWithReturn, {
              key: 0,
              title: _unref(isMobile) ? formatedName.value : '',
              type: "page",
              returnAction: backLink.value,
              "has-header": _unref(isMobile)
            }, _createSlots({
              footer: _withCtx(() => [
                _createVNode(MyVTabHeatingMode, {
                  listItem: TabHeatingModeFakeDatas.value,
                  action: changeMode,
                  activeTab: currentActiveManualSetting.value
                }, null, 8, ["listItem", "activeTab"])
              ]),
              default: _withCtx(() => [
                (isApplianceFaulty.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "diagnostic-alert",
                      onClick: handleDiagnosticDetail
                    }, [
                      _createVNode(MyVIcon, { name: "exclamation-circle" }),
                      _createElementVNode("div", null, _toDisplayString(_ctx.$t('diagnostic.deviceStatus.banner.text')), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  (appliance.value.programming.isOn)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (appliance.value.applianceType === _unref(EApplianceType).WaterHeater)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                              (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[13] || (_cache[13] = [
                                _createElementVNode("use", { "xlink:href": ['#icon-big-colored-chauffe-eau'] }, null, -1)
                              ]))),
                              (appliance.value.programming.progType !== _unref(EApplianceProgType).Default)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(heatingType.value), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              (appliance.value.programming.mode === _unref(EApplianceMode).Temp)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    (_openBlock(), _createBlock(SliderRound, {
                                      max: maxTemp,
                                      min: minTemp,
                                      tooltipGenerateTemplate: temperatureTooltip.value,
                                      modelValue: temperature.value,
                                      "onUpdate:modelValue": [
                                        _cache[0] || (_cache[0] = ($event: any) => ((temperature).value = $event)),
                                        changeTemperature
                                      ],
                                      isFromProgram: isProgTypeProgram.value,
                                      key: updatedTempKey.value
                                    }, null, 8, ["tooltipGenerateTemplate", "modelValue", "isFromProgram"]))
                                  ]))
                                : _createCommentVNode("", true),
                              (appliance.value.programming.progType === _unref(EApplianceProgType).Default)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createVNode(MyVIconMode, {
                                      "icon-name": "zap-off",
                                      size: "large"
                                    }),
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(heatingType.value), 1)
                                  ], 64))
                                : (appliance.value.programming.mode !== _unref(EApplianceMode).Temp)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                      _createVNode(MyVIconMode, {
                                        "icon-name": iconName.value,
                                        active: true,
                                        type: appliance.value.programming.mode,
                                        size: "large"
                                      }, null, 8, ["icon-name", "type"]),
                                      _createElementVNode("div", _hoisted_10, _toDisplayString(heatingType.value), 1)
                                    ], 64))
                                  : _createCommentVNode("", true)
                            ])),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["heaterMode__progMode", {'default': appliance.value.programming.progType === _unref(EApplianceProgType).Default}])
                          }, [
                            _createElementVNode("span", { innerHTML: currentProg.value }, null, 8, _hoisted_12)
                          ], 2),
                          (appliance.value.programming.progType === _unref(EApplianceProgType).Program)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createVNode(PlanningBar, {
                                  plannings: planning.value ?? [],
                                  hideLegend: true
                                }, null, 8, ["plannings"]),
                                _createVNode(_component_router_link, {
                                  to: programEditRoute.value,
                                  class: "heaterMode__editPlanning"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('appliance.edit_planning')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ]))
                            : (appliance.value.programming.progType !== _unref(EApplianceProgType).Default)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createElementVNode("div", null, [
                                    _createVNode(MyVButton, {
                                      class: _normalizeClass([{'quickType': isQuickProgType.value}]),
                                      disabled: !appliance.value.programming.isOn || isQuickProgType.value,
                                      inline: true,
                                      round: "",
                                      onClick: openPopinDuration
                                    }, {
                                      default: _withCtx(() => [
                                        (!isQuickProgType.value)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_15))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("span", null, _toDisplayString(untilText.value) + " " + _toDisplayString(prog.value), 1),
                                        (!isQuickProgType.value)
                                          ? (_openBlock(), _createBlock(MyVIcon, {
                                              key: 1,
                                              name: "pen"
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }, 8, ["class", "disabled"])
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                          (appliance.value.programming.progType === _unref(EApplianceProgType).Manual)
                            ? (_openBlock(), _createBlock(MyVButton, {
                                key: 2,
                                icon: "cross",
                                onClick: stopManualMode
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('appliance.stop_manual_mode')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(MyVIconMode, {
                          "icon-name": "zap-off",
                          active: true,
                          size: "large"
                        }),
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('mode.off')), 1),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("div", _hoisted_18, [
                            _createElementVNode("span", { innerHTML: currentProg.value }, null, 8, _hoisted_19)
                          ]),
                          (appliance.value.programming.progType === _unref(EApplianceProgType).Program)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createVNode(_component_router_link, {
                                  to: programEditRoute.value,
                                  class: "heaterMode__editPlanning"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t('appliance.edit_planning')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["to"])
                              ]))
                            : (appliance.value.programming.progType !== _unref(EApplianceProgType).Default)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                  _createElementVNode("div", null, [
                                    _createVNode(MyVButton, {
                                      class: _normalizeClass([ {'quickType': isQuickProgType.value}]),
                                      disabled: isQuickProgType.value,
                                      onClick: openPopinDuration
                                    }, {
                                      default: _withCtx(() => [
                                        (!isQuickProgType.value)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_22))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("span", null, _toDisplayString(untilText.value) + " " + _toDisplayString(prog.value), 1),
                                        (!isQuickProgType.value)
                                          ? (_openBlock(), _createBlock(MyVIcon, {
                                              key: 1,
                                              name: "pen"
                                            }))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 1
                                    }, 8, ["class", "disabled"])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                        ]),
                        (appliance.value.programming.progType === _unref(EApplianceProgType).Manual)
                          ? (_openBlock(), _createBlock(MyVButton, {
                              key: 0,
                              icon: "cross",
                              onClick: stopManualMode
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('appliance.stop_manual_mode')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ], 64)),
                  (appliance.value.programming.mode === _unref(EApplianceMode).Temp && appliance.value.programming.isOn)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 2,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (showCalibrationPopin.value = true)),
                        class: "heaterMode__calibrate"
                      }, _toDisplayString(_ctx.$t('device_status.manual_calibration.start')), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, [
              (_unref(isMobile))
                ? {
                    name: "headerRight",
                    fn: _withCtx(() => [
                      _createElementVNode("button", {
                        type: "button",
                        onClick: openPopinEdit
                      }, _cache[12] || (_cache[12] = [
                        _createElementVNode("svg", {
                          class: "icon icon-pen",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-pen" })
                        ], -1)
                      ]))
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title", "returnAction", "has-header"]))
          : _createCommentVNode("", true)
      ]),
      (isOpenPopinEdit.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createVNode(MyVPageWithReturn, {
              title: _ctx.$t('heating.edit.title'),
              returnAction: closePopinEdit,
              type: "popin"
            }, {
              default: _withCtx(() => [
                _createVNode(MyVRenameAppliance, {
                  returnAction: closePopinEdit,
                  onSubmit: submitRename,
                  applianceName: appliance.value.name,
                  applianceType: applianceTypeTrad(appliance.value.applianceType)
                }, null, 8, ["applianceName", "applianceType"])
              ]),
              _: 1
            }, 8, ["title"])
          ]))
        : _createCommentVNode("", true),
      (showCalibrationPopin.value)
        ? (_openBlock(), _createBlock(MyVCalibrationPopin, {
            key: 1,
            appliance: appliance.value,
            onClose: _cache[2] || (_cache[2] = ($event: any) => (showCalibrationPopin.value = false))
          }, null, 8, ["appliance"]))
        : _createCommentVNode("", true),
      (isOpenPopinDuration.value)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ? MyVBottomPopin : MyVPageWithReturn), {
            key: 2,
            type: "popin",
            class: "heater-view-custom-duration",
            title: _ctx.$t('heating_manual.duration.title', {modeTitle: _ctx.$t(getManualModeTitle(selectTabMode.value ?? currentActiveManualSetting.value))}),
            "return-action": closePopinDuration,
            BottomPopin: "",
            onCancel: _cache[10] || (_cache[10] = ($event: any) => (closePopinDuration()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.$t('heating_manual.duration.title2', {
                modeTitle: _ctx.$t(getManualModeTitle(selectTabMode.value ?? currentActiveManualSetting.value)),
                applianceName: appliance.value.name
              }
          )), 1),
              (hasEnergyManager.value && _unref(isMobile))
                ? (_openBlock(), _createBlock(MyVGeAlert, {
                    key: 0,
                    class: "changeDuration__geAlert"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("ul", _hoisted_25, [
                _createElementVNode("li", _hoisted_26, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (changeDuration(1)))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', 1)), 1)
                ]),
                _createElementVNode("li", _hoisted_27, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (changeDuration(2)))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', 2)), 1)
                ]),
                _createElementVNode("li", _hoisted_28, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (changeDuration(3)))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', 3)), 1)
                ]),
                _createElementVNode("li", _hoisted_29, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (changeDuration(6)))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', 6)), 1)
                ]),
                _createElementVNode("li", _hoisted_30, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (changeDuration(24)))
                  }, _toDisplayString(_ctx.$t('heating.duration.hours', 24)), 1)
                ]),
                _createElementVNode("li", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('heating.duration.custom')), 1),
                  _createVNode(MyVAntddatepicker, {
                    value: duration.value,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((duration).value = $event)),
                    onlyFuture: false,
                    placement: "topLeft",
                    showTime: true,
                    class: "changeDuration__btn",
                    onOk: onCustomDurationChange
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("li", _hoisted_33, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "changeDuration__btn",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (changeDuration('untilFurtherNotice')))
                  }, _toDisplayString(_ctx.$t('heating.duration.untilFurtherNotice')), 1)
                ])
              ])
            ]),
            _: 1
          }, 40, ["title"]))
        : _createCommentVNode("", true)
    ]),
    (isTemperatureControlModalOpen.value)
      ? (_openBlock(), _createBlock(MyVTemperatureControlModal, {
          key: 0,
          onClose: _cache[11] || (_cache[11] = ($event: any) => (isTemperatureControlModalOpen.value= false))
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})