import { useStorageKeys } from "@/composables/useStorageKeys";

export function useReminder(reminderKeyName: "temperatureControlReminder" | "progReminder") {
    const { [reminderKeyName]: reminderKey } = useStorageKeys();

    const setReminder = (value: boolean) => {
        localStorage.setItem(reminderKey.value, String(value));
    };

    const showReminder = () => {
        if (localStorage.getItem(reminderKey.value) === null) {
            localStorage.setItem(reminderKey.value, String(true));
        }

        return localStorage.getItem(reminderKey.value) === String(true);
    };

    return {
        setReminder,
        showReminder,
    };
}
