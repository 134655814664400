import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "form__radioIcon-icon" }
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = ["xlink:href"]
const _hoisted_4 = { class: "form__radioIcon-label" }

import {TRadio} from "./interfaces";
import {onBeforeMount, ref, watch} from "vue";

export interface Props {
  defaultValue?: TRadio["defaultValue"];
  options?: TRadio["options"];
  hasIcon?: TRadio["hasIcon"];
  fullWidth?: TRadio["fullWidth"];
  value?: TRadio["value"];
  error?: TRadio["error"];
  onChange?: TRadio["onChange"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVAntdradio',
  props: {
    defaultValue: { default: undefined },
    options: { default: undefined },
    hasIcon: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    value: { default: undefined },
    error: { default: undefined },
    onChange: { type: Function, default: undefined }
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const m_value = ref<TRadio['value']>(null);

const onLocalChange = (event: any) => {
  props.onChange?.(event.target.value);
}

onBeforeMount(() => {
  m_value.value = props.value ?? props.defaultValue;
})

watch(m_value, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue !== props.value) {
    emit('update:value', newValue);
  }
})

watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue !== m_value.value) {
    m_value.value = newValue;
  }
})

return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_ctx.hasIcon)
    ? (_openBlock(), _createBlock(_component_a_form_item, {
        key: 0,
        class: "form__radioIcon",
        "validate-status": _ctx.error ? 'error' : undefined,
        help: _ctx.error
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio_group, {
            value: m_value.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((m_value).value = $event)),
            class: "form__radioIcon-container",
            onChange: onLocalChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createBlock(_component_a_radio, {
                  value: option.value,
                  key: option.value,
                  class: "form__radioIcon-item"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (option.icon)
                        ? (_openBlock(), _createElementBlock("svg", {
                            key: 0,
                            class: _normalizeClass(['icon icon-'+option.icon]),
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", {
                              "xlink:href": '#icon-'+option.icon
                            }, null, 8, _hoisted_2)
                          ], 2))
                        : _createCommentVNode("", true),
                      (option.iconActive)
                        ? (_openBlock(), _createElementBlock("svg", {
                            key: 1,
                            class: _normalizeClass(['icon iconActive icon-'+option.iconActive]),
                            "aria-hidden": "true"
                          }, [
                            _createElementVNode("use", {
                              "xlink:href": '#icon-'+option.iconActive
                            }, null, 8, _hoisted_3)
                          ], 2))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(option.label), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      }, 8, ["validate-status", "help"]))
    : (_openBlock(), _createBlock(_component_a_form_item, {
        key: 1,
        class: _normalizeClass(['form__radio',{'form__radio--fullWidth':_ctx.fullWidth}]),
        "validate-status": _ctx.error ? 'error' : undefined,
        help: _ctx.error
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio_group, {
            value: m_value.value,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((m_value).value = $event)),
            options: _ctx.options,
            onChange: onLocalChange
          }, null, 8, ["value", "options"])
        ]),
        _: 1
      }, 8, ["class", "validate-status", "help"]))
}
}

})