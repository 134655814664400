import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "inner" }
const _hoisted_2 = { class: "advices__container inner" }
const _hoisted_3 = { class: "advices__blockText" }
const _hoisted_4 = { class: "advices__blockText-title" }
const _hoisted_5 = { class: "advices__blockText-text" }
const _hoisted_6 = { class: "advices__list" }
const _hoisted_7 = { class: "adviceCard__content" }
const _hoisted_8 = { class: "adviceCard__title" }

import MyVPageMain from "@/components/ui/organisms/layouts/MyVPageMain/MyVPageMain.vue";
import MyVHeaderMainPage from "@/components/ui/atoms/MyVHeaderMainPage/MyVHeaderMainPage.vue";
import logo from "@/assets/images/logo_beta.svg?url";
import ERoutes from "@/router/routes";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import API from "@/helpers/api";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {computed, onBeforeMount, ref} from "vue";
import {SiteModule} from '@/store';
import {ECountry} from "@/composables/countries";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvicesView',
  setup(__props) {

const categories = ref<string[]>([])

const siteCountry = computed<ECountry>(() => SiteModule.siteCountry ? SiteModule.siteCountry : ECountry.FRANCE)

const getCategoryImage = (category: string) =>{
  const images = require.context('../../../../assets/images', false)
  if (!images) return;
  return images('./' + category + '.jpg')
}

const singleAdviceRoute = (category: string) => {
  return `${ERoutes.ADVICE}/${category}`
}

onBeforeMount(() => {
  API.get('/api/user/advice').then(({data}) => {
    categories.value = data.categories
    if (siteCountry.value === ECountry.FRANCE) {
      categories.value.splice(2, 0, "advice.ecowatt")
    }
  })
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(MyVDisplayGroupStore, {
    currentRight: _unref(EDisplayGroupRightName).MY_ADVICES,
    forbiddenRedirection: _unref(ERoutes).HOME
  }, {
    default: _withCtx(() => [
      _createVNode(MyVPageMain, {
        homeLink: _unref(ERoutes).HOME,
        logo: _unref(logo),
        title: _ctx.$t('app.title'),
        hasProgramBar: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(MyVHeaderMainPage, { title: "advices.title" })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("advices.block.title")), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("advices.block.description")), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (category) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  to: singleAdviceRoute(category),
                  class: "adviceCard",
                  key: category
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: "adviceCard__visual",
                        style: _normalizeStyle(`background-image:url(${getCategoryImage(category)});`)
                      }, [
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(category)), 1)
                      ], 4)
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ])
          ])
        ]),
        _: 1
      }, 8, ["homeLink", "logo", "title"])
    ]),
    _: 1
  }, 8, ["currentRight", "forbiddenRedirection"]))
}
}

})