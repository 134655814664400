export type TSwitch = {
    /**
     * defaultValue
     */
    defaultValue?: boolean;

    /**
     * value
     */
    value?: boolean;

    /**
     * isPaused (checked but in pause)
     */
    isPaused?: boolean;

    /**
     * checkedChildren (inside label)
     */
    checkedChildren?: string;

    /**
     * checkedChildrenIcon (inside label)
     */
    checkedChildrenIcon?: string;

    /**
     * uncheckedChildrenIcon (inside label)
     */
    uncheckedChildrenIcon?: string;

    /**
     * unCheckedChildren (inside label)
     */
    unCheckedChildren?: string;

    /**
     * outside label
     */
    label?: string;

    /**
     * size
     */
    size?: ESwitchSize;

    /**
     * Error rendered.
     */
    error?: string;

    /**
     * Switch is disabled.
     */
    disabled?: boolean;
};

export enum ESwitchType {
    Unit = "unit",
    Default = "Default"
}

export enum ESwitchSize {
    Big = "big",
}

