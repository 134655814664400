import {EApplianceMode, EApplianceType, TAppliance} from "@/services/Appliances/interfaces";

export const heatingType = (mode: EApplianceMode, isOn: TAppliance['programming']['isOn']): string => {
    if (!isOn) {
        return 'mode.off';
    }

    switch (mode) {
        case EApplianceMode.Comfort:
            return 'mode.confort';
        case EApplianceMode.Comfort1:
            return 'mode.confort1';
        case EApplianceMode.Comfort2:
            return 'mode.confort2';
        case EApplianceMode.Eco:
            return 'mode.eco';
        case EApplianceMode.EcoV:
            return 'mode.ecov';
        case EApplianceMode.FrostProtection:
            return 'mode.frostProtection';
        case EApplianceMode.Temp:
            return 'mode.temperature';
        case EApplianceMode.Normal:
            return 'mode.normal';
        default:
            return '';
    }
}

export const heatingIcon = (mode: EApplianceMode, isOn: TAppliance['programming']['isOn']): string => {
    if (!isOn) {
        return 'x-circle';
    }

    switch (mode) {
        case EApplianceMode.Eco:
        case EApplianceMode.EcoV:
            return 'leaf-2';
        case EApplianceMode.FrostProtection:
            return 'snowflake-01';
        case EApplianceMode.Temp:
            return 'thermometer-03';
        case EApplianceMode.Comfort:
        case EApplianceMode.Comfort2:
        default:
            return 'thermometer-warm';
    }
}

export const heatingIconMode = (mode: EApplianceMode, appliance: TAppliance): string => {
    if (!appliance) return "zap-off";

    const {applianceType, programming} = appliance;

    switch (mode) {
        case EApplianceMode.Stop:
            return "x-circle";

        case EApplianceMode.Eco:
        case EApplianceMode.EcoV:
            return "leaf-2";

        case EApplianceMode.FrostProtection:
            return "snowflake-01";

        case EApplianceMode.Temp:
            return "thermometer-03";

        case EApplianceMode.Comfort:
        case EApplianceMode.Comfort1:
        case EApplianceMode.Comfort2:
            return "thermometer-warm";

        case EApplianceMode.Normal:
            return applianceType !== EApplianceType.Heater ? "zap" : "thermometer-warm";

        default:
            if (applianceType === EApplianceType.Heater) {
                return programming.isOn ? "thermometer-warm" : "x-circle";
            }

            if (applianceType === EApplianceType.WaterHeater) {
                return programming.isOn ? "zap" : "zap-off";
            }

            return "zap-off";
    }
};
