import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, unref as _unref } from "vue"

const _hoisted_1 = { class: "ProviderStep" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mainContent" }
const _hoisted_4 = {
  key: 0,
  class: "providerContainer"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "noProvider"
}

import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {ContractModule, SiteModule} from "@/store";
import {TProvider, TProviders} from "@/services/Contract/interfaces";
import ContractService from "@/services/Contract";
import {OTHER_PROVIDER_ID} from "@/helpers/constants/providers";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import { computed, onBeforeMount, ref} from "vue";
import {useI18n} from "vue-i18n";
import { EInputType } from "@/components/ui/atoms/MyVAntdinput/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProviderStepView',
  emits: ["next", "skipContract"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const {t} = useI18n()

const contractName = ref<string | undefined>(undefined)
const providers = ref<TProviders>([])

const isSiteCountryFrance = computed(() => SiteModule.isSiteCountryFrance)
const providerList = computed(() => {
  const providersValue = providers.value;

  if (contractName.value) {
    const res = providers.value.filter(el => el.businessName.toLowerCase().includes(contractName.value!.toLowerCase()))
    if (!res.length) {
      return providers.value.filter(provider => provider.id === OTHER_PROVIDER_ID)
    }

    return res
  }

  return providersValue.sort((a, b) => {
    if (a.id === OTHER_PROVIDER_ID) {
      return 1
    }
    return a.name.localeCompare(b.name)
  })
})

const save = (provider: TProvider) => {
  if (!providers.value.includes(provider)) {
    return emit('skipContract', provider)
  }
  return emit('next', provider)
}

onBeforeMount(() => {
  contractName.value = ContractModule.fakeContract ? t(ContractModule.fakeContract!.companyName) : undefined;
  ContractService.getProviders().then((data) => {
    providers.value = data;

    const otherProviderIndex = providers.value.findIndex(provider => provider.id === OTHER_PROVIDER_ID)
    if (otherProviderIndex >= 0) {
      providers.value.push(providers.value.splice(otherProviderIndex, 1)[0])
    }
  })
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isSiteCountryFrance.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('contract.provider_step.choose_provider')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (isSiteCountryFrance.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(MyVAntdinput, {
              value: contractName.value,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((contractName).value = $event)),
              id: "providerSearch",
              "input-type": "Text",
              label: _ctx.$t('contract.provider_step.search_in_list'),
              allowClear: ""
            }, null, 8, ["value", "label"]),
            (providerList.value.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(providerList.value, (provider, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "providerRow",
                      onClick: ($event: any) => (save(provider))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t(provider.businessName ?? provider.name)), 1),
                      _createVNode(MyVButton, {
                        class: "chooseProviderButton",
                        primary: false,
                        size: "small",
                        onClick: ($event: any) => (save(provider))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('contract.provider_step')), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ], 8, _hoisted_5))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('contract.provider_step.no_provider_found')), 1))
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(MyVAntdinput, {
              id: "providercontractName",
              "input-type": _unref(EInputType).Text,
              value: contractName.value,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((contractName).value = $event)),
              "allow-clear": "",
              label: _ctx.$t('contract.provider_step.choose_provider')
            }, null, 8, ["input-type", "value", "label"]),
            _createVNode(MyVButton, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('next', {name: contractName.value!, id: null, businessName: ''}))),
              disabled: !contractName.value
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('global.next')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ], 64))
    ])
  ]))
}
}

})