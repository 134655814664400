import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "BottomPopin__text" }
const _hoisted_6 = { class: "BottomPopin__title" }
const _hoisted_7 = { class: "BottomPopin__subtitle" }
const _hoisted_8 = { class: "BottomPopin__buttons" }

import {TObjectiveModal} from "@/components/ui/organisms/MyVObjectiveModal/interfaces";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import MyVIcon from "@/components/ui/atoms/MyVIcon/MyVIcon.vue";
import MyVBarLineGraphic from '@/components/ui/atoms/graphics/MyVBarLineGraphic/MyVBarLineGraphic.vue';
import {TBarLineGraphic} from "@/components/ui/atoms/graphics/MyVBarLineGraphic/interfaces";

import {ConsoModule, ContractModule, IntlModule, ObjectiveModule, SwitchConsoModule} from "@/store";

import ObjectiveModalItem from "./MyVObjectiveModalItem.vue";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import {EAggregationAlias, TSummaryConsumption} from "@/services/Conso/interfaces";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {computed, onBeforeMount, onBeforeUnmount, reactive, ref} from "vue";
import {useI18n} from "vue-i18n";
import useCurrency from "@/composables/currency";
import {useDate} from "@/helpers/dates/date-utils";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import {EButtonSize} from "@/components/ui/atoms/MyVButton/interfaces";
import {ESwitchType} from "@/components/ui/atoms/MyVSwitches/interfaces";
import MyVSwitch from "@/components/ui/atoms/MyVSwitches/MyVSwitch.vue";

interface Props {
  isEditing: TObjectiveModal['isEditing'];
  currentYear: TObjectiveModal['currentYear'];
  objectiveDatas?: TObjectiveModal['objectiveData'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVObjectiveModal',
  props: {
    isEditing: { type: Boolean, default: undefined },
    currentYear: { default: undefined },
    objectiveDatas: { default: undefined }
  },
  emits: ['close', 'resetObjective'],
  setup(__props: any, { emit: __emit }) {


const props = __props

const emit = __emit

const {currency, formatCurrency, isCurrencyBeforeText} = useCurrency()

const unitCurrency = currency.value;
const editingObjective = ref(false);
const showDeletePopin = ref(false);
const _consos = ref<TSummaryConsumption[]>([])
const idTimeout = ref<any | null>(null);

const {t} = useI18n()

const form = reactive<TObjectiveModal['form']>({
  yearlyObjectiveInWh: null,
  yearlyObjectiveInCurrency: null
});

const initData = reactive<TObjectiveModal['form']>({
  yearlyObjectiveInWh: null,
  yearlyObjectiveInCurrency: null
});

const title = computed(() => {
  if (props.isEditing) {
    return `${t('objective.editing.title')} ${props.currentYear}`;
  }

  return `${t('objective.define.title')} ${props.currentYear}`;
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType
})

const consos = computed(() => {
  return {
    unitEnergy: EEnergyUnit.kWh,
    unitCurrency,
    values: _consos.value
        .map(({
                stepTimestampOnSite,
                objectiveInWh,
                totalConsumptionInWh,
                objectiveInCurrency,
                totalConsumptionInCurrency
              }) => {
          const date = useDate(stepTimestampOnSite).locale(IntlModule.locale).format('MMMM');
          const common = {
            date: date[0].toUpperCase() + date.substring(1),
          };

          return consoType.value ? {
            ...common,
            objective: objectiveInWh || 0,
            conso: totalConsumptionInWh || 0,
          } : {
            ...common,
            objective: objectiveInCurrency || 0,
            conso: totalConsumptionInCurrency || 0,
          };
        })
  };
})

const objectiveInCurrentType = computed(() => {
  return consoType.value ? form.yearlyObjectiveInWh : form.yearlyObjectiveInCurrency;
})

const contractHasTarif = computed(() => {
  const contract = ContractModule.contractsList[0];
  if (!contract) return false;
  return (contract.kwhOffpeakHourPrice !== null && contract.kwhPeakHourPrice !== null) || contract.kwhBasePrice !== null;
})

const barLineConso = computed(() => {
  const labels: TBarLineGraphic['labels'] = [];
  const objectiveDatas: TBarLineGraphic['bars'][number]['datas'] = [];
  const consoDatas: TBarLineGraphic['bars'][number]['datas'] = [];

  const consosValues = [...consos.value.values].sort((a, b) => useDate(a.date, 'MMMM').month() - useDate(b.date, 'MMMM').month())

  consosValues.forEach(({date, objective, conso}) => {
    labels.push({text: date});
    objectiveDatas.push(Math.round(consoType.value ? fromWhToKwh(objective) : objective));
    consoDatas.push(Math.round(consoType.value ? fromWhToKwh(conso) : conso));
  })

  return {
    labels,
    leftAxis: {
      min: 0,
      title: consoType.value ? consos.value.unitEnergy : consos.value.unitCurrency,
      stacked: false
    },
    bars: [{
      label: t('objective_modal.objective'),
      datas: objectiveDatas,
      color: 'rgba(255, 255, 255, 0)',
      borderColor: "rgb(97, 97, 97)",
      borderWidth: 1
    }, {
      label: t('objective_modal.consumption'),
      datas: consoDatas,
      color: (_: number, index: number, barDatas: number[][]) => barDatas[0][index] > barDatas[1][index] ? "rgb(151, 190, 13)" : "rgb(235, 87, 87)"
    }],
    barThickness: 16,
    displayLegend: false
  };
})

const closePopin = () => {
  emit('close');
}

const updateObjective = () => {
  if (objectiveInCurrentType.value) {
    if (consoType.value && form.yearlyObjectiveInWh !== null) {
      ObjectiveModule.updateObjectiveWh(
          Math.max(Math.round(form.yearlyObjectiveInWh), 1) * 1000,
      ).then(res => {
        Object.assign(form, res)
        form.yearlyObjectiveInWh = Math.round((form.yearlyObjectiveInWh ?? 0) / 1000);
        getSummaryConso(true);
      })
    } else {
      if (form.yearlyObjectiveInCurrency !== null) {
        ObjectiveModule.updateObjectiveCurrency(
            Math.max(Math.round(form.yearlyObjectiveInCurrency), 1)
        ).then(res => {
          Object.assign(form, res)
          form.yearlyObjectiveInWh = Math.round((form.yearlyObjectiveInWh ?? 0) / 1000);
          getSummaryConso(true);
        })
      }
    }
    Object.assign(initData, form);
  }
}

const editObjective = () => {
  if (editingObjective.value) {
    updateObjective();
  }
  editingObjective.value = !editingObjective.value;
}

const saveObjective = () => {
  updateObjective();
  editingObjective.value = false;
}

const cancelEdition = () => {
  if (form.yearlyObjectiveInWh !== null) {
    Object.assign({form}, initData)
  }
  editingObjective.value = !editingObjective.value;
}

const getSummaryConso = (forceRequest = false) => {
  ConsoModule.getConsumptionFullData({
    period: EAggregationAlias.Year,
    dates: props.currentYear.toString(),
    forceRequest
  }).then(conso => {
    _consos.value = conso!.dataPoints ?? [];
  })
}

const getCurrencyEstimation = async () => {
  form.yearlyObjectiveInCurrency = await ObjectiveModule.getCurrencyEstimation(Math.round((form.yearlyObjectiveInWh ?? 0) * 1000));
}

const getWhEstimation = async () => {
  form.yearlyObjectiveInWh = Math.round(fromWhToKwh(await ObjectiveModule.getWhEstimation(Math.round(form.yearlyObjectiveInCurrency ?? 0))));
}

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
}

const checkKeyDownNumeric = (event: any) => {
  const disallowedChar = [',', '.'];
  if (disallowedChar.includes(event.key)) event.preventDefault();
  if (event.target.value.length + 1 > 7 && event.keyCode !== 8) event.preventDefault();
}

const confirmDelete = () => {
  deleteObjective();
  showDeletePopin.value = false;
}

const deleteObjective = () => {
  ObjectiveModule.deleteObjetive().then(() => {
    getSummaryConso(true)
    emit('resetObjective');
    form.yearlyObjectiveInCurrency = form.yearlyObjectiveInWh = null;
  });
}


onBeforeMount(() => {
      if (ObjectiveModule.objective) {
        Object.assign(form, ObjectiveModule.objective!);
        form.yearlyObjectiveInWh = form.yearlyObjectiveInWh ? Math.round(fromWhToKwh(form.yearlyObjectiveInWh)) : null;

        Object.assign({initData}, form);
      }

      getSummaryConso();
    }
)
onBeforeUnmount(async () => {
  Object.assign(form, await ObjectiveModule.getObjective(true));
})

const onCurrencyChanged = (event: any) => {
  if (idTimeout.value) clearTimeout(idTimeout.value);
  if (event.target.value == 0 || event.target.val == "") return false;
  idTimeout.value = setTimeout(() => {
    form.yearlyObjectiveInCurrency = Math.round(event.target.value)
    getWhEstimation();
  }, 500)
}

const onWhChanged = (event: any) => {
  if (idTimeout.value) clearTimeout(idTimeout.value);
  if (event.target.value == 0 || event.target.val == "") return false;
  idTimeout.value = setTimeout(() => {
    getCurrencyEstimation();
    form.yearlyObjectiveInWh = Math.round(event.target.value)
  }, 500)
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(MyVPageWithReturn, {
      type: "popin",
      title: title.value,
      "return-action": closePopin,
      class: _normalizeClass(_ctx.$style.ObjectiveModal)
    }, {
      default: _withCtx(() => [
        (!_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass(_ctx.$style.newObjectiveDesc)
            }, _toDisplayString(_ctx.$t('objective.define_objective.desc')), 3))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.modalHeader)
        }, [
          _createVNode(MyVSwitch, {
            modelValue: consoType.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((consoType).value = $event)),
              setConsoType
            ],
            type: _unref(ESwitchType).Unit,
            unCheckedChildren: _unref(currency),
            checkedChildren: _unref(EEnergyUnit).kWh
          }, null, 8, ["modelValue", "type", "unCheckedChildren", "checkedChildren"])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.editHeader)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.$style.editHeaderContent)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.editObjectiveValue)
            }, [
              (!editingObjective.value && _ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style.values)
                  }, [
                    _cache[9] || (_cache[9] = _createElementVNode("svg", { "aria-hidden": "true" }, [
                      _createElementVNode("use", { "xlink:href": ['#icon-white-flag'] })
                    ], -1)),
                    (consoType.value)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(form.yearlyObjectiveInWh) + " " + _toDisplayString(_unref(EEnergyUnit).kWh), 1))
                      : (form.yearlyObjectiveInCurrency)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(formatCurrency)(form.yearlyObjectiveInCurrency)), 1))
                        : _createCommentVNode("", true)
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style.editValues)
                  }, [
                    (consoType.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.objectiveInput)
                        }, [
                          (_openBlock(), _createElementBlock("svg", {
                            "aria-hidden": "true",
                            class: _normalizeClass(_ctx.$style.editSvg)
                          }, _cache[10] || (_cache[10] = [
                            _createElementVNode("use", { "xlink:href": ['#icon-white-flag'] }, null, -1)
                          ]), 2)),
                          _createVNode(MyVAntdinput, {
                            onKeydown: _cache[1] || (_cache[1] = ($event: any) => (checkKeyDownNumeric($event))),
                            onKeyup: onWhChanged,
                            value: form.yearlyObjectiveInWh,
                            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.yearlyObjectiveInWh) = $event)),
                            "input-type": _unref(EInputType).NumberInput,
                            maxlength: 7
                          }, null, 8, ["value", "input-type"]),
                          _createElementVNode("span", null, _toDisplayString(_unref(EEnergyUnit).kWh), 1)
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(_ctx.$style.objectiveInput)
                        }, [
                          (_openBlock(), _createElementBlock("svg", {
                            "aria-hidden": "true",
                            class: _normalizeClass(_ctx.$style.editSvg)
                          }, _cache[11] || (_cache[11] = [
                            _createElementVNode("use", { "xlink:href": ['#icon-white-flag'] }, null, -1)
                          ]), 2)),
                          (_unref(isCurrencyBeforeText))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(currency)), 1))
                            : _createCommentVNode("", true),
                          _createVNode(MyVAntdinput, {
                            onKeydown: _cache[3] || (_cache[3] = ($event: any) => (checkKeyDownNumeric($event))),
                            onKeyup: _cache[4] || (_cache[4] = ($event: any) => (onCurrencyChanged($event))),
                            value: form.yearlyObjectiveInCurrency,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((form.yearlyObjectiveInCurrency) = $event)),
                            "default-value": form.yearlyObjectiveInCurrency,
                            "input-type": _unref(EInputType).NumberInput
                          }, null, 8, ["value", "default-value", "input-type"]),
                          (!_unref(isCurrencyBeforeText))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(currency)), 1))
                            : _createCommentVNode("", true)
                        ], 2))
                  ], 2)),
              ((form.yearlyObjectiveInCurrency || form.yearlyObjectiveInWh) && contractHasTarif.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style.editEquivalent)
                  }, _toDisplayString(_ctx.$t('objective.equivalent')) + " " + _toDisplayString(consoType.value ? _unref(currency) : _unref(EEnergyUnit).kWh) + " : " + _toDisplayString(consoType.value ? (form.yearlyObjectiveInCurrency ? _unref(formatCurrency)(form.yearlyObjectiveInCurrency) : '-') : `${(form.yearlyObjectiveInWh ?? '-')} ${_unref(EEnergyUnit).kWh}`), 3))
                : _createCommentVNode("", true),
              (editingObjective.value || !_ctx.isEditing)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(_ctx.$style.editButtons)
                  }, [
                    _createVNode(MyVButton, {
                      onClick: cancelEdition,
                      variant: "secondary",
                      size: _unref(EButtonSize).Small,
                      class: _normalizeClass(_ctx.$style.cancel)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('objective_modal.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["size", "class"]),
                    _createVNode(MyVButton, {
                      variant: "primary",
                      disabled: !form.yearlyObjectiveInWh || !form.yearlyObjectiveInCurrency,
                      onClick: saveObjective,
                      size: _unref(EButtonSize).Small,
                      class: _normalizeClass(_ctx.$style.validate)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('objective_modal.validate')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "size", "class"])
                  ], 2))
                : _createCommentVNode("", true)
            ], 2),
            (!editingObjective.value && _ctx.isEditing)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(_ctx.$style.editIcon),
                  onClick: editObjective
                }, [
                  _createVNode(MyVIcon, { name: "pen" })
                ], 2))
              : _createCommentVNode("", true)
          ], 2)
        ], 2),
        (_ctx.isEditing)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.$style.editGraph)
            }, [
              _createElementVNode("h2", null, _toDisplayString(`${_ctx.$t('objective_modal.graph.title')} ${_ctx.currentYear}`), 1),
              _createElementVNode("div", {
                class: _normalizeClass(editingObjective.value ? _ctx.$style.hasOpacity : '')
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.editGraphMain)
                }, [
                  (barLineConso.value)
                    ? (_openBlock(), _createBlock(MyVBarLineGraphic, _normalizeProps(_mergeProps({ key: 0 }, barLineConso.value)), null, 16))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.graphLegend)
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.objectifOk)
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendColor)
                      }, null, 2),
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendName)
                      }, _toDisplayString(_ctx.$t('objective_modal.legend.objective_ok')), 3)
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.objectifKo)
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendColor)
                      }, null, 2),
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendName)
                      }, _toDisplayString(_ctx.$t('objective_modal.legend.objective_ko')), 3)
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.objectifGoal)
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendColor)
                      }, null, 2),
                      _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style.legendName)
                      }, _toDisplayString(_ctx.$t('objective_modal.legend.objective_goal')), 3)
                    ], 2)
                  ], 2)
                ], 2),
                _createVNode(MyVButton, {
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (showDeletePopin.value = true)),
                  primary: true,
                  size: _unref(EButtonSize).Small,
                  class: _normalizeClass(_ctx.$style.deleteObjective)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('objective_modal.delete')), 1)
                  ]),
                  _: 1
                }, 8, ["size", "class"]),
                _createElementVNode("ul", {
                  class: _normalizeClass(_ctx.$style.objectiveModalList)
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(consos.value.values, (conso) => {
                    return (_openBlock(), _createBlock(ObjectiveModalItem, {
                      key: conso.date,
                      month: `${conso.date} ${_ctx.currentYear}`,
                      objective: conso.objective,
                      consumption: conso.conso,
                      unitIsEnergy: consoType.value
                    }, null, 8, ["month", "objective", "consumption", "unitIsEnergy"]))
                  }), 128))
                ], 2)
              ], 2)
            ], 2))
          : _createCommentVNode("", true),
        (showDeletePopin.value)
          ? (_openBlock(), _createBlock(MyVBottomPopin, {
              key: 2,
              onCancel: _cache[8] || (_cache[8] = ($event: any) => (showDeletePopin.value = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("svg", {
                    class: "icon icon-big-colored-delete-forever",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('objective.popin.delete')), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('objective.popin.subtitle')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(MyVButton, {
                      class: "BottomPopin__confirm",
                      onClick: confirmDelete
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.confirm')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(MyVButton, {
                      class: "BottomPopin__cancel",
                      primary: false,
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (showDeletePopin.value = false))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "class"])
  ]))
}
}

})