import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]

import {g_timeFormat} from "@/helpers/domains/dailyprogs";
import {ref} from "vue";
import dayjs, {Dayjs} from "dayjs";
import {useDate} from "@/helpers/dates/date-utils";
import {TTimepicker} from "@/components/ui/molecules/MyVTimepicker/interfaces";

export interface Props {
  inputRequired?: TTimepicker["inputRequired"];
  placeholder?: TTimepicker["placeholder"];
  id?: TTimepicker["id"];
  inlinelabel?: TTimepicker["inlinelabel"];
  minuteStep?: TTimepicker["minuteStep"];
  modelValue?: TTimepicker["value"];
  error?: TTimepicker["error"];
  change?: TTimepicker["change"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVTimepicker',
  props: {
    inputRequired: { type: Boolean, default: false },
    placeholder: { default: undefined },
    id: { default: undefined },
    inlinelabel: { default: undefined },
    minuteStep: { default: 15 },
    modelValue: { default: undefined },
    error: { default: undefined },
    change: { type: Function, default: undefined }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {


const props = __props

const emit = __emit
const value = ref<Dayjs>(dayjs(props.modelValue, g_timeFormat));

const handleChange = (value: string) => {
  emit('update:modelValue', useDate(value).format(g_timeFormat))
}


return (_ctx: any,_cache: any) => {
  const _component_a_time_picker = _resolveComponent("a-time-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, {
    class: "form__group myv-time-picker",
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      (_ctx.inlinelabel!==null)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.inlinelabel), 1)
          ], 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createVNode(_component_a_time_picker, {
        inputReadOnly: "",
        value: value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        format: _unref(g_timeFormat),
        "show-now": false,
        "minute-step": _ctx.minuteStep,
        placeholder: _ctx.placeholder,
        allowClear: false,
        onChange: handleChange
      }, null, 8, ["value", "format", "minute-step", "placeholder"])
    ]),
    _: 1
  }, 8, ["validate-status", "help"]))
}
}

})