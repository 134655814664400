import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {TCheckbox, TCheckboxValue} from "./interfaces";
import {ref, watch, onBeforeMount} from "vue";
import {arrayAreDiff} from "@/helpers/array/array";

export interface Props {
  defaultValue?: TCheckbox["defaultValue"];
  error?: TCheckbox["error"];
  options?: TCheckbox["options"];
  value?: TCheckbox["value"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCheckbox',
  props: {
    defaultValue: { default: () => [] },
    error: { default: undefined },
    options: { default: () => [] },
    value: { default: undefined }
  },
  emits: ['update:value'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const _value = ref<TCheckbox["value"]>([]);

onBeforeMount(() => {
  _value.value = props.value || props.defaultValue;
})

watch(_value, (newValue, oldValue) => {
  if (arrayAreDiff<TCheckboxValue>(newValue, oldValue)) {
    emit('update:value', [...newValue]);
  }
})

watch(() =>props.value, (newValue, oldValue) => {
  if (arrayAreDiff<TCheckboxValue>(newValue!, oldValue!)) {
    _value.value = newValue!;
  }
})


return (_ctx: any,_cache: any) => {
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, {
    class: "form__radio",
    "validate-status": _ctx.error ? 'error' : undefined,
    help: _ctx.error
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_checkbox_group, {
        value: _value.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_value).value = $event)),
        options: _ctx.options
      }, null, 8, ["value", "options"])
    ]),
    _: 1
  }, 8, ["validate-status", "help"]))
}
}

})