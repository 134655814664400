import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {TDropDown} from "./interfaces";
import {ref, onBeforeMount} from "vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";

interface Props {
  showCarret?: TDropDown['showCarret'];
  className?: TDropDown['className'];
  onOpen?: TDropDown['onOpen'];
  fullWidth?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDropDown',
  props: /*@__PURE__*/_mergeModels({
    showCarret: { type: Boolean, default: false },
    className: { default: undefined },
    onOpen: { type: Function, default: undefined },
    fullWidth: { type: Boolean, default: false }
  }, {
    "open": {default: false},
    "openModifiers": {},
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:open", "update:modelValue"],
  setup(__props: any) {


const props = __props

const openTimeout = ref(-1);
const open = _useModel(__props, 'open')
const model = _useModel(__props, "modelValue")

const toggleDropdown = () => {
  model.value = !model.value;
}

const close = () => {
  open.value = false;
  window.clearTimeout(openTimeout.value);
}

onBeforeMount(() => {
  document.addEventListener('closeDropDown', close)
})

return (_ctx: any,_cache: any) => {
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, {
    open: model.value,
    "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((model).value = $event)),
    class: "myv-dropdown",
    trigger: ['click']
  }, {
    overlay: _withCtx(() => [
      _renderSlot(_ctx.$slots, "overlay", { toggleDropdown: toggleDropdown })
    ]),
    default: _withCtx(() => [
      _createVNode(MyVButton, {
        "full-width": props.fullWidth,
        variant: "secondary",
        class: "myv-dropdown-button",
        icon: "carret-down",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["full-width"])
    ]),
    _: 3
  }, 8, ["open"]))
}
}

})