import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {Doughnut} from 'vue-chartjs'
import {TCircleGraphic} from "./interfaces";
import {roundNumber2} from "@/helpers/number/formatNumber";
import {computed} from "vue";
import {useResponsive} from "@/composables/useResponsive";
import {EEnergyUnit} from "@/helpers/constants/unit";
import {SwitchConsoModule} from "@/store";
import useCurrency from "@/composables/currency";

interface Props {
  items: TCircleGraphic["items"];
  texts?: TCircleGraphic["texts"];
  tooltip?: TCircleGraphic["tooltip"];
  plugins?: TCircleGraphic["plugins"];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVCircleGraphic',
  props: {
    items: { default: undefined },
    texts: { default: () => [] },
    tooltip: { default: undefined },
    plugins: {}
  },
  setup(__props: any) {

const props = __props

/*
const legends = ref<TCircleGraphicLegends>([]);
*/
const {isTabletMax} = useResponsive()
const {formatCurrency} = useCurrency()

const centerTitle = computed(() => {
  return `${props.texts[0][0]?.value}${props.texts[0][1]?.value}`
})

const datas = computed(() => {
  const datas: number[] = [];
  const backgroundsColor: string[] = [];
  const labels: string[] = [];
  //legends.value = []

  props.items.forEach(({label, value, color}) => {
    labels.push(label);
    datas.push(value);
    backgroundsColor.push(color);
  });

  return {
    datasets: [
      {
        data: datas.map(roundNumber2),
        backgroundColor: backgroundsColor,
        hoverBackgroundColor: backgroundsColor,
        borderWidth: 2,
        borderRadius: 10,
        borderColor: isTabletMax.value ? "#f4f5f7" : '#E4E8EF',
        circumference: 360,
        rotation: 0,
      },
    ],
    labels: labels,
  }
})

const options = computed(() => {
  return {
    type: 'doughnut',
    responsive: true,
    cutout: isTabletMax.value ? '50%' : '60%',
    aspectRatio: isTabletMax.value ? 1 : Array.isArray(props.tooltip) ? 1.6 : props.tooltip ? 2 : 1,
    plugins: {
      ...props.plugins,
      centerText: {
        text: centerTitle.value,
        fontWeight: 'bold',
        fontSize: isTabletMax ? 20 : 30,
        fontColor: '#3E3E7C',
      },
      datalabels: {
        display: 'auto',
        borderWidth: 0,
        color: 'white',
        textAlign: 'center',
        font: {
          size: 16,
          weight: 'bold'
        },
        padding: 8,
        formatter: (value: number) => {
          if (SwitchConsoModule.switchType) {
            return [Math.round(value), EEnergyUnit.kWh];

          } else {
            return `${formatCurrency(Math.round(value))}`;
          }
        }
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded'
        }
      }
    },
    layout: {
      padding: 0
    },
    tooltip: props.tooltip,
    texts: props.texts
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Doughnut), {
    data: datas.value,
    options: options.value
  }, null, 8, ["data", "options"]))
}
}

})