import { ChartType, Plugin } from 'chart.js';

interface CenterTextPluginOptions {
    text: string;
    fontSize?: number;
    fontColor?: string;
    fontWeight?: string; // New font weight option
}

const centerText: Plugin<ChartType> = {
    id: 'centerText',
    beforeDraw(chart, args, options) {
        const { ctx } = chart;
        const centerText = options as CenterTextPluginOptions;

        if (centerText?.text) {
            const text = centerText.text;
            const fontSize = centerText.fontSize || 16;
            const fontColor = centerText.fontColor || '#000';
            const fontWeight = centerText.fontWeight || 'normal'; // Default to 'normal' if not provided

            // Calculate the center point
            const { top, left, right, bottom } = chart.chartArea;
            const xCenter = (left + right) / 2;
            const yCenter = (top + bottom) / 2;

            if (ctx) {
                ctx.save();
                ctx.font = `${fontWeight} ${fontSize}px sans-serif`; // Include font weight in the font string
                ctx.fillStyle = fontColor;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(text, xCenter, yCenter);
                ctx.restore();
            }
        }
    },
};

// Export the plugin for use
export default centerText;
