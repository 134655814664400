import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "default-contract-popin-buttons" }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {Checkbox} from "ant-design-vue";
import {onBeforeUnmount, ref} from "vue";
import ERoutes from "@/router/routes";
import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import Cookie from "@/helpers/cookie";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {useResponsive} from "@/composables/useResponsive";
import {useStorageKeys} from "@/composables/useStorageKeys";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVDefaultContractPopin',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const dontShowReminderAgain = ref(false)
const {isMobile} = useResponsive()
const {defaultContrat} = useStorageKeys()

const closeReminder = () => {
  pageScroll(true);
  if (dontShowReminderAgain.value) {
    Cookie.set(defaultContrat.value, '1');
  }
  emit('close')
}

onBeforeUnmount(() => {
  emit('close')
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(isMobile) ?  MyVBottomPopin : MyVPageWithReturn), {
    title: _ctx.$t('page.contracts.title'),
    class: "default-contract-popin",
    type: "popin",
    onCancel: _cache[1] || (_cache[1] = () =>emit('close')),
    "return-action": () =>emit('close')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_i18n_t, {
        keypath: "profile.contract.defaultContract.new",
        tag: "p"
      }, {
        contractLink: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: _unref(ERoutes).CONTRACT
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('profile.contract.defaultContract.new.link')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVButton, { onClick: closeReminder }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("OK")
          ])),
          _: 1
        })
      ]),
      _createVNode(_unref(Checkbox), {
        class: "dontAskAgain",
        checked: dontShowReminderAgain.value,
        "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((dontShowReminderAgain).value = $event))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('prog_reminder.dont_ask_again')), 1)
        ]),
        _: 1
      }, 8, ["checked"])
    ]),
    _: 1
  }, 40, ["title", "return-action"]))
}
}

})