import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "MultiStep__main" }
const _hoisted_2 = { class: "fieldset__inner" }
const _hoisted_3 = { class: "MultiStep__footer inner" }

import {computed, onBeforeMount, reactive, ref, watch} from "vue";

import {checkPasswordSecurity, containsSpecialChars, hasUpper} from '@/helpers/string/password';

import {TPasswordStep} from '../interfaces';
import {AuthModule} from "@/store";
import ERoutes from "@/router/routes";
import {useRoute, useRouter} from "vue-router";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import {EButtonType} from "@/components/ui/atoms/MyVButton/interfaces";
import MyVAntdinput from "@/components/ui/atoms/MyVAntdinput/MyVInput.vue";
import {EInputType} from "@/components/ui/atoms/MyVAntdinput/interfaces";
import MyVFieldset from "@/components/ui/atoms/MyVFieldset/MyVFieldset.vue";
import MyVPasswordHelper from "@/components/ui/organisms/MyVPasswordHelper/MyVPasswordHelper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVPasswordStep',
  props: {
    next: { type: Function },
    prev: { type: Function }
  },
  setup(__props: any) {

const props = __props

const form = reactive({
  password: '',
  confirmPassword: '',
})

const passwordIsValid = ref(false);
const passwordHasUpper = ref(false);
const passwordHasHeightCaracters = ref(false);
const passwordHasSpecialCaracter = ref(false);
const confirmPasswordIsValid = ref(true);

const router = useRouter()
const route = useRoute()

const token = computed(() => {
  return route.query?.token as string;
})

const subscriberId = computed(() => {
  return route.query?.subscriberId as any;
})

const updateConfirmPasswordIsValid = (password: string, confirmPassword: string) => {
  confirmPasswordIsValid.value = confirmPassword === password;
}

const onFinish = () => {
  if (passwordIsValid.value && confirmPasswordIsValid.value) {
    AuthModule.createOnboardingPassword({
      newPassword: form.password,
      token: token.value,
      subscriberId: subscriberId.value
    })
        .then((data) => AuthModule.tryConnectUserFromToken(data.token))
        .then((data) => {
          if (!data) {
            return router.push(ERoutes.RESET_EMAIL_TOKEN);
          }
          props.next();
        })
        .catch(() => {
          router.push(ERoutes.RESET_EMAIL_TOKEN);
        });
  }
}

onBeforeMount(() => {
  updateConfirmPasswordIsValid(form.password, form.confirmPassword);
})

watch(() => form.password, (newValue) => {
  passwordHasHeightCaracters.value = newValue.length >= 8
  passwordHasUpper.value = hasUpper(newValue)
  passwordHasSpecialCaracter.value = containsSpecialChars(newValue)
  passwordIsValid.value = checkPasswordSecurity(newValue);
}, {deep: true})

watch(() => form.confirmPassword, (newValue) => {
  updateConfirmPasswordIsValid(form.password, newValue);

}, {deep: true})

return (_ctx: any,_cache: any) => {
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    model: form,
    onFinish: onFinish,
    class: "MultiStep__form password-step"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(MyVFieldset, {
          title: _ctx.$t('firstConnection.password')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(MyVAntdinput, {
                label: _ctx.$t('form.password'),
                inputType: _unref(EInputType).Password,
                value: form.password,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.password) = $event)),
                id: "password",
                class: "password-input",
                inputRequired: true
              }, null, 8, ["label", "inputType", "value"]),
              _createVNode(MyVAntdinput, {
                label: _ctx.$t('form.confirm_password'),
                inputType: _unref(EInputType).Password,
                value: form.confirmPassword,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.confirmPassword) = $event)),
                id: "confirm_password",
                inputRequired: true,
                error: confirmPasswordIsValid.value ? undefined : _ctx.$t('firstConnection.confirm_password_error')
              }, null, 8, ["label", "inputType", "value", "error"]),
              _createVNode(MyVPasswordHelper, {
                "password-has-upper": passwordHasUpper.value,
                "password-has-special-caracter": passwordHasSpecialCaracter.value,
                "password-has-height-caracters": passwordHasHeightCaracters.value
              }, null, 8, ["password-has-upper", "password-has-special-caracter", "password-has-height-caracters"])
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(MyVButton, {
          primary: false,
          type: _unref(EButtonType).Submit,
          disabled: !(form.confirmPassword.length && passwordIsValid.value && confirmPasswordIsValid.value)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('button.first_connection_password_form')), 1)
          ]),
          _: 1
        }, 8, ["type", "disabled"])
      ])
    ]),
    _: 1
  }, 8, ["model"]))
}
}

})