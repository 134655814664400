import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "popinForm" }
const _hoisted_2 = { class: "popinForm__main" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "Choices__block__content__header" }
const _hoisted_5 = { class: "Choices__block__content__header" }
const _hoisted_6 = { class: "BottomPopin__text" }
const _hoisted_7 = { class: "BottomPopin__title" }
const _hoisted_8 = { class: "BottomPopin__subtitle popinSub" }
const _hoisted_9 = { class: "BottomPopin__buttons" }

import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import ERoutes from "@/router/routes";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import API from "@/helpers/api";
import {EManageChoicesSections, TManageChoices} from "@/views/domains/Profile/ManageChoices/interfaces";
import MyVErrorBanner from "@/components/ui/atoms/MyVErrorBanner/MyVErrorBanner.vue";
import MyVBottomPopin from "@/components/ui/atoms/MyVBottomPopin/MyVBottomPopin.vue";
import {AuthModule, SiteModule} from "@/store";
import {getCurrentSiteIdFromCookie} from "@/helpers/domains/site";
import {computed, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import {useResponsive} from "@/composables/useResponsive";
import MyVLockedOverlay from "@/components/ui/molecules/MyVLockedOverlay/MyVLockedOverlay.vue";
import {useI18n} from "vue-i18n";
import MyVCustomSwitch from "@/components/ui/atoms/MyVSwitches/MyVCustomSwitch.vue";
import {ESwitchSize} from "@/components/ui/atoms/MyVSwitches/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'ManageChoicesView',
  setup(__props) {

const choices = ref<TManageChoices[]>([])
const error = ref<string | null>(null)
const showBottomPopin = ref(false);
const resetSwitch = ref(0)
const selectedChoice = ref<any | null>(null)

const route = useRoute()
const {isMobile} = useResponsive()
const {t} = useI18n();

const user = computed(() => {
  return AuthModule.user;
})

const hasBasicOffer = computed(() => SiteModule.hasBasicOffer)

const filteredChoices = computed(() => {
  if (hasBasicOffer.value) {
    return [...choices.value]!.map((choice: TManageChoices) => {
      return {
        ...choice,
        subcategories: choice.subcategories.filter((sub) => sub.nameKey.includes('ecowatt')),
        htmlId: choice.categoryKey
      }
    })

  }
  return choices.value
})

const mockedSubcategories = computed(() => {
  return [
    {
      nameKey: t('consent.notif-and-alert.exceed-alert.name'),
      enabled: true,
      descriptionKey: t('consent.notif-and-alert.exceed-alert.description'),
    },
    {
      nameKey: t('consent.notif-and-alert.notifications.name'),
      enabled: true,
      descriptionKey: t('consent.notif-and-alert.notifications.description'),
    }
  ]
})

const cancelConsent = () => {
  resetSwitch.value++
  showBottomPopin.value = false;
}

const updateConsent = (val: boolean, index: number, ind: number) => {
  const payload = (JSON.parse(JSON.stringify(choices.value)) as TManageChoices[]);
  const nameKey = payload[index].subcategories[ind].nameKey

  selectedChoice.value = {
    nameKey,
    val, index, ind
  }

  if (!val) {
    showBottomPopin.value = true;
    return;
  }

  saveConsent(val, index, ind)
}

const saveConsent = (val: boolean, index: number, ind: number) => {
  showBottomPopin.value = false;

  const payload = (JSON.parse(JSON.stringify(choices.value)) as TManageChoices[]);
  const body = [{
    nameKey: payload[index].subcategories[ind].nameKey,
    enabled: val
  }]

  choices.value[index].subcategories[ind].enabled = val

  API.put(`/api/site/${getCurrentSiteIdFromCookie(user.value)}/user/consent`, body)
      .then(() => initChoices())
      .catch(e => {
        error.value = e.response ? e.response.data.message : 'Erreur serveur';
      })
}

const initChoices = () => {
  API.get(`/api/site/${getCurrentSiteIdFromCookie(user.value)}/user/consent`).then(({data}) => {
    choices.value = data!.map((choice: TManageChoices) => {
      return {
        ...choice,
        subcategories: choice.subcategories.filter((sub) => {
          if (sub.nameKey.includes('enedis')) {
            if (sub.enabled) return null;
          }
          return sub;
        }),
        htmlId: choice.categoryKey
      }
    })
  }).catch(e => {
    error.value = e.response ? e.response.data.message : 'Erreur serveur';
  })
}

onBeforeMount(() => {
  initChoices();
  setTimeout(() => {
    if (route.query.scroll) {
      const element = document.getElementById(EManageChoicesSections.DatasCollect);
      const position = element?.getBoundingClientRect();
      const page = document.getElementsByClassName('mobilePopin');

      if (isMobile.value) {
        page![0]!.scrollTo(0, position!.top - 60);
      } else {
        window.scrollTo(0, position!.top - 60)
      }
    }
  }, 500)

})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(MyVPageWithReturn, {
      title: _ctx.$t('profile.manage_choices.title'),
      type: "page",
      "return-action": _unref(ERoutes).HOME,
      class: "Choices",
      id: "manageChoices"
    }, {
      default: _withCtx(() => [
        (error.value)
          ? (_openBlock(), _createBlock(MyVErrorBanner, {
              key: 0,
              error: error.value,
              class: "error-banner",
              onClose: _cache[0] || (_cache[0] = ($event: any) => (error.value=null))
            }, null, 8, ["error"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredChoices.value, (choice, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "Choices__block",
                key: index
              }, [
                (choice.subcategories.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        id: choice.htmlId
                      }, null, 8, _hoisted_3),
                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t(choice.categoryKey)), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(choice.subcategories, (sub, ind) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "Choices__block__content",
                          key: ind
                        }, [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("h3", null, _toDisplayString(_ctx.$t(sub.nameKey)), 1),
                            (_openBlock(), _createBlock(MyVCustomSwitch, {
                              key: resetSwitch.value,
                              value: sub.enabled,
                              "default-value": sub.enabled,
                              size: _unref(ESwitchSize).Big,
                              onChange: ($event: any) => (updateConsent($event, index, ind))
                            }, null, 8, ["value", "default-value", "size", "onChange"]))
                          ]),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t(sub.descriptionKey)), 1)
                        ]))
                      }), 128))
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (hasBasicOffer.value)
              ? (_openBlock(), _createBlock(MyVLockedOverlay, {
                  key: 0,
                  description: _ctx.$t('subscriberOffer.locked.messages.notifications')
                }, {
                  content: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mockedSubcategories.value, (mockedSub, ind) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "Choices__block__content",
                        key: ind
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(mockedSub.nameKey)), 1),
                          _createVNode(MyVCustomSwitch, {
                            value: mockedSub.enabled
                          }, null, 8, ["value"])
                        ]),
                        _createElementVNode("p", null, _toDisplayString(_ctx.$t(mockedSub.descriptionKey)), 1)
                      ]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["description"]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "return-action"]),
    (showBottomPopin.value)
      ? (_openBlock(), _createBlock(MyVBottomPopin, {
          key: 0,
          onCancel: _cache[2] || (_cache[2] = ($event: any) => (showBottomPopin.value = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _cache[3] || (_cache[3] = _createElementVNode("svg", {
                class: "icon icon-big-colored-delete-forever",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('profile.manage_choices.popin.delete')), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t(`removal.${selectedChoice.value.nameKey}`)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(MyVButton, {
                  class: "BottomPopin__confirm",
                  onClick: cancelConsent
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.manage_choices.popin.cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(MyVButton, {
                  class: "BottomPopin__cancel",
                  primary: false,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (saveConsent(selectedChoice.value.val, selectedChoice.value.index, selectedChoice.value.ind)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('profile.manage_choices.popin.confirm')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})