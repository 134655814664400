import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "Historic__list" }
const _hoisted_2 = {
  key: 0,
  class: "Historic__seeMore"
}

import ConsoListItem from "@/components/domains/Conso/MyVConsoListItem/MyVConsoListItem.vue";
import {EAggregationAlias, labelMonthsFormat, TSummaryConsumption} from "@/services/Conso/interfaces";
import ERoutes from "@/router/routes";
import {EEnergyUnit, fromWhToKwh} from "@/helpers/constants/unit";
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import {AuthModule, ConsoModule, IntlModule, ObjectiveModule, SiteModule, SwitchConsoModule} from "@/store";
import MyVDisplayGroupStore from "@/components/ui/molecules/MyVDisplayGroup/MyVDisplayGroupStore.vue";
import {TSite} from "@/services/Site/interfaces";
import {getCurrentSiteIdFromCookie, getCurrentSiteObjectFromCookie} from "@/helpers/domains/site";
import {EDisplayGroupRightName} from "@/services/DisplayGroup/interfaces";
import {computed, onBeforeMount, ref} from "vue";
import {updateSwitchConso} from "@/helpers/domains/switchConso";
import {useDate} from "@/helpers/dates/date-utils";
import useCurrency from "@/composables/currency";
import MyVSwitch from "@/components/ui/atoms/MyVSwitches/MyVSwitch.vue";
import {ESwitchType} from "@/components/ui/atoms/MyVSwitches/interfaces";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyVHistoric',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const emit = __emit
const {currency} = useCurrency()

const historicData = ref<TSummaryConsumption[]>([]);
const lastLoadedYear = ref(useDate().year().toString());

const mappedData = computed(() => {
  const unit = EEnergyUnit.kWh;

  return {
    unit,
    datas: historicData.value!.map(data => {
      const goal = Math.round(consoType.value ? fromWhToKwh(data.objectiveInWh || 0) : data.objectiveInCurrency || 0);
      const value = Math.round(consoType.value ? fromWhToKwh(data.totalConsumptionInWh || 0) : data.totalConsumptionInCurrency || 0);
      const dataUnit = consoType.value ? unit : currency.value;
      const link = `${ERoutes.CONSO}?date=${useDate(data.stepTimestampOnSite).format('YYYY-MM')}&type=${ERoutes.CONSO_MONTH}`;
      const monthLabel = useDate(data.stepTimestampOnSite).locale(IntlModule.locale).format(labelMonthsFormat);
      const date = data.stepTimestampOnSite

      return {goal, value, unit: dataUnit, link, monthLabel, date};
    }),
  };
});

const historyItems = computed(() => {
  return mappedData.value.datas.slice().filter(item => useDate(item.date).isSameOrAfter(startDate.value.toDate(), 'day')).sort((a, b) => useDate(b.date).unix() - useDate(a.date).unix())
})

const showLoadMoreData = computed(() => {
  const dates = historicData.value.map(historicItem => useDate(historicItem.stepTimestampOnSite, 'YYYY-MM-dd'))

  return !dates.some(date => useDate(startDate.value).isSameOrAfter(date.toDate()));
})

const currentSiteId = computed((): TSite['id'] => {
  return getCurrentSiteIdFromCookie(AuthModule.user);
})

const consoType = computed(() => {
  return SwitchConsoModule.switchType
})

const startDate = computed(() => {
  return useDate(currentSite.value.dataStart, 'YYYY-MM-dd');
})

const currentSite = computed((): TSite => {
  return getCurrentSiteObjectFromCookie(currentSiteId.value);
})

const setConsoType = (val: boolean) => {
  updateSwitchConso(val)
      .then((res) => {
        if (res) {
          updateSwitchConso(val);
        } else {
          updateSwitchConso(true);
        }
      })
}

const closePage = () => {
  emit("close");
}
const fetchConsumptionData = async (incrementDate = false) => {
  if (incrementDate) {
    lastLoadedYear.value = useDate(lastLoadedYear.value).substract(1, 'year').year().toString();
  }
  ConsoModule.getConsumptionFullData({
    period: EAggregationAlias.Year,
    dates: lastLoadedYear.value,
  }).then(res => {
    res!.dataPoints?.forEach(data => {
      if (!useDate(data.stepTimestampOnSite).isAfter(new Date())) {
        historicData.value.push(data);
      }
    });
  });
}

onBeforeMount(async () => {
  await Promise.all([fetchConsumptionData(), fetchConsumptionData(true), ObjectiveModule.getObjective(), SiteModule.getSites()])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(MyVPageWithReturn, {
    type: "popin",
    "return-action": closePage,
    title: _ctx.$t('historic.title'),
    class: "Historic"
  }, {
    headerRight: _withCtx(() => [
      _createVNode(MyVDisplayGroupStore, {
        currentRight: _unref(EDisplayGroupRightName).MY_CONTRACT
      }, {
        default: _withCtx(() => [
          _createVNode(MyVSwitch, {
            modelValue: consoType.value,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((consoType).value = $event)),
              setConsoType
            ],
            type: _unref(ESwitchType).Unit,
            unCheckedChildren: _unref(currency),
            checkedChildren: _unref(EEnergyUnit).kWh
          }, null, 8, ["modelValue", "type", "unCheckedChildren", "checkedChildren"])
        ]),
        _: 1
      }, 8, ["currentRight"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(historyItems.value, (historyItem, index) => {
          return (_openBlock(), _createBlock(ConsoListItem, {
            key: index,
            class: "Historic__item",
            title: historyItem.monthLabel,
            goal: historyItem.goal,
            consoValue: historyItem.value,
            unit: historyItem.unit,
            link: historyItem.link
          }, null, 8, ["title", "goal", "consoValue", "unit", "link"]))
        }), 128)),
        (showLoadMoreData.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("button", {
                type: "button",
                class: "Historic__seeMore-btn",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (fetchConsumptionData(true)))
              }, [
                _cache[2] || (_cache[2] = _createElementVNode("svg", {
                  class: "icon icon-calendar-event-create",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("use", { "xlink:href": "#icon-calendar-event-create" })
                ], -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('global.showMore')), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}
}

})