import {createApp} from "vue";

import App from "./App.vue";
import Antd from 'ant-design-vue/es';
import 'ant-design-vue/dist/reset.css';
import 'round-slider/dist/roundslider.min.css';
import '@/assets/icomoon/style.css';
import '@/scss/style.scss';

import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";

import '@/chart';
import i18n from './i18n';
import { install } from 'resize-observer'

if (!window.ResizeObserver) {
    install()
}

const app = createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(Antd)
    .use(VueGtag, {
        config: {
            id: "UA-47640629-2"
        }
    }, router)
    .directive('nl2br', el => {
        el.innerHTML = el.innerHTML.replace(/\r?\n/g, '<br />');
    })

Sentry.init({
    app,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({router}),
    ],
});

app.mount("#app")