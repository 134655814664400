import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "ProgramPlanningEditWrapper" }
const _hoisted_2 = { class: "mobilePopin" }
const _hoisted_3 = { class: "ProgramPlanningEdit__menuDays" }
const _hoisted_4 = { class: "ProgramPlanningEdit__planning" }
const _hoisted_5 = { class: "ProgramPlanningEdit__planning-title" }
const _hoisted_6 = { class: "ProgramPlanningEdit" }
const _hoisted_7 = { class: "ProgramPlanningEdit__footer" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "mobilePopin"
}
const _hoisted_10 = {
  key: 1,
  class: "mobilePopin"
}
const _hoisted_11 = { class: "popinForm" }
const _hoisted_12 = { class: "popinForm__footer" }
const _hoisted_13 = { class: "ProgramPlanningEdit__planning" }
const _hoisted_14 = { class: "ProgramPlanningEdit__planning-title" }

import ERoutes from "@/router/routes";
import {IntlModule, ProgramModule} from '@/store';
import MyVPageWithReturn from "@/components/ui/organisms/layouts/MyVPageWithReturn/MyVPageWithReturn.vue";
import MyVButton from "@/components/ui/atoms/MyVButton/MyVButton.vue";
import PlanningTimeline from "@/components/domains/Heating/programs/MyVPlanningTimeline/MyVPlanningTimeline.vue";
import PlanningBar from "@/components/domains/Heating/programs/MyVPlanningBar/MyVPlanningBar.vue";
import MyVMenuSlider from "@/components/ui/molecules/MyVMenuSlider/MyVMenuSlider.vue";
import {TMenuSliderOption} from "@/components/ui/molecules/MyVMenuSlider/interfaces";

import {
  fillDailyProgSegments,
  g_defaultMode,
  g_defaultTemperature,
  g_firstDayTime,
  g_lastDayTime
} from "@/helpers/domains/dailyprogs";

import EditRange from "@/components/domains/Heating/MyVEditRange/MyVEditRange.vue";
import PopinListCheck from '@/components/ui/organisms/MyVPopinListCheck/MyVPopinListCheck.vue';
import {TPopinListCheckDatas, TPopinListCheckItem} from "@/components/ui/organisms/MyVPopinListCheck/interfaces";

import {pageScroll} from "@/helpers/domains/ui/pageScroll";
import {TEditRangeDatasSubmit} from "@/components/domains/Heating/MyVEditRange/interfaces";
import {AxiosError} from "axios";
import {TDailyProgs} from "@/services/Program/interfaces";
import {computed, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {useDate} from "@/helpers/dates/date-utils";
import {useResponsive} from "@/composables/useResponsive";
import {notification} from "ant-design-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramPlanningEditView',
  setup(__props) {

const isOpenPopinDelete = ref(false);
const rangeIndex = ref<number | undefined>(-1);
const isOpenPopinEditRangeForm = ref(false);
const isOpenPopinDuplicatePlanning = ref(false);
const route = useRoute()
const router = useRouter()
const {t} = useI18n()
const {isMobile} = useResponsive();
const backLink = computed(() => {
  return `${ERoutes.PROGRAM}/${route.params.id}/${ERoutes.ADDON_PLANNING}`;
})

const editLink = computed(() => {
  return `${ERoutes.PROGRAM}/${route.params.id}/${ERoutes.ADDON_PLANNING}/${route.params.idAppliance}/${ERoutes.ADDON_EDIT}/${day.value}/${ERoutes.ADDON_RANGE}`;
})

const formatedName = computed(() => {
  let formatedTitle = planning.value!.nameAppliance!;
  if (formatedTitle && planning.value!.nameAppliance!.length > 50) {
    formatedTitle = "<div title='" + formatedTitle + "''>" + formatedTitle.slice(0, 50) + "..." + "</div>";
  }
  return formatedTitle;
})

const title = computed((): string => {
  return `
            <div class="ProgramPlanningEdit__titleDesktop">
                ${useDate().locale(locale.value).isoWeekday(day.value).format('dddd')}
            </div>
            <div class="ProgramPlanningEdit__titleMobile">
                ${t('programPlanning.details')}
            </div>
        `;
})

const subtitle = computed((): string => {
  return (planning.value?.nameAppliance) ? `
            <div class="ProgramPlanningEdit__titleMobile">
                ${formatedName.value}
            </div>
        ` : '';
})

const day = computed((): number => {
  return parseInt(route.params.day as string) as number;
})

const getIdAppliance = computed((): number => {
  return parseInt(route.params.idAppliance as string);
})

const locale = computed(() => {
  return IntlModule.locale;
})

const programId = computed(() => {
  return parseInt(route.params.id as string);
})

const program = computed(() => {
  return ProgramModule.getProgramById(programId.value);
})

const planning = computed(() => {
  return program.value?.plannings!.find(({idAppliance}) => idAppliance === getIdAppliance.value);
})

const dailyprog = computed(() => {
  return planning.value && planning.value.dailyprogs ? fillDailyProgSegments(planning.value.dailyprogs) : [];
})

const planningBarData = computed(() => {
  const currentDayPlanning = {...dailyprog.value.find(({dayNumber}) => dayNumber === day.value)};

  if (currentDayPlanning.ranges) {
    currentDayPlanning!.ranges!.forEach(range => {
      (range as any).duration = useDate().diffTime(range.to, range.from);
    });
  }

  return currentDayPlanning;
})

const planningBarDataMerged = computed(() => {
  const currentDayPlanning = fillDailyProgSegments([{...dailyprog.value.find(({dayNumber}) => dayNumber === day.value)!}], undefined, {
    mergeSlot: true
  })[0];

  currentDayPlanning.ranges.forEach(range => {
    (range as any).duration = useDate().diffTime(range.to, range.from);
  });

  return currentDayPlanning;
})

const sliderOptions = computed(() => {
  return dailyprog.value.map(({dayNumber}) => ({
    label: capitalizeFirstLetter(useDate().isoWeekday(dayNumber).locale(locale.value).format('dddd')),
    labelNotActive: capitalizeFirstLetter(useDate().isoWeekday(dayNumber).locale(locale.value).format('dd')),
    value: dayNumber
  }) as TMenuSliderOption);
})

const currentDailyprog = computed(() => {
  return planning.value ? fillDailyProgSegments(planning.value.dailyprogs).find(({dayNumber}) => dayNumber === day.value) : undefined;
})

const rangeIsSelected = computed(() => {
  return typeof rangeIndex.value === 'number' && rangeIndex.value !== -1;
})

const defaultRange = computed(() => {
  return {
    start_hour: undefined,
    end_hour: undefined,
    mode: g_defaultMode,
    temperature: g_defaultTemperature
  }
})

const popinRangeFormTitle = computed((): string => {
  return t(rangeIndex.value === -1 ? 'ProgramPlanningRangeForm.add_time_slot' : 'ProgramPlanningRangeForm.modify_time_slot');
})

const popinRangeFormSubtitle = computed((): string => {
  return `<div class="ProgramPlanningEdit__titleMobile">${planning.value!.nameAppliance}</div>`;
})

const otherDays = computed(() => {
  return dailyprog.value.map(({id, dayNumber}) => ({
    name: useDate().isoWeekday(dayNumber).locale(locale.value).format('dddd'),
    id,
    enable: dayNumber !== day.value
  } as TPopinListCheckItem));
})

const invalidModeError = (err: AxiosError) => {
  if (err.request.response.includes("invalidmode")) {
    closePopinRangeForm();
        return notification.error({
          message: t('planning.edit.invalid_mode.title'),
          description: t('planning.edit.invalid_mode.desc'),
          duration: 5
        });
  }
}

const menuLink = (value: TMenuSliderOption['value']) => {
  return `${ERoutes.PROGRAM}/${route.params.id}/${ERoutes.ADDON_PLANNING}/${route.params.idAppliance}/${ERoutes.ADDON_EDIT}/${value}`;
}

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const openPopinRangeForm = (_: any, index: number) => {
  rangeIndex.value = index;
  isOpenPopinEditRangeForm.value = true;
  pageScroll(false);
}

const closePopinRangeForm = () => {
  rangeIndex.value = undefined;
  isOpenPopinEditRangeForm.value = false;
  pageScroll(true);
}

const setCurrent = (value: TMenuSliderOption['value']) => {
  route.params.day = value as string
  router.push({params: route.params})
}

/*
TODO refactoring needed
*/
const submitRangeForm = (formDatas: TEditRangeDatasSubmit) => {
  const dailyprogs: TDailyProgs = JSON.parse(JSON.stringify(planning.value!.dailyprogs));
  ProgramModule.updateProgramPlanningDailyProgs(fillDailyProgSegments(
      rangeIndex.value !== undefined && rangeIndex.value !== -1 ?
          dailyprogs.map((dailyprog, _, dailyprogs) => {
            if (dailyprog.dayNumber === day.value && dailyprog.ranges.length > 1) {
              let rangeIndexValue = rangeIndex.value!;
              const removeRange = dailyprog.ranges[rangeIndexValue];
              let prevRange;
              let nextRange;
              let createNewLastRange = false;

              if (rangeIndexValue === 0) {
                const prevDay = dailyprogs.find(({dayNumber}) => dayNumber === (dailyprog.dayNumber === 1 ? 7 : (dailyprog.dayNumber - 1)))!;

                dailyprog.ranges.unshift({
                  ...prevDay.ranges[prevDay.ranges.length - 1],
                  from: g_firstDayTime
                });
                prevRange = dailyprog.ranges[0];
                rangeIndexValue += 1;
              } else {
                prevRange = dailyprog.ranges[rangeIndexValue - 1];
              }

              if (rangeIndexValue === dailyprog.ranges.length - 1) {
                const nextDay = dailyprogs.find(({dayNumber}) => dayNumber === (dailyprog.dayNumber === 7 ? 1 : (dailyprog.dayNumber + 1)))!;

                dailyprog.ranges.push({
                  ...nextDay.ranges[0],
                  to: g_lastDayTime
                });
                nextRange = dailyprog.ranges[dailyprog.ranges.length - 1];
                createNewLastRange = true;
              } else {
                nextRange = dailyprog.ranges[rangeIndexValue + 1];
              }

              if (formDatas.start_day === day.value &&
                  formDatas.start_hour >= removeRange.from &&
                  formDatas.start_hour < removeRange.to) {
                prevRange.to = formDatas.start_hour;
              } else {
                prevRange.to = removeRange.to;
              }

              if (formDatas.end_day === day.value &&
                  formDatas.end_hour <= removeRange.to &&
                  formDatas.end_hour > removeRange.from) {
                nextRange.from = formDatas.end_hour;
              } else if (createNewLastRange) {
                nextRange.from = removeRange.to;
              }

              dailyprog.ranges.splice(rangeIndexValue, 1);
            }
            return dailyprog;
          }) : planning.value!.dailyprogs,
      [{
        from: {
          dayNumber: formDatas.start_day,
          time: formDatas.start_hour
        },
        to: {
          dayNumber: formDatas.end_day,
          time: formDatas.end_hour
        },
        mode: formDatas.mode,
        isOn: formDatas.isOn,
        temperature: formDatas.temperature
      }], {mergeOnlyNewSlot: true}))
      .then(() => {
        closePopinRangeForm();
      }).catch(err => {
    invalidModeError(err);
  });
}

const range = () => {
  return rangeIsSelected.value && currentDailyprog.value?.ranges ? currentDailyprog.value?.ranges[rangeIndex.value!] : undefined;
}

const openPopinDelete = () => {
  isOpenPopinDelete.value = true;
  document.dispatchEvent(new Event("closeDropDownMenu"));
  pageScroll(false);
}

const closePopinDelete = () => {
  isOpenPopinDelete.value = false;
  pageScroll(true);
}

const deleteDailyProg = () => {
  if (rangeIsSelected.value) {
    ProgramModule.updateProgramPlanningDailyProgs(fillDailyProgSegments(
        planning.value!.dailyprogs.map(dailyprog => {
          if (dailyprog.dayNumber === day.value) {
            const deletedRange = dailyprog.ranges.splice(rangeIndex.value!, 1)[0];

            if (rangeIndex.value! === 0) {
              dailyprog.ranges[rangeIndex.value!] = {
                ...dailyprog.ranges[rangeIndex.value!],
                from: deletedRange.from
              };
            } else {
              dailyprog.ranges[rangeIndex.value! - 1] = {
                ...dailyprog.ranges[rangeIndex.value! - 1],
                to: deletedRange.to
              };
            }
          }
          return dailyprog;
        })))
        .then(() => {
          closePopinRangeForm();
          closePopinDelete();
        }).catch(err => {
      invalidModeError(err)
    });
  }
}

const openPopinDuplicatePlanning = () => {
  isOpenPopinDuplicatePlanning.value = true;
  pageScroll(false);
}

const closePopinDuplicatePlanning = () => {
  isOpenPopinDuplicatePlanning.value = false;
  pageScroll(true);
}

const applyPlanningToOtherDays = ({items}: TPopinListCheckDatas) => {
  if (items.length > 0) {
    const currentRanges = currentDailyprog.value!.ranges;
    const dailyprogs = planning.value!.dailyprogs.map(dailyprog => {
      if (items.includes(dailyprog.id)) {
        dailyprog.ranges = currentRanges;
      }
      return dailyprog;
    });

    ProgramModule.updateProgramPlanningDailyProgs(dailyprogs)
        .then(() => {
          closePopinDuplicatePlanning();
        }).catch(err => {
      invalidModeError(err);
    });
  } else {
    closePopinDuplicatePlanning();
    pageScroll(false);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(MyVPageWithReturn, {
        title: title.value,
        subtitle: subtitle.value,
        returnAction: backLink.value,
        type: "page"
      }, {
        headerRight: _withCtx(() => _cache[2] || (_cache[2] = [])),
        footer: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(MyVButton, {
              "full-width": true,
              class: "ProgramPlanningEdit__add",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openPopinRangeForm(null, -1)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('programPlanningEdit.add_time_slot')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("button", {
              class: "ProgramPlanningEdit__duplicate",
              onClick: openPopinDuplicatePlanning
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("svg", {
                class: "icon icon-dupplicate",
                "aria-hidden": "true"
              }, [
                _createElementVNode("use", { "xlink:href": "#icon-dupplicate" })
              ], -1)),
              _createElementVNode("span", {
                innerHTML: _ctx.$t('programPlanningEdit.duplicate_day', { day: `<b>${_unref(useDate)().isoWeekday(day.value).locale(locale.value).format('dddd')}</b>` })
              }, null, 8, _hoisted_8)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (sliderOptions.value)
              ? (_openBlock(), _createBlock(MyVMenuSlider, {
                  key: 0,
                  options: sliderOptions.value,
                  routeFunc: menuLink,
                  defaultCurrent: day.value,
                  onCurrentValueChange: setCurrent,
                  isBlue: true
                }, null, 8, ["options", "defaultCurrent"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('programPlanningEdit.planningBarTitle')), 1),
            (planningBarDataMerged.value.ranges)
              ? (_openBlock(), _createBlock(PlanningBar, {
                  key: 0,
                  className: "ProgramPlanningEdit__planning-bar",
                  plannings: planningBarDataMerged.value.ranges
                }, null, 8, ["plannings"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            (planningBarData.value.ranges)
              ? (_openBlock(), _createBlock(PlanningTimeline, {
                  key: 0,
                  ranges: planningBarData.value.ranges,
                  editLink: editLink.value,
                  action: openPopinRangeForm
                }, null, 8, ["ranges", "editLink"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["title", "subtitle", "returnAction"])
    ]),
    (isOpenPopinEditRangeForm.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (planning.value)
            ? (_openBlock(), _createBlock(MyVPageWithReturn, {
                key: 0,
                title: popinRangeFormTitle.value,
                subtitle: popinRangeFormSubtitle.value,
                type: "popin",
                returnAction: closePopinRangeForm
              }, {
                headerRight: _withCtx(() => [
                  (planningBarData.value?.ranges.length > 1 && rangeIndex.value != -1)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        onClick: openPopinDelete,
                        title: "{{ $t('global.delete') }}"
                      }, _cache[4] || (_cache[4] = [
                        _createElementVNode("svg", {
                          class: "icon icon-delete-forever",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("use", { "xlink:href": "#icon-delete-forever" })
                        ], -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                footer: _withCtx(() => _cache[5] || (_cache[5] = [])),
                default: _withCtx(() => [
                  _createVNode(EditRange, {
                    onSubmit: submitRangeForm,
                    returnAction: closePopinRangeForm,
                    day: day.value,
                    start_hour: range() ? range().from : defaultRange.value.start_hour,
                    end_hour: range() ? range().to : defaultRange.value.end_hour,
                    mode: range() ? range().mode : defaultRange.value.mode,
                    temperature: range() ? range().temperature : defaultRange.value.temperature,
                    isOn: range() ? range().isOn : true,
                    availableModes: planning.value.availableModes
                  }, null, 8, ["day", "start_hour", "end_hour", "mode", "temperature", "isOn", "availableModes"])
                ]),
                _: 1
              }, 8, ["title", "subtitle"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (isOpenPopinDelete.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(MyVPageWithReturn, {
            title: _ctx.$t('programPlanningEdit.popinDelete.title'),
            type: "popin",
            returnAction: closePopinDelete
          }, {
            headerRight: _withCtx(() => _cache[6] || (_cache[6] = [])),
            footer: _withCtx(() => _cache[8] || (_cache[8] = [])),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "popinDelete" }, [
                  _createElementVNode("svg", {
                    class: "popinDelete__icon icon icon-big-colored-delete-forever",
                    "aria-hidden": "true"
                  }, [
                    _createElementVNode("use", { "xlink:href": "#icon-big-colored-delete-forever" })
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(MyVButton, {
                    onClick: closePopinDelete,
                    variant: "secondary"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('global.cancel')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(MyVButton, { onClick: deleteDailyProg }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('button.submit_program_planning_delete')), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["title"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(PopinListCheck, {
      open: isOpenPopinDuplicatePlanning.value,
      "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((isOpenPopinDuplicatePlanning).value = $event)),
      items: otherDays.value,
      onClose: closePopinDuplicatePlanning,
      onValidate: applyPlanningToOtherDays,
      title: _ctx.$t('ProgramPlanningEdit__planning.popinApplyToOtherDay.title', { day: _unref(useDate)().isoWeekday(day.value).locale(locale.value).format('dddd') }),
      fieldsetTitle: _ctx.$t('programPlanning.popinApplyToOtherDay.fieldsetTitle'),
      validateLabel: _ctx.$t('button.submit_program_planning_apply'),
      cancelLabel: _ctx.$t('global.cancel')
    }, _createSlots({ _: 2 }, [
      (_unref(isMobile))
        ? {
            name: "subHeader",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('ProgramPlanningEdit__planning.popinApplyToOtherDay.duplicate', {day: _unref(useDate)().isoWeekday(day.value).locale(locale.value).format('dddd')})), 1),
                (planningBarData.value.ranges)
                  ? (_openBlock(), _createBlock(PlanningBar, {
                      key: 0,
                      className: "ProgramPlanningEdit__planning-bar",
                      plannings: planningBarData.value.ranges
                    }, null, 8, ["plannings"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["open", "items", "title", "fieldsetTitle", "validateLabel", "cancelLabel"])
  ]))
}
}

})